
import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../shared/components/base.component';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.template.html',
    styleUrls: ['./profile.styles.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {
    public model: any = {};


    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.user = this.service.Users.current();
    }

    public edit() {
        if (this.model.user) {
            this.service.Users.view(this.model.user);
        }
    }

    public goto(item: any) {
        if (item.id) {
            this.service.navigate(item.id, item.query || {});
        } else if (item.link || item.url) {
            window.open(item.link || item.url, 'blank_');
        }
    }
}
