import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { IUser } from './users.service';
import { ITDEAnalyticsService } from '../itde-analytics.service';

@Injectable({
    providedIn: 'root'
})
export class ContactsService extends BaseService<IUser> {

    constructor(protected http: CommsService, protected _analytics: ITDEAnalyticsService) {
        super();
        this.model.name = 'contact';
        this.model.route = '/contacts';
        this.subjects.list = new BehaviorSubject<IUser[]>([]);
        this.observers.list = this.subjects.list.asObservable();
        this.subjects.orgs = new BehaviorSubject<string[]>([]);
        this.observers.orgs = this.subjects.list.asObservable();
    }

    public load() {
        if (!this.parent || this.parent.Users.get('state') !== 'available') {
            return this.timeout('load', () => this.load());
        }
        if (this.parent.Settings.get('app.contacts.enabled')) {
            this.query();
            this.interval('load_list', () => this.query(), 5 * 60 * 1000);
        }
    }

    /**
     * Convert user data to local format
     * @param user User data
     */
    protected processItem(item: { [name: string]: any }) {
        item.type = 'external';
        const user: IUser = (this.parent.Users as any).processItem(item);
        if (user.organisation_name) {
            const orgs = this.get('orgs');
            if (!orgs.find(i => i === user.organisation_name)) {
                orgs.push(user.organisation_name);
                orgs.sort((a, b) => a.localeCompare(b));
            }
            this.set('orgs', orgs);
        }
        return user;
    }

}
