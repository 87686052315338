import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { Utils } from '../../shared/utility.class';
import { ITDEAnalyticsService } from '../itde-analytics.service';

import * as moment from 'moment';

export interface ILocker {
    id: string;
    serial: string;
    available: boolean;
    pin: string;
    card: string;
}

@Injectable({
    providedIn: 'root',
})
export class LockersService extends BaseService<ILocker> {
    constructor(protected http: CommsService, protected _analytics: ITDEAnalyticsService) {
        super();
        this.model.name = 'locker';
        this.model.route = '/lockers';
        this.subjects.list = new BehaviorSubject<ILocker[]>([]);
        this.observers.list = this.subjects.list.asObservable();
    }

    /**
     * Book a locker
     * @param id ID of the locker
     * @param period Start and end date to book the locker for
     * @param pin Pin number to apply to the locker
     */
    public book(id: string, period: { start: number; end: number }, pin?: string) {
        if (!pin) {
            pin = Utils.padZero(Math.floor(Math.random() * 9999), 4);
        }
        const key = `book|${id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const url = `${this.endpoint}`;
                const time = moment(period.start).hours(12).minutes(0).seconds(0);
                const end = moment(period.end).hours(12).minutes(0).seconds(0);
                this.http
                    .post(url, {
                        locker_number: id,
                        pin,
                        from: time.unix(),
                        until: end.unix(),
                    })
                    .subscribe(
                        () => null,
                        (err) => {
                            reject(err);
                            this.promises[key] = null;
                        },
                        () => {
                            resolve(pin);
                            this.promises[key] = null;
                        }
                    );
            });
        }
        return this.promises[key];
    }

    /**
     * Wrapper of task method for the task 'open'
     * @param id ID of the locker
     */
    public open(id) {
        return this.task(id, 'open');
    }

    protected processItem(raw_item: { [name: string]: any }, id?: string) {
        const item: ILocker = {
            id: raw_item.id || id,
            serial: raw_item.serial,
            available: raw_item.available,
            pin: raw_item.pin,
            card: raw_item.card,
        };
        return item;
    }
}
