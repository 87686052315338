
<div class="container" [style.background-image]="model.settings ? 'url(' + (model.settings.background || '') + ')' : ''">
    <div class="overlay" [style.background-color]="model.settings ? model.settings.colour : ''"></div>
    <div class="content" *ngIf="!model.show">
        <div class="header">WiFi Access</div>
        <div class="subheader">Enter details</div>
        <div class="field">
            <div class="label"></div>
            <div class="value">
                <input-field [(model)]="model.name" placeholder="Name"></input-field>
            </div>
        </div>
        <div class="field">
            <div class="label"></div>
            <div class="value">
                <input-field [(model)]="model.email" placeholder="Email"></input-field>
            </div>
        </div>
        <div class="submit">
            <btn [disabled]="!model.name || !model.email" (tapped)="model.show = true">Next</btn>
        </div>
    </div>
    <div class="body" *ngIf="model.show">
        <div class="content small">
            <div class="header">Free for 3hrs</div>
            <div class="subheader">Free usage for up to 3 hours</div>
            <div class="submit">
                <btn>Connect</btn>
            </div>
        </div>
        <div class="content small">
            <div class="header">All Day Package</div>
            <div class="subheader">{{(model.cost || 0) | currency}} for the day</div>
            <div class="submit">
                <btn>Connect</btn>
            </div>
        </div>
    </div>
</div>
