import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';

export interface ITDEAnalyticsEvent {
    /** ID associated with the booking */
    bookingcode?: string;
    /** ISO string of the event date */
    bookingdate?: string;
    /** Name of the space being booked */
    room?: string;
    /** Details about the type of event occurred */
    analytictype: string;
}

@Injectable({
    providedIn: 'root',
})
export class ITDEAnalyticsService {
    private _token = new BehaviorSubject('');

    /** Endpoint to post analytic events to */
    private _domain: string;
    /** Endpoint to authenticate against */
    private _auth_domain: string;

    public readonly has_token = this._token.pipe(map(_ => !!_));

    public get token() {
        return this._token.getValue();
    }

    constructor(private _http: HttpClient, private _settings: SettingsService) {}

    public apiDomain(api: string, auth: string) {
        this._domain = api;
        this._auth_domain = auth;
        this.loadToken();
    }

    private async loadToken() {
        const domain = this._domain;
        const client_id = this._settings.get("app.ITDE.client_id") || "booking.-.activity.space.api";
        const client_secret = this._settings.get("app.ITDE.client_secret") || "2Q4QFHUJHE2N3J_Z4QKL8G"
        const data = `client_id=${encodeURIComponent(client_id)}&client_secret=${encodeURIComponent(client_secret)}&grant_type=client_credentials`;
        const token: any = await this._http
            .post(`${this._auth_domain}/connect/token`, data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .toPromise();
        this._token.next(token.access_token);
        setTimeout(() => this.loadToken(), (token.expires_in - 1) * 1000);
        return token;
    }

    public async event(details: ITDEAnalyticsEvent) {
        return this._http
            .post(`${this._domain}/odata/v1/analytic`, details, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.token}`,
                },
            })
            .toPromise();
    }
}
