<div class="container">
    <div class="header">
        <div class="text">Select Filters</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body" *ngIf="model && model.show">
        <div class="item">
            <checkbox name="n m" [(model)]="model.show.desks" (modelChange)="updateState()" label="Desks Status"></checkbox>
            <!-- <div class="text">Desk Status</div>
            <div class="checkbox">
                <toggle name="zones" [(model)]="model.show.desks" (modelChange)="updateState()" type="ios"></toggle>
            </div> -->
        </div>
        <div class="item">
            <checkbox name="n m" [(model)]="model.show.rooms" (modelChange)="updateState()" label="Room Status"></checkbox>
            <!-- <div class="text">Room Status</div>
            <div class="checkbox">
                <toggle name="zones" [(model)]="model.show.rooms" (modelChange)="updateState()" type="ios"></toggle>
            </div> -->
        </div>
        <div class="item">
            <checkbox name="n m" [(model)]="model.show.teams" (modelChange)="updateState()" label="Team Tables"></checkbox>
            <!-- <div class="text">Team Tables</div>
            <div class="checkbox">
                <toggle name="zones" [(model)]="model.show.teams" (modelChange)="updateState()" type="ios"></toggle>
            </div> -->
        </div>
        <div class="item">
            <checkbox name="n m" [(model)]="model.show.zones" (modelChange)="updateState()" label="Zones"></checkbox>
            <!-- <div class="text">Zones</div>
            <div class="checkbox">
                <toggle name="zones" [(model)]="model.zones.show" (modelChange)="updateState()" type="ios"></toggle>
            </div> -->
        </div>
    </div>
    <div class="footer">
        <btn name="modal" (tapped)="event('Accept')">Confirm</btn>
    </div>
</div>