<div class="container">
    <div class="header">
        <div class="heading">{{model.show_order ? 'Confirm order' : 'Catering Service'}}</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <div class="item-list" *ngIf="!model.show_order">
            <div #tablist class="tabs">
                <div #tab class="tab" [class.active]="!model.category" (touchrelease)="setCategory('')">
                    All ({{model.count }})
                </div>
                <div #tab class="tab" *ngFor="let cat of model.category_list" [class.active]="model.list[cat] === model.category" (touchrelease)="setCategory(cat)">
                    {{cat}}
                </div>
                <div class="bar" [style.left]="model.tab?.left + 'px'" [style.width]="model.tab?.width + 'px'">
                </div>
            </div>
            <div class="list">
                <div class="item" *ngFor="let item of filtered_items">
                    <div class="name">{{item.name}}</div>
                    <div class="price" *ngIf="item.price">{{((item.price / 100) || 0 )| currency}} each</div>
                    <div class="amount">
                        <div class="counter" (keyup.arrowleft)="add(item, -1)" (keyup.arrrowright)="add(item, 1)">
                            <div class="btn" [class.disabled]="!item.quantity || item.quantity <= 0" (touchrelease)="add(item, -1)">
                                <i class="material-icons">remove</i>
                            </div>
                            <div class="count">{{item.quantity || '0'}}</div>
                            <div class="btn" [class.disabled]="item.quantity >= 20" (touchrelease)="add(item, 1)">
                                <i class="material-icons">add</i>
                            </div>
                        </div>
                    </div>
                    <div class="price" *ngIf="item.price">{{(((item.price /100) * (item.quantity || 0)) || 0) | currency}}</div>
                </div>
            </div>
        </div>
        <div class="order-list" *ngIf="model.show_order">
            <div class="table-head">
                <div class="col">Item</div>
                <div class="col fixed">Cost</div>
                <div class="col quantity">Quantity</div>
                <div class="col fixed">Total</div>
            </div>
            <div class="list" *ngIf="model.cart && model.cart.items">
                <div class="item" *ngFor="let item of model.cart.items">
                    <div class="name">{{item.name}}</div>
                    <div class="price" *ngIf="item.price">{{((item.price / 100) || 0 )| currency}} each</div>
                    <div class="amount">
                        <div class="counter">
                            <div class="btn" [class.disabled]="!item.quantity || item.quantity <= 0" (touchrelease)="add(item, -1, true)">
                                <i class="material-icons">remove</i>
                            </div>
                            <div class="count">{{item.quantity || '0'}}</div>
                            <div class="btn" [class.disabled]="item.quantity >= 20" (touchrelease)="add(item, 1, true)">
                                <i class="material-icons">add</i>
                            </div>
                        </div>
                    </div>
                    <div class="price" *ngIf="item.price">{{(((item.price /100) * (item.quantity || 0)) || 0) | currency}}</div>
                    <div class="remove" (touchrelease)="removeItem(item)">
                        <i center class="material-icons">close</i>
                    </div>
                </div>
                <div class="tally">
                    <div class="name">Total</div>
                    <div class="count">{{model.cart.total || '0'}}</div>
                    <div class="price" *ngIf="model.cart.total_price">{{((model.cart.total_price / 100) || '0') | currency}}</div>
                </div>
            </div>
            <div class="dietary">
                <div class="block-header" (touchrelease)="model.show_diet = !model.show_diet">
                    <div class="text">Dietary requirements</div>
                    <div class="icon" [class.green]="model.cart.details && !model.show_diet">
                        <i class="material-icons">{{ model.show_diet ? 'close' : (model.cart.details ? 'done' : 'help') }}</i>
                    </div>
                </div>
                <div class="block" [class.show]="model.show_diet">
                    <textarea [(ngModel)]="model.cart.details" placeholder="Add any dietary requirements here"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <btn klass="modal inverse" (tapped)="!model.show_order ? fn.event('Cancel') : model.show_order = false">{{!model.show_order ? 'Cancel' : 'Back'}}</btn>
        <btn klass="modal" type="submit" [disabled]="model.item_count <= 0 || model.show_diet" (tapped)="!model.show_order ? showOrder() : order()">
            <div class="text" *ngIf="!model.booking">
                {{ model.show_order ? 'Confirm' : 'Place order' }}
                <div class="item-count" *ngIf="!model.show_order && model.item_count > 0">
                    {{model.item_count}}
                </div>
            </div>
            <div class="icon" *ngIf="model.booking">
                <spinner type="circle-ring" color="#fff"></spinner>
            </div>
        </btn>
    </div>
</div>