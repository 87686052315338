
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'user-details-overlay',
    templateUrl: './user-details-overlay.template.html',
    styleUrls: ['./user-details-overlay.styles.scss']
})
export class UserDetailsOverlayComponent extends OverlayContentComponent {

    public init() {
        if (!this.model.form) { this.model.form = {}; }
        if (this.model.search_field) {
            if ((this.model.search_field || '').indexOf('@') >= 0) {
                this.model.form.email = this.model.search_field;
            } else if ((this.model.search_field || '').indexOf(' ') >= 0) {
                const parts = this.model.search_field.split(' ');
                this.model.form.first_name = parts[0];
                this.model.form.last_name = parts[1];
            } else {
                this.model.form.first_name = this.model.search_field;
            }
            this.model.form = { ...this.model.form };
        }
    }

    public async add(finish: boolean = true) {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            if (!this.model.form.first_name && !this.model.form.last_name) {
                this.model.form.name = (this.model.form.email || `Guest ${Math.random() * 9999}`).split('@')[0];
            } else {
                this.model.form.name = (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || '');
            }
            if (this.model.backend) {
                await this.service.Users.add(this.model.form.users ? (this.model.form.users[0] || this.model.form) : this.model.form)
                    .catch((err) => console.log(err));
            }
            this.model.processing = false;
            this.fn.event('Success');
            if (finish) {
                setTimeout(() => this.fn.close(), 3000);
            } else {
                setTimeout(() => {
                    this.model.form = {};
                    this.model.loading = false;
                }, 3000);
            }
        }
    }

    public update() {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.form.name = (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || '');
            this.service.Users.update(this.model.form.users ? (this.model.form.users[0] || this.model.form) : this.model.form).then(() => {
                this.model.processing = false;
                this.fn.event('Success');
                setTimeout(() => this.fn.close(), 3000);
            }, () => this.model.loading = false);
        }
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
}
