import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';

import * as moment from 'moment';

interface IWeekday {
    id: number;
    value: number;
    month: string;
    day: string;
    past: boolean;
}

@Component({
    selector: 'custom-week-field',
    templateUrl: './week-field.component.html',
    styleUrls: ['./week-field.component.scss']
})
export class CustomWeekFieldComponent extends BaseComponent implements ICustomField<string>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<string>;

    public week: IWeekday[] = [];
    public offset = 0;
    public past = true;
    public active = -1;

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => {
                    this.offset = 0;
                    this.updateDisplay();
                });
            }

            this.subs.obs.control = this.control.valueChanges.subscribe(() => {
                this.offset = 0;
                this.updateDisplay();
            });
            this.init();
        }
    }

    public init() {
        const value = this.ref_control ? this.ref_control.value || '' : '';
        const date = (value ? moment(value) : moment());
        this.setValue(date.valueOf());
        this.updateDisplay();
    }

    public updateDisplay() {
        this.week = this.generateWeek(this.control.value, this.offset);
        this.active = -1;
        const active_day = moment(this.control.value);
        for (const day of this.week) {
            if (active_day.isSame(moment(day.id), 'd')) {
                this.active = this.week.indexOf(day);
                break;
            }
        }
    }

    public changeWeek(value: number = 1) {
        this.offset += value;
        this.updateDisplay();
    }

    public generateWeek(datestamp: number, offset: number = 0): IWeekday[] {
        // Clean up time
        const week = [];
        const now = moment().startOf('d');
        const date = moment(datestamp).startOf('w').add(offset, 'w');
        for (let i = 0; i < 7; i++) {
            week.push({
                id: date.valueOf(),
                value: date.date(),
                month: date.format('MMM'),
                day: date.isSame(now, 'd') ? 'Today' : date.format('ddd'),
                past: date.isBefore(now, 'd')
            });
            date.add(1, 'd');
        }
        const previous_week = date.startOf('w').add(-8, 'd');
        this.past = previous_week.isBefore(now, 'd');
        return week;
    }

    public setValue(value: number) {
        this.control.setValue(value);
        this.offset = 0;
        if (this.ref_control) {
            const v = this.ref_control ? this.ref_control.value || '' : '';
            const ref_date = (v ? moment(v) : moment());
            const date = moment(value).startOf('d').hours(ref_date.hours()).minutes(ref_date.minutes());
            this.ref_control.setValue(date.valueOf());
        }
    }
}
