
import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

export interface IBannerSettings {
    enabled: boolean;
    page?: IBannerSettings;
    background?: string;
    links?: ILinkSetting[];
}

export interface ILinkSetting {
    id: string;
    name: string;
    src?: string;
    color?: string;
    query?: any;
    link?: string;
}

@Component({
    selector: 'page-banner',
    templateUrl: './page-banner.template.html',
    styleUrls: ['./page-banner.styles.scss']
})
export class PageBannerComponent extends BaseComponent implements OnInit {
    @Input() public heading = '';
    @Input() public info = '';

    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.model.hide_routes = ['/', '/home'];
        this.init();
        this.subs.obs.router_events = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.menu = this.service.Settings.get('app.menu');
        this.model.copyright = this.service.Settings.get('app.copyright');
        this.model.route_settings = this.service.Settings.get('app.route_settings');
        this.model.tiles = this.service.Settings.get('app.tiles');
        this.model.banner = this.service.Settings.get('app.banner') || {};
        this.model.bld = this.service.Buildings.current();
        this.subs.obs.blk_height = this.service.listen('BANNER.block_height', (v: number) => this.model.blk_height = v);
        this.checkRoute();
    }

    public goto(item: any) {
        if (item.id) {
            this.service.navigate(item.id, item.query || {});
        } else if (item.link || item.url) {
            window.open(item.link || item.url, 'blank_');
        }
        if (this.model.menu) {
            this.model.menu.show = false;
        }
    }

    public checkRoute() {
        this.timeout('route', () => {
            const route = this.router.url.split('?')[0];
            this.model.is_home = route === '/' || route === '/home' || route === '/help';
            if (route && route !== '/' && route !== '/home') {
                    // Load banner settings for route
                const parts = route.substr(1).split('/');
                this.model.banner.page = this.service.Settings.get(`app.${this.model.route_settings[parts[0]] || parts[0]}.banner`);
                if (!this.model.banner.page) {
                    this.model.banner.page = (this.service.Settings.get('app.banner') || {}) as IBannerSettings;
                }
                    // If banner is of type colours get the colours from the closest matching tile
                if (this.model.tiles && ['color', 'colour', 'colors', 'colours'].indexOf(this.model.banner.type) >= 0) {
                    let item = null;
                    for (const t of this.model.tiles) {
                        if (`/${t.id}` === route) {
                                // Exact match
                            this.model.banner.page.color = t.color || this.model.banner.page.color || '';
                            item = null;
                            break;
                        } else if (route.indexOf(t.id) >= 0 && (!item || item.id.length < t.id.length)) {
                                // Partial match
                            item = t;
                        }
                    }
                        // Set most relevant match as the colour
                    if (item) {
                        this.model.banner.page.color = item.color || this.model.banner.page.color || '';
                    }
                }
            } else {
                    // Disable banner when no route or home page
                this.model.banner.page = { enabled: false } as IBannerSettings;
            }
        }, 50);
    }
}
