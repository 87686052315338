<ng-container *ngIf="form">
    <div class="week-field" [formGroup]="form">
        <div class="change previous" [class.disabled]="past">
            <i class="material-icons" (touchrelease)="changeWeek(-1)">keyboard_arrow_left</i>
        </div>
        <div class="week not-mobile">
            <div class="day" *ngFor="let item of week; let i = index"
                [class.disabled]="item.past"
                [class.active]="active === i" [class.weekend]="i === 0 || i === 6"
                (touchrelease)="!item.past ? setValue(item.id) : ''">

                <div class="weekday">{{item.day}}</div>
                <div class="date">{{item.value}}</div>
                <div class="month">{{item.month}}</div>
            </div>
        </div>
        <div class="week mobile-only">
            <div class="day" *ngFor="let item of week | slice:1:6; let i = index"
                [class.disabled]="item.past"
                [class.active]="active === i + 1" [class.weekend]="i === 0 || i === 6"
                (touchrelease)="!item.past ? setValue(item.id) : ''">

                <div class="weekday">{{item.day}}</div>
                <div class="date">{{item.value}}</div>
                <div class="month">{{item.month}}</div>
            </div>
        </div>
        <div class="change next">
            <i class="material-icons"(touchrelease)="changeWeek(1)">keyboard_arrow_right</i>
        </div>
    </div>
</ng-container>
