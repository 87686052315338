
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'visitor-details-overlay',
    templateUrl: './visitor-details-overlay.template.html',
    styleUrls: ['./visitor-details-overlay.styles.scss']
})
export class VisitorDetailsOverlayComponent extends OverlayContentComponent {

    public init() {
        this.model.form = {};
        this.model.group = 0;
        if (this.model.visitor) {
            this.model.datetime = this.model.visitor.date || this.model.visitor.time;
        }
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
}
