
import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'avatar',
    templateUrl: './avatar.template.html',
    styleUrls: ['./avatar.styles.scss'],
})
export class AvatarComponent implements OnChanges {
    @Input() public user: any;

    public model: any = {};

    constructor() {
        this.model.colour_list = [
            '#F44336',
            '#E91E63',
            '#9C27B0',
            '#673AB7',
            '#3F51B5',
            '#2196F3',
            '#03A9F4',
            '#00BCD4',
            '#009688',
            '#4CAF50',
            '#8BC34A',
            '#CDDC39',
            '#FDD835',
            '#FFC107',
            '#FF9800',
            '#FF5722',
            '#795548',
            '#9E9E9E',
            '#607D8B'
        ];
    }

    public ngOnChanges(changes: any) {
        if (changes.user && this.user) {
            const parts = this.user.name.split(' ');
            let initials = '';
            for (const p of parts) {
                // if (initials) { initials += ' '; }
                initials += p[0];
            }
            this.model.initials = initials;
            let char_value = 0;
            for (let i = 0; i < this.model.initials.length; i++) {
                char_value += this.model.initials.charCodeAt(i);
            }
            this.model.colour = this.model.colour_list[char_value % this.model.colour_list.length];
        }
    }
}
