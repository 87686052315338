
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';

import * as moment from 'moment';

@Component({
    selector: 'custom-date-field',
    templateUrl: './date-field.template.html',
    styleUrls: ['./date-field.styles.scss']
})
export class CustomDateFieldComponent extends BaseComponent implements ICustomField<number>, OnDestroy {
    public control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<number>;

    public model: any = {};

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.model.today = moment().valueOf();
            this.control = this.form.controls[this.field.key];
            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.updateDisplay();
        }
    }

    public updateDisplay() {
        this.model.value = this.control.value;
        this.model.display = moment(this.control.value || null).format('ddd DD MMM YYYY');
    }

    public setValue(value: number) {
        const old_value = this.control.value;
        const old_date = moment(old_value);
        const date = moment(value).hours(old_date.hours()).minutes(old_date.minutes());
        this.control.setValue(date.valueOf());
        this.model.show_tooltip = false;
    }
}
