<div class="container">
    <div class="header">
        <div class="text">Select {{ model.simple ? 'time' : 'Booking Period' }}</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body" *ngIf="model.form">
        <time-picker [(date)]="model.form.start" [manual]="true" [(duration)]="model.duration" [range]="!model.simple"></time-picker>
    </div>
    <div class="footer">
        <btn name="modal" (tapped)="event('Accept')">Ok</btn>
    </div>
</div>