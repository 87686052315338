<div
    class="container"
    *ngIf="context.data.center && display !== false"
    [style.left]="context.data.center?.x + '%'"
    [style.top]="context.data.center?.y + '%'"
>
    <div
        class="content"
        [class.top]="context.data.center && context.data.center.y >= 50"
        [class.bottom]="context.data.center && context.data.center.y < 50"
        [class.left]="context.data.center && context.data.center.x >= 50"
        [class.right]="context.data.center && context.data.center.x < 50"
    >
        <div class="name" *ngIf="!loading">
            <span class="capitalise">{{ desk?.name || 'Unidentifed user' }}</span> is here
        </div>
        <div class="loading" *ngIf="loading">
            <spinner type="circle-ring"></spinner>
        </div>
        <div class="corner"></div>
    </div>
</div>
