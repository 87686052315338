<div class="item control">
    <div class="status" [class.active]="item.in_use"></div>
    <div class="details">
        <div class="name">{{item.name}}</div>
        <div class="extras">
            <div class="icon" *ngIf="item.extras" [class.active]="model.show" tooltip [template]="template" [(show)]="model.show" position="right" offset="middle" (touchrelease)="model.show = !model.show">
                <i class="material-icons">info</i>
                <ng-template #template>
                    <div class="equipment">
                        <div class="heading">Equipment:</div>
                        <ul>
                            <li *ngFor="let itm of item.extras">{{itm.name}}</li>
                        </ul>
                    </div>
                </ng-template>
            </div>
            <div class="field">Capacity: {{item.capacity || '0'}} {{item.capacity === 1 ? 'Person' : 'People'}}</div>
        </div>
    </div>
    <div class="options">
        <div class="field">{{item.level?.name}}</div>
        <btn name="find" (tapped)="control()"><div>Control</div></btn>
    </div>
</div>