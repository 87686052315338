
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../../shared/components/base.component';
import { AppService } from '../../services/app.service';

import * as moment from 'moment';
import { Utils } from '../../shared/utility.class';

@Component({
    selector: 'app-visitor',
    templateUrl: './visitor.template.html',
    styleUrls: ['./visitor.styles.scss']
})
export class VisitorComponent extends BaseComponent implements OnInit {
    public model: any = { display: {} };

    constructor(private service: AppService, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit() {
        this.service.Contacts.query();
        this.route.paramMap.subscribe((params) => {
            if (params.has('page')) {
                this.model.page = params.get('page');
                if (this.model.page && this.model.page.indexOf('result') >= 0) {
                    this.loadOldVisitor();
                }
            }
        });
        this.route.queryParamMap.subscribe((params) => {
            if (params.has('finish')) {
                this.model.finish = params.get('finish');
            }
        });
    }

    public loadOldVisitor() {
        if (localStorage) {
            this.model.booking = JSON.parse(localStorage.getItem('STAFF.last_visitor') || '{}');
            const booking = this.model.booking;
            const date = moment(booking.date);
            this.model.display.organiser = this.service.Users.current().name;
            this.model.display.date = date.format('dddd, Do MMMM, YYYY');
            if (booking.duration) {
                const end = moment(date).add(booking.duration, 'm');
                const d = Utils.humaniseDuration(booking.duration);
                this.model.display.duration = `${date.format('h:mma')} – ${end.format('h:mma')}(${d})`;
            }
            if (booking.visitors && booking.visitors.length > 0) {
                this.model.display.attendees = `${booking.visitors.length} Attendee${booking.visitors.length === 1 ? '' : 's'}; `;
                let start = false;
                for (const user of booking.visitors) {
                    if (start) {
                        this.model.display.attendees += ', ';
                    } else {
                        start = true;
                    }
                    this.model.display.attendees += user.name;
                }
            }
        }
    }

    public onConfirm() {
        if (localStorage) {
            localStorage.removeItem('STAFF.last_visitor');
        }
        if (this.model.finish) {
            this.home();
        } else {
            this.service.navigate(['/visitor']);
        }
    }

    public home() {
        this.service.navigate(this.service.Settings.get('app.booking.return_id') || '');
    }
}
