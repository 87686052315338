<div class="container">
    <div class="header">
        <div class="text">Device list<span *ngIf="model.list">({{model.list.length || '0'}})</span></div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="model.list">
            <div class="item" *ngFor="let item of model.list; let i = index" (touchrelease)="show(item)">
                <div class="text" *ngIf="item && item.display && item.display.building">{{item.display.building}}, {{item.display.level}}</div>
                <div class="text" *ngIf="item && item.name">{{item.name}}</div>
            </div>
        </ng-container>
        <div class="no-item" center *ngIf="!model.list">
            <div class="icon"><i class="material-icons">close</i></div>
            <div class="text">No devices found</div>
        </div>
    </div>
</div>