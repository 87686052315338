<div class="container" *ngIf="context.data.center" [style.left]="context.data.center?.x + '%'" [style.top]="context.data.center?.y + '%'">
    <div
        class="content"
        [class.top]="context.data.center && context.data.center.y >= 50"
        [class.bottom]="context.data.center && context.data.center.y < 50"
        [class.left]="context.data.center && context.data.center.x >= 50"
        [class.right]="context.data.center && context.data.center.x < 50"
    >
        <div class="name">{{ context.data.room?.name }}</div>
        <div class="capacity" *ngIf="context.data.room && context.data.room.capacity">
            Capacity: {{ context.data.room?.capacity }} {{ context.data.room?.capacity === 1 ? 'Person' : 'People' }}
        </div>
        <div
            class="free"
            [class.available]="context.data.available"
            [class.non-bookable]="context.data.room && !context.data.room.bookable"
            [innerHTML]="context.data.room && !context.data.room.bookable ? 'Not Bookable' : context.data.display.time"
        ></div>
        <div class="equipment" *ngIf="context.data.room && context.data.room.extras && context.data.room.extras.length > 0">
            <div class="header">Equipment:</div>
            <ul>
                <li *ngFor="let item of context.data.room.extras">{{ item.name }}</li>
            </ul>
        </div>
        <div class="corner"></div>
    </div>
</div>
