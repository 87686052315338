import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';
import { Utils } from '../../../utility.class';

import * as moment from 'moment';

@Component({
    selector: 'custom-time-field',
    templateUrl: './time-field.component.html',
    styleUrls: ['./time-field.component.scss']
})
export class CustomTimeFieldComponent extends BaseComponent implements ICustomField<string>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<string>;

    public model: any = {};

    public font_size = 16;

    @ViewChild(CdkVirtualScrollViewport) private viewport: CdkVirtualScrollViewport;
    @ViewChild('ref') private ref: ElementRef;

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => this.updateDisplay());
            }

            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.init();
            this.checkFontSize();
        }
    }

    public init() {
        const parts = (this.control.value || '00:00').split(':');
        const value = this.ref_control ? this.ref_control.value || '' : '';
        const date = (value ? moment(value) : moment());
        if (this.control.value) {
            date.hours(+parts[0]).minutes(+parts[1]);
        } else {
            date.minutes(Math.ceil(date.minutes() / 5) * 5);
        }
        this.setValue(date.format('HH:mm'));
    }

    public updateDisplay() {
        const parts = (this.control.value || '00:00').split(':');
        const value = this.ref_control ? this.ref_control.value || '' : '';
        const date = (value ? moment(value) : moment()).hours(+parts[0]).minutes(+parts[1]);
        const times = this.generateTimes(date.add(1, 'd').valueOf());
        this.model.list_height = Math.min(times.values.length, 8);
        this.model.blocks = times.values;
        this.model.index = times.index;
    }

    public generateTimes(datestamp: number) {
        // Clean up time
        const time = {
            values: [],
            index: 0
        };
        const date = moment(datestamp);
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5);
        if (now.minutes() % 15 !== 0) {
            time.values.push({ id: now.format('HH:mm'), name: now.format('hh:mm A') });
            now.minutes(Math.ceil(now.minutes() / 15) * 15);
        }
        if (date.isAfter(now, 'd')) { now.startOf('d'); }
        const end = moment(now).endOf('d');
        for (; now.isBefore(end, 'm'); now.add(15, 'm')) {
            time.values.push({ id: now.format('HH:mm'), name: now.format('hh:mm A') });
        }
        const ts = date.format('HH:mm');
        time.index = this.getIndex(ts, time.values, date.format('hh:mm A'));
        time.values = Utils.unique(time.values, 'id');
        time.values.sort((a, b) => a.id.localeCompare(b.id));
        return time;
    }

    public getIndex(timestamp: string, values: { id: string, name: string }[], name?: string) {
        let index = 0;
        let exists = values.find(a => a.id === timestamp);
        if (exists) {
            values.sort((a, b) => a.id.localeCompare(b.id));
            index = values.indexOf(exists);
        } else {
            values.push({ id: timestamp, name: name || timestamp });
            values.sort((a, b) => a.id.localeCompare(b.id));
            exists = values.find(a => a.id === timestamp);
            index = values.indexOf(exists);
        }
        return index;
    }

    public updateView(tries = 0) {
        if (tries > 20) { return; }
        if (!this.viewport) {
            return this.timeout('scroll', () => this.updateView(++tries), 50);
        }
        this.timeout('scroll', () => this.viewport.scrollToIndex(this.model.index));

    }

    public setValue(value: string) {
        this.control.setValue(value);
        this.model.show = false;
        if (this.ref_control) {
            const parts = (this.control.value || '00:00').split(':');
            const v = this.ref_control ? this.ref_control.value || '' : '';
            const date = (v ? moment(v) : moment()).hours(+parts[0]).minutes(+parts[1]);
            date.minutes(Math.round(date.minutes() / 5) * 5);
            this.ref_control.setValue(date.valueOf());
        }
    }

    public show() {
        this.model.show = !this.model.show;
        if (this.model.show) {
            this.updateView();
        }
    }

    public cleanTime() {
        const parts = (this.control.value || '00:00').split(':');
        const value = this.ref_control ? this.ref_control.value || '' : '';
        const date = (value ? moment(value) : moment()).hours(+parts[0]).minutes(+parts[1]);
        date.minutes(Math.round(date.minutes() / 5) * 5);
        this.setValue(date.format('HH:mm'));
        this.model.index = this.getIndex(date.format('HH:mm'), this.model.blocks, date.format('hh:mm A'));
    }

    public checkFontSize(tries: number = 0) {
        if (tries > 5) { return; }
        if (this.ref && this.ref.nativeElement) {
            this.font_size = this.ref.nativeElement.offsetHeight;
        } else {
            this.timeout('up_size', () => this.checkFontSize(tries), 200 * ++tries);
        }
    }
}
