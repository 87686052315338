/*
 * @Author: Alex Sorafumo
 * @Date: 2017-06-05 12:39:28
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:56:07
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';

import { IRoom } from './rooms.service';
import { IUser } from './users.service';

import * as moment from 'moment';
import { BaseService } from './base.service';
import { Utils } from '../../shared/utility.class';
import { ITDEAnalyticsService } from '../itde-analytics.service';
export interface IBooking {
    id: string;
    icaluid?: string;
    title: string;
    date: number;
    start_hours?: number;
    duration: number;
    description?: string;
    organiser?: IUser;
    is_organiser?: boolean;
    attendees?: IUser[];
    room?: IRoom;
    room_list?: IRoom[];
    display?: { [name: string]: string };
    catering?: boolean;
    visitors?: boolean;
    cost_code?: string;
    status?: 'future' | 'upcoming' | 'past' | 'in_progress';
    _status?: string;
    recurr?: {
        period: number,
        end: number
    };
    parking?: boolean;
    booking_type?: string;
}

@Injectable({
    providedIn: 'root'
})
export class BookingsService extends BaseService<IBooking> {

    constructor(protected http: CommsService, protected _analytics: ITDEAnalyticsService) {
        super();
        this.model.name = 'booking';
        this.model.route = '/bookings';
        this.set('timeline', {});
        this.set('new_booking', { state: 'idle' });
        this.set('update_booking', { state: 'idle' });
    }

    public init() {
        if (!this.parent || !this.parent.Rooms.list(true) || this.parent.Rooms.list(true).length <= 0 || !this.parent.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        if (this.parent && this.parent.Settings.get('app.schedule.enabled')) {
            this.load();
        }
    }

    protected load(tries: number = 0) {
        this.query();
    }

    /**
     * Create overlay with the give booking details displayed
     * @param item Booking to view
     */
    public view(item: IBooking) {
        if (this.parent) {
            this.parent.Overlay.openModal('meeting-details', { data: { booking: item } }, (e) => e.close());
        }
    }

    /**
     * Create modal with a booking form
     * @param room Room to book
     */
    public bookRoom(room: IRoom) {
        if (!room) { return; }
        const today = moment();
        today.minutes(Math.ceil(today.minutes() / 5) * 5).seconds(0).milliseconds(0);
        const bookings = room.bookings.filter(b => moment(b.date).isSame(today, 'd'));
        let block = this.getNextFreeBlock(bookings);
        if (block && moment.duration(moment(block.end).diff(today)).asMinutes() < 30) {
            block = this.getNextFreeBlock(bookings, 30, block.end);
        }
        this.parent.Overlay.openModal('booking-form', { data: {
            room,
            date: !block || block.start < 0 ? today.valueOf() : Math.max(today.valueOf(), block.start) }
        }, (e) => e.close());
            }

    /**
     * Create confirm modal for booking the specified space with the given parameters
     * @param item Booking form hash map
     */
    public book(item: { [name: string]: any }): Promise<IBooking> {
        return new Promise((resolve, reject) => {
            if (this.get('new_booking').state !== 'idle') { return reject('Another booking is in progress'); }
            const date = moment(item.date);
            this.parent.confirm(this.confirmSettings('add', item), (event) => {
                if (event.type === 'Accept') {
                    this.subjects.new_booking.next({ state: 'processing' });
                    this.add(item).then((d) => {
                        this.set('new_booking', { state: 'success', value: d });
                        resolve(d);
                        if (localStorage) {
                            const booking = localStorage.getItem('STAFF.booking_form');
                            localStorage.removeItem('STAFF.booking_form');
                            localStorage.setItem('STAFF.last_booking', booking || JSON.stringify(item));
                        }
                        this.timeout(`${moment().unix()}`, () => this.set('new_booking', { state: 'idle' }));
                    }, (e) => {
                        this.set('new_booking', { state: 'error', value: e });
                        this.timeout('new_error', () => this.set('new_booking', { state: 'idle' }));
                        reject(`Error: ${e}`);
                    });
                } else {
                    reject('Cancelled by user');
                }
                event.close();
            });
        });
    }

    /**
     * Create confirm modal for updating booking of the specified space with the given parameters
     * @param item Booking form hash map
     */
    public update(id: string, item: { [name: string]: any }): Promise<IBooking> {
        return new Promise((resolve, reject) => {
            this.parent.confirm(this.confirmSettings('update', item), (event) => {
                if (event.type === 'Accept') {
                    this.set('update_booking', { state: 'processing' });
                    this.updateItem(item.id || id, item)
                        .then((d) => {
                            this.set('update_booking', { state: 'success', value: d });
                            const new_item = this.processItem(d);
                            resolve(new_item);
                            if (localStorage) {
                                const booking = localStorage.getItem('STAFF.booking_form');
                                localStorage.removeItem('STAFF.booking_form');
                                localStorage.setItem('STAFF.last_booking', booking || JSON.stringify(item));
                            }
                            this.parent.Rooms.replaceBooking(new_item);
                            this.query();
                            this.timeout(`${moment().unix()}`, () => this.set('new_booking', { state: 'idle' }));
                        }, (e) => {
                            this.set('update_booking', { state: 'error', value: e });
                            this.timeout('update_error', () => this.set('update_booking', { state: 'idle' }));
                            reject(e);
                        });
                    event.close();
                } else { reject('Cancelled by user'); }
                event.close();
            });
        });
    }

    public accept(id: string, fields?: { [name: string]: any }) {
        return this.task(id, 'accept', fields);
    }

    /**
     * Retrieve booking with the given ID
     * @param id ID of booking
     */
    public getWithID(id: string) {
        return this.item(id);
    }

    /**
     * Check for conflicts between two lists of bookings
     * @param list First list to check for conflicts
     * @param comparison Second list to check for conflicts. Defaults to the users bookings
     */
    public checkConflicts(list: IBooking[], comparison?: IBooking[]) {
        if (!comparison) {
            comparison = this.get('timeline') || [];
        }
        let conflict_count = 0;
        for (const bkn of list) {
            const bkn_start = moment(bkn.date);
            const bkn_end = moment(bkn_start).add(bkn.duration, 'm');
            for (const cmp of comparison) {
                const cmp_start = moment(cmp.date);
                const cmp_end = moment(cmp_start).add(cmp.duration, 'm');
                if (bkn_start.isBetween(cmp_start, cmp_end, 'm', '[)') || bkn_end.isBetween(cmp_start, cmp_end, 'm', '(]')) {
                    conflict_count++;
                    break;
                }
            }
        }
        return conflict_count;
    }

    /**
     * Get the free times between a group of booking
     * @param list List of bookings
     */
    public getFreeSlots(list: IBooking[]) {
        list.sort((a, b) => a.date - b.date);
        const block: { start: number, end: number }[] = [];
        let bkn_start: moment.Moment = null;
        let bkn_end: moment.Moment = null;
        for (const bkn of list) {
            bkn_start = moment(bkn.date).seconds(0).milliseconds(0);
            if (!bkn_end) {
                block.push({ start: moment(bkn_start).startOf('d').valueOf(), end: bkn_start.valueOf() });
            } else if (bkn_end.isBefore(bkn_start, 'm')) {
                block.push({ start: bkn_end.valueOf(), end: bkn_start.valueOf() });
            }
            bkn_end = moment(bkn_start).add(bkn.duration, 'm');
        }
        if (bkn_end) {
            block.push({ start: bkn_end.valueOf(), end: bkn_end.endOf('d').valueOf() });
        } else if (block.length <= 0) {
            const today = moment();
            block.push({ start: today.startOf('d').valueOf(), end: today.endOf('d').valueOf() });
        }
        return block;
    }

    public getNextFreeBlock(list: IBooking[], gap: number = 30, time: number = moment().valueOf()) {
        const blocks = this.getFreeSlots(list);
        const now = moment(time);
        let block = null;
        for (const blk of blocks) {
            const start = blk.start < 0 ? moment().hours(0).minutes(0) : moment(blk.start);
            const end = blk.end < 0 ? moment().hours(23).minutes(59) : moment(blk.end);
            const dur = moment.duration(end.diff(start));
            const length = Math.floor(dur.asMinutes());
            if (!block && (now.isBetween(start, end, 'm', '[)') || now.isBefore(start, 'm')) && length >= gap) {
                block = blk;
                break;
            }
        }
        return block;
    }

    /**
     * This method will take the entire payload of bookings and will provide us with the
     * @param raw_item
     */
    public processItem(raw_item: { [name: string]: any }) {
        const user = this.parent.Users.current();
        const start = moment(raw_item.start_epoch * 1000 || raw_item.start || raw_item.Start || raw_item.date);
        const end = moment(raw_item.end_epoch * 1000 || raw_item.end || raw_item.End);
        const has_end = raw_item.end_epoch * 1000 || raw_item.end || raw_item.End;
        const duration = moment.duration(start.diff(end));
        const item: IBooking = {
            id: raw_item.id,
            icaluid: raw_item.icaluid,
            title: raw_item.title || raw_item.Subject || raw_item.subject,
            date: start.valueOf(),
            start_hours: start.hours() + start.minutes() / 60,
            duration: has_end ? Math.abs(duration.asMinutes()) || raw_item.duration : raw_item.duration,
            description: raw_item.description,
            attendees: this.parent.Users.processList(raw_item.attendees || []),
            organiser: raw_item.owner,
            is_organiser: raw_item.isOrganizer || false,
            room: { id: raw_item.room_id, name: raw_item.room_name } as IRoom,
            visitors: raw_item.visitors,
            display: {
                date: start.format('DD/MM/YYYY'),
                long_date: start.format('DD MMMM YYYY'),
                time: `${start.format('h:mma')} - ${end.format('h:mma')}`,
                start: start.format('h:mma'),
                end: has_end ? end.format('h:mma') : moment(start).add(raw_item.duration, 'm').format('h:mma'),
                duration: Utils.humaniseDuration(has_end ? Math.abs(duration.asMinutes()) || raw_item.duration : raw_item.duration),
                room: raw_item.location_name
            }
        };
        // Setup organiser for booking
        if (raw_item.organiser || raw_item.organizer) {
            const email = typeof raw_item.organiser === 'string' ?
                raw_item.organiser :
                (typeof raw_item.organizer === 'string' ? raw_item.organizer : (raw_item.organiser || raw_item.organizer || {}).email);
            // Get the organiser's details
            item.organiser = this.parent.Users.get(email) || raw_item.organiser || raw_item.organizer;
            if (item.organiser && item.organiser.email) {
                item.organiser.email = item.organiser.email.toLowerCase();
            }
            // Check if organiser is the current user
            item.is_organiser = item.is_organiser || (item.organiser && user && (item.organiser.id === user.id ||
                email.toLowerCase() === user.email.toLowerCase()));
        }
        this.processRoom(item, raw_item);
        Object.defineProperty(item, 'status', {
            get: () => {
                const now = moment();
                if (item._status && (item as any)._updated < now.valueOf() - 60 * 1000) {
                    item._status = null;
                }
                if (!item._status) {
                    const date = moment(item.date);
                    const upcoming = moment(date).subtract(15, 'm');
                    const ending = moment(date).add(item.duration, 'm');
                    item._status = now.isBefore(upcoming, 'm') ? 'future' : (
                        now.isBefore(date, 'm') ? 'upcoming' : (
                            now.isBefore(ending, 'm') ? 'in_progress' : 'past'));
                    (item as any)._updated = now.valueOf();
                }
                return item._status;
            },
            set: (v) => item._status = v
        });
        if (!item.display.location) { item.display.location = raw_item.location_name; }
        this.createVisitors(item);
        return item;
    }

    public processRoom(item: IBooking, raw_item: any) {
        Object.defineProperty(item, 'room', {
            get: () => {
                let rm = null;
                // Get room associated with the booking
                const id = raw_item.room_id;
                if (item.room_list && item.room_list.length > 0) {
                    rm = item.room_list[0];
                    item.display.room = rm.name || raw_item.room_name || raw_item.location_name;
                    item.display.level = rm.level ? rm.level.name : '';
                    return rm;
                }
                if (id instanceof Array) {
                    const rm_list: IRoom[] = [];
                    for (const rm_id of id) {
                        const room = this.parent.Rooms.item(rm_id);
                        if (room) { rm_list.push(room); }
                    }
                    item.room_list = rm_list;
                    if (item.room_list.length > 0) {
                        rm = item.room_list.length[0] || {};
                        const same_level = item.room_list.filter(i => i.level && rm.level && i.level.id === rm.level.id).length >= item.room_list.length;
                        item.display.level = same_level ? rm.level.name : 'Multiple levels';
                        item.display.room = item.room_list.reduce((a, b) => a += (a ? ', ' + b.name : b.name), '') || raw_item.location_name;
                    }
                } else {
                    rm = this.parent.Rooms.item(id);
                    if (rm) {
                        item.display.room = rm.name || raw_item.room_name || raw_item.location_name;
                        item.display.level = rm.level ? rm.level.name : '';
                        item.room_list = [rm];
                    }
                }
                return rm;
            },
            set: (v) => {
                if (v) { item.room_list = v instanceof Array ? v : [v]; }
            }
        });
    }

    /**
     * Create visitor group from booking
     * @param booking Booking data
     */
    private createVisitors(booking: IBooking) {
        if (booking.visitors) {
            const visitor_list: any[] = [];
            for (const person of booking.attendees) {
                if (person.external) {
                    visitor_list.push(person);
                }
            }
            if (!visitor_list || visitor_list.length <= 0) { return; }
            const group = {
                id: booking.id,
                visitors: visitor_list,
                date: booking.date,
                duration: booking.duration,
                group_name: booking.title,
                user_id: booking.organiser ? booking.organiser.id || '' : '',
                user_email: booking.organiser ? booking.organiser.email || booking.organiser : '',
                room_id: booking.room ? booking.room.id : '',
                location: booking.display.location
            };
            this.parent.Visitors.updateList([this.parent.Visitors.processItem(group)], false);
        }
    }

    protected format(item) {
        const form = item || {};
        if (!form.room) { form.room = {}; }
        const date = moment(form.date).startOf('m');
        let room_id: string[] = [];
        let rate = 0;
        if (form.room instanceof Array) {
            room_id = [];
            for (const rm of form.room) { room_id.push(rm.email || rm.id); }
            rate = form.room[0].rate;
        } else if (form.room && (form.room.email || form.room.id)) {
            room_id.push(form.room.email || form.room.id);
            rate = form.room.rate;
        }
        const request: any = {
            start: date.unix(),
            end: date.add(form.duration, 'm').unix(),
            room_id: room_id[0],
            title: form.title,
            description: form.description,
            attendees: form.attendees,
            other: form.other,
            from_room: form.from_room || '',
            loan_items: form.loan_items || '',
            private: form.private || false,
            catering: form.catering || false,
            cost_code: form.cost_code,
            parking: form.catering || false,
            booking_type: form.booking_type || 'internal',
            notify_users: form.notify_users,
            card_payment: form.card_payment,
            rate
        };
        if (item.icaluid) { request.icaluid = item.icaluid; }
        if (item.location) { request.location = item.location; }
        if (item.organiser) { request.organiser = item.organiser; }
        if (item.host) {
            request.host = item.host;
            const exists = form.attendees.find(i => i.email.toLowerCase() === item.host.email.toLowerCase());
            if (!request.notify_users) { request.notify_users = [item.host.email]; }
            if (!exists) {
                request.attendees.unshift(item.host);
            }
        }
        if (item.recurr || item.recurrence) {
            request.recurr = {
                period: ['none', 'daily', 'weekly', 'monthly', 'yearly'][item.recurr || item.recurrence.period],
                end: Math.floor((item.recurr_end || item.recurrence.end) / 1000)
            };
        }
        return request;
    }

    public updateList(list: IBooking[]) {
        super.updateList(list);
        const timeline = this.get('timeline') || {};
        list.forEach(bkn => {
            const date = moment(bkn.date);
            timeline[date.format('YYYY-MM-DD')] = [];
        });
        // Add bookings to timeline
        for (const item of list) {
            const date = moment(item.date);
            let day = timeline[date.format('YYYY-MM-DD')] || [];
            day = day.filter(event => event.id !== item?.id);
            day.push(item);
            timeline[date.format('YYYY-MM-DD')] = day;
        }
        // Sort events in the timeline

        for (const key in timeline) {
            if (timeline.hasOwnProperty(key)) {
                timeline[key].sort((a, b) => a.date - b.date);
            }
        }
        this.set('timeline', timeline);
        this.parent.Visitors.checkList();
    }

    public clearList() {
        super.clearList();
        this.set('timeline', {});
    }

    public removeFromList(list: IBooking[]) {
        super.removeFromList(list);
        // Also remove booking from timeline
        this.removeFromTimeline(list);
    }

    public removeFromTimeline(list: IBooking[]) {
        const timeline = this.get('timeline') || {};
        for (const item of list) {
            const date = moment(item.date);
            const day = timeline[date.format('YYYY-MM-DD')] || [];
            for (const event of day) {
                if (item.id && event.id === item.id) {
                    day.splice(day.indexOf(event), 1);
                    break;
                }
            }
            if (item.visitors) {
                this.parent.Visitors.removeFromTimeline([item as any]);
            }
        }
        this.set('timeline', timeline);
    }

    public clear(fields?: { from?: number, to?: number }) {
        if (!fields || !fields.from) {
            super.clear(fields);
        } else {
            const start = moment(fields.from).startOf('d');
            const end = moment(fields.to || fields.from);
            // Clear period in list
            const list: IBooking[] = this.get('list') || [];
            this.set<IBooking[]>('list', list.filter(i => !moment(i.date).isBetween(start, end)));
            // Clear period in timeline
            const timeline = this.get('timeline') || {};
            for (; start.isBefore(end, 'm'); start.add(1, 'd')) {
                const date = start.format('YYYY-MM-DD');
                if (timeline[date]) { delete timeline[date]; }
            }
            this.set('timeline', timeline);
        }
    }

    protected confirmSettings(key: string, fields: { [name: string]: any } = {}) {
        const settings = super.confirmSettings(key, fields);
        const date = moment(fields.date || '');
        const user = this.parent.Users.current();
        const room: any = fields.room ? this.parent.Rooms.item(fields.room.id) : { name: fields.room_name };
        const bkn_type: string = room ? room.book_type : null;
        const lvl = room ? room.level : null;
        const display_name = fields.name || fields.title || '';
        // Get cancellation policy
        const terms = this.parent.Buildings.current() ?  this.parent.Buildings.current().terms : [];
        const cancellation_policy = terms.find(i => i.title === 'Cancellation Policy');
        switch (key) {
            case 'add':
                settings.title = `${bkn_type || (!lvl ? 'Schedule' : 'Book')} ${lvl ? 'space' : 'meeting'}`;
                if (lvl) {
                    settings.message = `${bkn_type || 'Book'} space "${room.name}"<br>`;
                } else {
                    settings.message = 'Meeting ';
                }
                settings.message += `
                    on the ${date.format('Do of MMMM, YYYY')} at ${date.format('h:mma')}<br>
                    for ${Utils.humaniseDuration(fields.duration)}
                `;
                break;
            case 'delete':
                if (!fields.is_organiser) {
                    settings.title = `Decline meeting`;
                    settings.message = `Are you sure you wish to decline attendance to meeting '${fields.name || fields.title || ''}'?`;
                    settings.icon = 'event_busy';
                } else {
                    if (lvl) {
                        settings.title = `Delete meeting`;
                        settings.message = `
                            Are you sure you wish to cancel meeting ${display_name ? ' \'' + display_name + '\'' : ''}?<br>
                            <div style="text-align:left;margin-top:1em;">
                                <div style="font-weight:bold">Cancellation Policy:</div>
                                ${cancellation_policy.details}
                            </div>
                        `;
                    } else {
                        settings.title = 'Delete Visitor';
                        settings.message = 'Are you sure you wish to delete this visitor?'
                    }
                    settings.icon = 'delete';
                }
                break;
            case 'update':
                // settings.message = `Change booking ${room.name ? 'for "' + room.name + '"' : ''}<br>
                //                     to the ${date.format('Do of MMMM, YYYY')} at ${date.format('h:mma')}<br>
                //                     for ${Utils.humaniseDuration(fields.duration)}`;
                settings.message = '';
                break;
        }
        return settings;
    }
}
