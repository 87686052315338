
import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { BaseComponent } from '../base.component';
import { IUser } from '../../../services/data/users.service';
import { IBaseObject } from '../../../services/data/base.service';
import { Utils } from '../../utility.class';

@Component({
    selector: 'user-form',
    templateUrl: './user-form.template.html',
    styleUrls: ['./user-form.styles.scss']
})
export class UserFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public form: { [name: string]: any } = {};
    @Input() public select = false;
    @Input() public clear = false;
    @Input() public search = true;
    @Input() public orgs: IBaseObject[];
    @Input() public visitors = false;
    @Input() public edit = false;
    @Output() public formChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: any = { form: {} };

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            if (bld) { this.processOrganisations(); }
        });
        this.init();
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.service.Users.listen('list', (list) => {
            this.model.users = list;
        });
        this.model.external = this.service.Settings.get('app.users.external');
        this.model.can_add_org = this.service.Settings.get('app.users.custom_orgs');
        this.model.min_char = Math.max(this.service.Settings.get('app.users.people_min_char') || 0, 3);
        this.model.selected_user = false;
        this.processOrganisations();
        this.find(false);
    }

    public ngOnChanges(changes: any) {
        if (changes.form && this.form) {
            this.model.form = {};
            for (const k in this.form) {
                if (this.form.hasOwnProperty(k)) {
                    this.model.form[k] = this.form[k];
                }
            }
            if (this.model.form.users) {
                (this.model.results || []).forEach(i => {
                    const user = this.model.form.users.find(k => k.id === i.id);
                    i.selected = !!user;
                });
                this.model.selected_user = this.model.form.users.length > 0;
            } else {
                (this.model.results || []).forEach(i => i.selected = false);
                this.model.selected_user = false;
            }
            if ((this.model.form.organisation || this.model.form.organisation_id) && this.model.org && this.model.org.list) {
                for (const org of this.model.org.list) {
                    if ((this.model.form.organisation && org.name === this.model.form.organisation.name) ||
                        (this.model.form.organisation_id && org.id === this.model.form.organisation_id)) {
                        this.model.org.active = this.model.org.list.indexOf(org);
                        break;
                    }
                }
            }
        }
        if (changes.orgs) {
            this.processOrganisations();
        }
        if (changes.clear && this.clear) {
            this.model.search = '';
            this.model.form = {};
            if (this.model.org) {
                this.model.org.active = -1;
            }
            this.find(false);
        }
    }

    public find(post: boolean = true) {
        this.timeout('find', () => {
            this.model.results = [];
            this.model.form.users = [];
            if (post) { this.post(); }
            if (this.model.search && this.model.search.length >= this.model.min_char) {
                this.model.searching = true;
                const search = this.model.search.toLowerCase();
                if (!this.visitors) {
                    this.service.Users.query({ q: search }).then((list) => {
                        this.model.results = list;
                        this.model.searching = false;
                    }, (e) => this.model.searching = false);
                } else {
                    this.model.results = [
                        ...this.model.users,
                        ...this.service.Contacts.list()
                    ].filter((a) => {
                        const name = a.name.toLowerCase();
                        const email = a.email.toLowerCase();
                        return name.indexOf(search) >= 0 || email.indexOf(search) >= 0;
                    }).sort((a, b) => a.name.localeCompare(b.name)) || [];
                    this.model.searching = false;
                }
            } else if (this.visitors) {
                this.model.results = [
                    ...this.model.users,
                    ...this.service.Contacts.list()
                ].sort((a, b) => a.name.localeCompare(b.name)) ;
            }
            this.model.results = this.model.results.map(i => ({ ...i }));
        }, 300);
    }

    public post() {
        this.validate();
        const form = this.model.form ? { ...this.form, ...this.model.form } : {};
        this.form = form;
        if (this.model.valid) {
            this.formChange.emit(form);
        }
    }

    /**
     * Callback for when the user search input is blurred
     */
    public blur() {
        this.timeout('blur', () => this.model.focus = false);
    }

    public selectUser(item: any) {
        this.timeout('select', () => {
            const user_list = this.model.results;
            this.model.form.users = [];
            this.model.selected_user = false;
            for (const i of user_list) {
                if (i === item) {
                    i.selected = !i.selected;
                } else if (!this.select) {
                    i.selected = false;
                }
                if (i.selected) { this.model.form.users.push(i); }
                this.model.selected_user = (this.model.selected_user || i.selected) && !this.select;
            }
            this.model.selected_user = item.selected || this.model.form.users.length > 0;
            this.post();
            if (item.selected && this.select) {
                this.event.emit(item);
            }
        }, 100);
    }

    public validate() {
        this.model.error = {};
        this.model.valid = true;
        if ((!this.model.form.users || this.model.form.users.length <= 0)) {
            if (!this.model.form.email) {
                this.model.error.email = { msg: 'Email is required' };
                this.model.valid = false;
            }
            const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            if (!regex.test(this.model.form.email)) {
                this.model.error.email = { msg: 'A valid email is required' };
                this.model.valid = false;
            }
            if (!this.model.form.organisation) {
                this.model.error.org = { msg: 'Organisation is required' };
                this.model.valid = false;
            }
            if (!this.model.form.first_name) {
                this.model.error.first_name = { msg: 'First name is required' };
                this.model.valid = false;
            }
            if (!this.model.form.last_name) {
                this.model.error.last_name = { msg: 'Last name is required' };
                this.model.valid = false;
            }
        }
    }

    public checkEmail() {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (this.model.form && this.model.form.email && regex.test(this.model.form.email) && !this.model.checking_email) {
            this.timeout('check-email', () => {
                this.model.checking_email = true;
                if (!this.visitors) {
                    this.service.Users.query({ q: this.model.form.email }).then((list) => {
                        const user = list ? list[0] || null : null;
                        if (user && user.email === this.model.form.email) { this.setUser(user); }
                        this.model.checking_email = false;
                    }, (e) => this.model.checking_email = false);
                } else {
                    const list = this.service.Contacts.list();
                    const user = list.find(i => i.email.indexOf(this.model.form.email) !== -1);
                    if (user && user.email === this.model.form.email) { this.setUser(user); }
                    this.model.checking_email = false;
                }
            }, 500);
        }
    }

    private setUser(user: IUser) {
        if (user) {
            this.service.info(`Found user with matching email address`);
            for (const k in user) {
                if (user.hasOwnProperty(k) && user[k]) {
                    this.model.form[k] = user[k];
                }
            }
            if (this.model.form.organisation) {
                this.model.form.organisation = this.model.form.organisation.name || this.model.form.organisation;
            }
            if (this.model.organisation_name || this.model.form.organisation_id) {
                for (const org of this.model.org.list) {
                    if (org.name === this.model.form.organisation_name || org.id === this.model.form.organisation_id) {
                        this.model.org.active = this.model.org.list.indexOf(org);
                        break;
                    }
                }
            }
            this.post();
        }
    }

    public updateOrg() {
        this.validate();
        if (this.model.org && this.model.org.list) {
            const org = this.model.org.list[this.model.org.active];
            if (org) {
                this.model.form.organisation_id = org.id;
                this.model.form.organisation_name = org.name;
            } else {
                this.model.form.organisation_id = null;
                this.model.form.organisation_name = '';
            }
            this.post();
        }
    }

    private processOrganisations(tries: number = 0) {
        if (tries > 5) { return; }
        const bld = this.service.Buildings.current();
        const org = this.service.Buildings.organisation();
        if (!bld || !org || !org.orgs) {
            return setTimeout(() => this.processOrganisations(tries), 300 * ++tries);
        }
        this.model.org = {
            list: JSON.parse(JSON.stringify(bld.orgs || org.orgs || [])),
            name: []
        };
        if (this.model.org.list.length <= 0) {
            return setTimeout(() => this.processOrganisations(tries), 300 * ++tries);
        }
        if (this.orgs) {
            this.orgs.forEach(o => this.model.org.list.findIndex(i =>
                i.id === o.id || i.name.toLowerCase() === o.name.toLowerCase()
            ) < 0 ? this.model.org.list.push(o) : null);
            this.model.org.list.sort((a, b) => {
                const a_used = this.orgs.findIndex(i => i.id === a.id || i.name.toLowerCase() === a.name.toLowerCase()) >= 0;
                const b_used = this.orgs.findIndex(i => i.id === a.id || i.name.toLowerCase() === a.name.toLowerCase()) >= 0;
                const cmp = (a.name || '').localeCompare(b.name);
                return a_used && !b_used ? (!a_used && b_used ? cmp : -1) : 1;
            });
        }
        this.model.org.name = [];
        for (const o of this.model.org.list) {
            this.model.org.name.push(o.name || o);
        }
        if ((!this.model.org.active && this.model.org.active !== 0) || this.model.org.active >= this.model.org.list.length) {
            this.model.org.active = -1;
        }
    }

    public updateOrganisationTypeahead() {
        this.model.filtered_orgs = Utils.filter(this.model.form.organisation, this.orgs || [], ['name']);
        this.model.filtered_orgs.forEach(i => {
            if (i.match_name) {
                i.match_name = i.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i.match_name = i.match_name.replace(/\`/g, '');
            }
        });
        this.model.show_org_tooltip = this.model.form.organisation && this.model.filtered_orgs.length > 0;
    }

    public setOrg(item) {
        this.model.form.organisation = item.name;
        this.model.show_org_tooltip = false;
        this.post();
    }
}
