
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';

import * as moment from 'moment';

export interface IRecurrence {
    period: number;
    end?: number;
}

type FieldType = IRecurrence;

@Component({
    selector: 'custom-recurrence-field',
    templateUrl: './recurrence-field.component.html',
    styleUrls: ['./recurrence-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class CustomRecurrenceFieldComponent extends BaseComponent implements ICustomField<FieldType>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<FieldType>;

    public period_options = [
        { id: 'none', name: 'None' },
        { id: 'daily', name: 'Daily' },
        { id: 'weekly', name: 'Weekly' },
        { id: 'monthly', name: 'Monthly' },
        { id: 'yearly', name: 'Yearly' }
    ];
    public details: FieldType;
    public date: string;
    public before: number = moment().valueOf();
    public show: boolean;
    public show_tooltip: boolean;

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => this.updateDisplay());
            }

            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.init();
        }
    }

    public init() {
        if (!this.control || !this.control.value) {
            this.setValue({ period: 0 });
        }
        this.updateDisplay();
    }

    public updateDisplay() {
        if (this.ref_control && this.ref_control.value) {
            this.before = moment(this.ref_control.value).valueOf();
        }
        this.details = this.control.value || { period: 0 };
        this.date = moment(this.details.end).format('DD MMM YYYY');
    }

    public setValue(value: FieldType) {
        console.warn('Set Value:', value, this.control.value);
        this.control.setValue(value);
    }
}
