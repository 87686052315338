<ng-container *ngIf="form">
    <div #ref class="reference"></div>
    <div [formGroup]="form">
        <custom-dropdown klass="form" [(show)]="model.show" [content]="content" [template]="list">
            <ng-template #content>
                <div class="header">
                    <div class="input">
                        <input [formControlName]="field.key" [id]="field.key" [placeholder]="field.label" type="time" (blur)="cleanTime()">
                    </div>
                    <div class="icon" (touchrelease)="show()">
                        <i class="material-icons">{{model.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</i>
                    </div>
                </div>
            </ng-template>
            <ng-template #list>
                <div class="item-list" [style.height]="(model.list_height * 2 * font_size) + 'px'">
                    <ng-container *ngIf="model.blocks">
                        <cdk-virtual-scroll-viewport [itemSize]="2 * font_size">
                            <ng-container *cdkVirtualFor="let item of model.blocks; let i = index">
                                <div class="item" [class.hide]="hideActive && (item.i || i) === model.index" (touchrelease)="setValue(item.id)">
                                    <div class="text">{{ item.name || item }}</div>
                                    <div class="icon">
                                        <div class="done" *ngIf="model.index === (item.i || i) ? 1 : 0"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </ng-container>
                </div>
            </ng-template>
        </custom-dropdown>
    </div>
</ng-container>
