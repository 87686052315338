import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'old-time-period-modal',
    templateUrl: './time-period-modal.template.html',
    styleUrls: ['./time-period-modal.styles.scss'],
})
export class OldTimePeriodModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.form = {};
        this.model.display = {};
        this.model.focus = {};
        this.model.min_duration = this.service.Settings.get('app.booking.min_length') || 30;
        this.model.max_duration = this.service.Settings.get('app.booking.max_length') || 480;
        if (!this.model.duration) { this.model.duration = 60; }
        if (!this.model.end) { this.model.simple = true; }
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5);
            // Set start and end times
        this.model.form.start = this.model.start || now.format('HH:mm');
        this.model.form.end = this.model.end || now.add(this.model.duration || 60, 'm').format('HH:mm');
            // Setup duration
        if (this.model.form.start && this.model.form.end) {
            const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
            const end = moment().hours(this.model.form.end.split(':')[0]).minutes(this.model.form.end.split(':')[1]);
            this.model.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
        }
        this.updateDisplay();
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
        start.minutes(Math.ceil(start.minutes() / 5) * 5);
        this.model.form.start = start.format('HH:mm');
        const end = moment(start).add(this.model.duration, 'm');
        this.model.form.end = end.format('HH:mm');
        this.model.form.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
        setTimeout(() => this.fn.event(name), 300);
    }

    public adjustDuration() {
        if (this.model.form.end) {
            const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
            start.minutes(Math.ceil(start.minutes() / 5) * 5);
            const end = moment().hours(this.model.form.end.split(':')[0]).minutes(this.model.form.end.split(':')[1]);
            end.minutes(Math.ceil(end.minutes() / 5) * 5);
            if (start.isAfter(end, 'm')) {
                end.add(1, 'd');
            }
            this.model.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
            if (this.model.duration < this.model.min_duration) {
                this.model.duration = this.model.min_duration;
            } else if (this.model.duration > this.model.max_duration) {
                this.model.duration = this.model.max_duration;
            }
        }
        this.updateDisplay();
    }

    public adjustEnd() {
        if (this.model.form.start) {
            const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
            start.minutes(Math.ceil(start.minutes() / 5) * 5);
            const end = moment(start).add(this.model.duration, 'm');
            end.minutes(Math.ceil(end.minutes() / 5) * 5);
            this.model.form.end = end.format('HH:mm');
        }
        this.updateDisplay();
    }

    public updateDisplay() {
        const h = Math.floor(this.model.duration / 60);
        this.model.display.duration = `${h >= 1 ? (h + ' hour' + (h === 1 ? '' : 's')) : '' }`;
        if (this.model.duration % 60 !== 0) {
            if (this.model.display.duration) { this.model.display.duration += ', '; }
            const m = this.model.duration % 60;
            this.model.display.duration += `${m >= 1 ? (m + ' minute' + (m === 1 ? '' : 's')) : '' }`;
        }
    }
}
