<div class="container">
    <div class="header">
        <div class="text">Select {{model.name || 'options'}}</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="model.options">
            <div class="item" *ngFor="let item of model.options; let i = index" (touchrelease)="select(item)">
                <div class="text">{{item}}</div>
                <div class="icon" *ngIf="i === model.selected">
                    <i class="material-icons">done</i>
                </div>
            </div>
        </ng-container>
        <div class="no-item" center *ngIf="!model.options">
            <div class="icon"><i class="material-icons">touch</i></div>
            <div class="text">No {{model.name || options}} to select</div>
        </div>
    </div>
</div>