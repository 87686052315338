import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { Utils } from '../../shared/utility.class';
import { BaseService } from './base.service';
import { ILocation } from './location.service';

import * as moment from 'moment';
import { ITDEAnalyticsService } from '../itde-analytics.service';

export interface IUser {
    id: string;
    name: string;
    first_name?: string;
    last_name?: string;
    email: string;
    win_id?: string;
    type?: 'partner' | 'internal' | 'external';
    role?: string;
    state?: string;
    location?: ILocation | string | {};
    image: string;
    phone?: string;
    lockers?: { id: string; expiry: number }[];
    external?: boolean;
    optional?: boolean;
    staff_code?: string;
    organisation_id?: string;
    organisation_name?: string;
    organisation?: { id: string; name: string };
    b_unit?: string;
    timezone?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseService<IUser> {
    constructor(protected http: CommsService, private http_unauth: HttpClient, protected _analytics: ITDEAnalyticsService) {
        super();
        this.model.name = 'user';
        this.model.route = '/users';
        this.subjects.list = new BehaviorSubject<IUser[]>([]);
        this.observers.list = this.subjects.list.asObservable();
        this.set('user', null);
        this.set('state', 'loading');
    }

    protected load(tries: number = 0) {
        if (tries > (this.parent.Settings.get('composer.local_login') ? 1 : 20)) {
            return this.set('state', 'invalid');
        }
        this.set('state', 'loading');
        this.show('current', { control: true }).then(
            user => {
                if (user) {
                    this.set('user', user);
                    this.set('state', 'available');
                    // Locate the active user
                    if (this.parent.Settings.get('app.user.current_location')) {
                        this.location(user.id, user.email).then(() => null, () => null);
                        this.interval(
                            'active-location',
                            () => {
                                this.location(user.id, user.email).then(() => null, () => null);
                            },
                            30 * 1000
                        );
                    }
                } else {
                    this.timeout('load', () => this.load(tries), 300 * ++tries);
                }
            },
            () => this.timeout('load', () => this.load(tries), 300 * ++tries)
        );
    }

    /**
     * Get current user
     */
    public current() {
        return this.get('user');
    }

    /**
     * Sets the access token and expiry for the user
     * @param token OAuth bearer token
     * @param expiry Expiry epoch timestamp in ms
     */
    public setToken(token: string, expiry?: number) {
        if (!expiry) {
            expiry = moment()
                .add(7, 'd')
                .valueOf();
        }
        const path = `${location.origin}${this.parent.Settings.get('composer.route') || ''}/oauth-resp.html`;
        const client_id = this.http.hash(path);
        if (sessionStorage) {
            sessionStorage.setItem(`${client_id}_access_token`, token);
            sessionStorage.setItem(`${client_id}_expires_at`, `${expiry}`);
        }
        if (localStorage) {
            localStorage.setItem(`${client_id}_access_token`, token);
            localStorage.setItem(`${client_id}_expires_at`, `${expiry}`);
            location.reload();
        }
        return path;
    }

    public getFilteredUsers(filter: string, items: IUser[] = this.list(), fields: string[] = ['name', 'email']) {
        return this.filter(filter, fields, items);
    }

    /**
     * Login with given credentials
     * @param fields Key value pairs of post parameters
     */
    public login(fields: { [name: string]: any } = {}) {
        this.subjects.state.next('loading');
        const query = Utils.generateQueryString(fields);
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.http_unauth.post('/auth/jwt/callback', query, { headers }).subscribe(
            (res: any) => {
                if (res.status >= 200 && res.status < 400) {
                    if (sessionStorage) {
                        const clientId = this.http.hash(`${location.origin}/oauth-resp.html`);
                        sessionStorage.setItem(`${clientId}_login`, 'true');
                    }
                    this.http.tryLogin();
                } else {
                    this.subjects.state.next('invalid');
                }
                return;
            },
            err => {
                if (err.status >= 400) {
                    this.subjects.state.next('error');
                } else {
                    if (sessionStorage) {
                        const clientId = this.http.hash(`${location.origin}/oauth-resp.html`);
                        sessionStorage.setItem(`${clientId}_login`, 'true');
                    }
                    this.http.tryLogin();
                }
            },
            () => this.load()
        );
    }

    /**
     * Logout of user and redirect to logout URL
     */
    public logout() {
        this.http.logout();
    }

    /**
     * Open modal to view user details
     * @param item User to view
     */
    public view(item: IUser) {
        if (this.parent) {
            this.parent.Overlay.openModal('user-details', { data: { user: item } }).then((inst: any) =>
                inst.subscribe(event => {
                    if (event.type === 'close') {
                        event.close();
                    }
                })
            );
        }
    }

    /**
     * Open modal to create new user
     * @param next Callback for events on the modal
     */
    public new(data: { [name: string]: any }): Promise<any> {
        return new Promise((resolve) => {
            if (this.parent) {
                this.parent.Overlay.openModal('user-details', { data: {} }).then((inst: any) =>
                    inst.subscribe(event => {
                        if (event.type === 'close') {
                            event.close();
                        }
                        resolve(event);
                    })
                );
            } else {
                resolve(null);
            }
        });
    }

    /**
     * Add new user
     * @param user User data
     */
    public add(user: { [name: string]: any }) {
        return new Promise<any>((resolve, reject) => {
            const data: { [name: string]: any } = {
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone,
                email: user.email,
                organisation: user.organisation,
                organisation_id: null
            };
            if (user.organisation_id) {
                data.organisation_id = user.organisation_id;
            }
            const url = `${this.endpoint}`;
            this.http.post(url, data).subscribe(
                () => null,
                err => {
                    reject(err);
                    this.parent.Analytics.event('Users', 'add_user_fail');
                },
                () => {
                    resolve(null);
                    this.parent.Analytics.event('Users', 'added_user');
                }
            );
        });
    }

    /**
     * Get location data for user
     * @param id ID of user to find
     * @param email Email of user to find
     */
    public location(id: string, email: string = id) {
        const key = `location|${id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                if (!this.parent || !this.parent.ready()) {
                    return setTimeout(() => {
                        this.promises[key] = null;
                        this.location(id, email).then(d => resolve(d), e => reject(e));
                    }, 300);
                }
                if (!this.parent.Settings.get('app.users.locate') || !this.parent.Location) {
                    this.parent.log('USER(S)', 'Locate user is disabled', null, 'warn');
                    return reject('Locate user is disabled');
                }
                this.parent.Location.show(id, { desk: email }).then(
                    loc => {
                        if (!loc) {
                            this.updateUserLocation(id, {});
                            reject('User not found');
                            this.promises[key] = null;
                            this.parent.Analytics.event('Users', 'locate_user_failed');
                        } else {
                            const u = this.item(id);
                            if (u) {
                                u.location = loc;
                            }
                            this.parent.Analytics.event('Users', 'locate_user_success');
                            resolve(loc);
                            this.timeout(`clear-loc-${key}`, () => (this.promises[key] = null), 1000);
                        }
                    },
                    e => {
                        reject(e);
                        this.promises[key] = null;
                        this.parent.Analytics.event('Users', 'locate_user_failed');
                    }
                );
            });
        }
        return this.promises[key];
    }

    /**
     * Store new location data for user
     * @param id ID of user
     * @param data New location data
     */
    public updateUserLocation(id, data) {
        const list = this.list() || [];
        for (const user of list) {
            if (user.id === id) {
                user.location = data;
            }
        }
    }

    public availability(email: string, start?: number, end?: number) {
        const name = `availability|${email}|${start || 'now'}|${end || 'soon'}`;
        if (!this.promises[name]) {
            this.promises[name] = new Promise((resolve, reject) => {
                const url = `${this.endpoint}/${email}`;
                this.parent.Bookings.query({ start, end, email }).then(
                    list => {
                        resolve(!list || list.length <= 0);
                        this.promises[name] = null;
                    },
                    err => {
                        reject(err);
                        this.promises[name] = null;
                    }
                );
            });
        }
        return this.promises[name];
    }

    /**
     * Convert user data to local format
     * @param user User data
     */
    public processItem(user: { [name: string]: any }) {
        if (!user) {
            return null;
        }
        const u_org = user.organisation;
        const org = {
            id: typeof u_org !== 'string' && u_org ? u_org.id : user.organisation_id,
            name: typeof u_org !== 'string' && u_org ? u_org.name : user.organisation_name || user.organisation
        };
        const member: IUser = {
            id: user.id || user.email,
            win_id: user.email,
            name: user.name || `${user.first_name} ${user.last_name}`,
            first_name: user.first_name,
            last_name: user.last_name,
            type: user.visitor ? 'external' : user.type || 'internal',
            image: null,
            email: (user.email || '').toLowerCase(),
            phone: user.phone || user.mobile,
            b_unit: user.department,
            lockers: user.lockers,
            organisation_id: org.id || org.name,
            organisation: org,
            external: user.type === 'external' || user.visitor,
            location: user.location ? this.parent.Location.processItem(user.location) : null,
            organisation_name: org.name,
            staff_code: user.staff_code
        };
        if (member.id) {
            member.image = user.image || `${this.parent.endpoint}/assets/users/${member.id}.png`;
        }
        return member;
    }
}
