
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

const SIDE_BLOCK = 40; // window.innerWidth / 8;

@Component({
    selector: 'overlay-menu',
    templateUrl: './overlay-menu.template.html',
    styleUrls: ['./overlay-menu.styles.scss']
})
export class OverlayMenuComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public show = false;
    @Output() public showChange = new EventEmitter();
    @Output() public building = new EventEmitter();

    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.init();
        this.subs.obs.router_events = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
    }

    public ngOnChanges(changes: any) {
        if (changes.show) {
            setTimeout(() => this.clearTimer('close'), 50);
        }
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.menu = this.service.Settings.get('app.menu') || {};
        this.model.list = this.model.menu.list || [];
        this.model.copyright = this.service.Settings.get('app.copyright');
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            this.model.bld = bld;
        });
        this.subs.obs.debug = this.service.listen('APP.enable_debug_options', (state) => {
            this.model.debugging = state;
            if (state) {
                this.model.list = (this.model.menu.list || []).concat([
                    {
                        name: 'Debugging',
                        items: [
                            { id: 'enable_debugging', name: 'Enable remote debugging' }
                        ]
                    }, {
                        name: 'Emergency',
                        items: [
                            { id: 'device_list', name: 'Active device list' }
                        ]
                    },
                ]);
            }
        });
        this.model.bld_count = (this.service.Buildings.list() || []).length;
    }

    public tap() {
        this.timeout('clear-close', () => this.clearTimer('close'), 50);
    }

    public close() {
        this.timeout('close', () => {
            this.show = false;
            this.showChange.emit(this.show);
        }, 100);
    }

    public goto(item: any) {
        if (item.id === 'terms') {
            this.service.viewTerms();
        } else if (item.id === 'logout') {
            this.service.logout();
        } else if (item.id === 'enable_debugging') {
            // this.service.logout();
        } else if (item.id === 'device_list') {
            this.service.Overlay.openModal('device-list', { data: {} }, (e) => e.close());
        } else if (item.id) {
            this.service.navigate(item.id, item.query || {});
        } else if (item.link || item.url) {
            window.open(item.link || item.url, 'blank_');
        }
        if (this.model.menu) {
            this.model.menu.show = false;
        }
    }

    public swipe(event) {
        if (this.service.get('APP.swipe_disabled')) { return; }
        if (event.center.x + -event.deltaX < SIDE_BLOCK) {
            this.show = !!this.model.menu.reverse;
            this.showChange.emit(this.show);
        } else if (event.center.x + -event.deltaX > window.innerWidth - SIDE_BLOCK) {
            this.show = !this.model.menu.reverse;
            this.showChange.emit(this.show);
        }
    }


    public checkRoute() {
        this.timeout('route', () => {
            const route = this.router.url.split('?')[0];
            if (this.model.menu && this.model.menu.list) {
                let found = false;
                for (const i of this.model.menu.list) {
                    i.active = route === `/${i.id}` && !found;
                    if (!found) { found = route === `/${i.id}`; }
                    if (i.items) {
                        for (const itm of i.items) {
                            itm.active = route === `/${itm.id}` && !found;
                            if (!found) { found = route === `/${itm.id}`; }
                        }
                    }
                }
            }
        }, 50);
    }
}
