
import { Component, Input, Output, EventEmitter, OnChanges, OnInit, ElementRef, ViewChild } from '@angular/core';

import { BaseComponent } from '../base.component';
import { AppService } from '../../../services/app.service';

import * as moment from 'moment-timezone';
import { IUser } from '../../../services/data/users.service';
import { Utils } from '../../utility.class';
import { IBooking } from '../../../services/data/bookings.service';

@Component({
    selector: 'user-availability',
    templateUrl: './user-availability.template.html',
    styleUrls: ['./user-availability.styles.scss']
})
export class UserAvailabilityComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() public date = moment().valueOf();
    @Input() public duration = 30;
    @Input() public host: IUser;
    @Input() public users: IUser[] = [];
    @Output() public dateChange = new EventEmitter();
    @Output() public durationChange = new EventEmitter();
    @Output() public usersChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: any = {};

    @ViewChild('cal', { static: true }) private calendar: ElementRef;
    @ViewChild('header', { static: true }) private header: ElementRef;
    @ViewChild('content', { static: true }) private content: ElementRef;

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.model.events = {};
        this.model.bookings = {};
        this.model.loading = {};
        this.model.tz_data = {};
        this.model.timezones = [];
        this.model.vertical = true;
        this.model.today = moment().valueOf();
        (window as any).moment = moment;
        if (!this.model.settings) { this.model.settings = {}; }
        this.updateTime();
        this.interval('time', () => this.updateTime(), 60 * 1000);
        this.generateBlocks();
        this.checkSize();
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.user = this.service.Users.current();
        this.model.current_timezone = moment.tz.guess().split('_').join(' ');
        this.model.short_timezone = this.model.current_timezone.split('/').map(v => v.slice(0, 3)).join('/').toUpperCase();
        const date = moment(this.date);
        const now = moment();
        this.model.today = now.isSame(date, 'd');
        this.updateAvailability(this.model.user);
        this.interval('normalise', () => this.updateContent(), 200);
        this.groupUsers();
    }

    public ngOnChanges(changes: any) {
        if (changes.date || changes.users) {
            const user = this.service.Users.current();
            if (!this.model.settings) { this.model.settings = {}; }
            const block_size = this.model.settings.block_size || 15;
            const date = moment(this.date);
            date.minutes(Math.round(date.minutes() / block_size) * block_size);
            this.duration = (Math.round(this.duration / block_size) * block_size) || 15;
            if (changes.users) { this.loadAvailability(); }
            this.updateBlock();
        }
    }

    public loadAvailability() {
        this.model.user_blocks = {};
        this.model.user = this.service.Users.current();
        for (const user of (this.users || [])) {
            this.updateAvailability(user);
        }
    }

    public generateBlocks() {
        if (!this.model.settings) { this.model.settings = {}; }
        // Get settings
        const start = this.model.settings.start_hour || 6.5;
        const end = this.model.settings.end_hour || 21;
        const block_size = this.model.settings.block_size || 30;
        // Generate times
        const start_time = moment(this.date || moment()).hours(start % 24).minutes((start % 1) * 60).seconds(0);
        const time = moment(start_time);
        const end_time = moment(start_time).hours(end % 24).minutes((end % 1) * 60);
        const length = Math.abs(moment.duration(start_time.diff(end_time)).asMinutes());
        // Generate time blocks
        this.model.blocks = [];
        for (; time.isSameOrBefore(end_time, 'm'); time.add(block_size, 'm')) {
            const timezones = {};
            for (const k of this.model.timezones) {
                const tz_time = time.clone().tz(k);
                timezones[k] = {
                    display: tz_time.format('h:00 A'),
                    short_display: tz_time.format('hA'),
                    raw_hour: +tz_time.format('h')
                };
            }
            this.model.blocks.push({
                id: time.format('H:00'),
                display: time.format('h:00 A'),
                short_display: time.format('hA'),
                offset: Math.abs(moment.duration(start_time.diff(time)).asMinutes()) / length,
                length: block_size / length,
                timezones,
                hour: time.minutes() === 0,
                raw: time.valueOf()
            });
        }
        this.loadAvailability();
    }

    public selectTimezone() {
        this.service.Overlay.openModal('timezone-search', { data: {} }, (event) => {
            if (event.type === 'Accept') {
                if (event.data.selected && this.model.timezones.indexOf(event.data.selected.id)) {
                    this.model.timezones.push(event.data.selected.id);
                    this.model.tz_data[event.data.selected.id] = event.data.selected;
                    this.generateBlocks();
                }
                this.groupUsers();
            }
            event.close();
        });
    }

    public removeTimezone(name) {
        this.model.timezones.splice(this.model.timezones.indexOf(name), 1);
        this.groupUsers();
    }

    public updateAvailability(user: IUser) {
        if (!this.model.loading) { this.model.loading = {}; }
        if (user && user.email) {
            this.timeout(`available_${user.id}`, () => {
                const date = moment(this.date);
                this.model.loading[user.id] = true;
                this.service.Bookings.query({
                    email: user.email,
                    from: date.hours(0).minutes(0).seconds(0).unix(),
                    to: date.hours(23).minutes(59).seconds(59).unix()
                }).then((list) => {
                    if (!this.model.events) { this.model.events = {}; }
                    this.model.events[user.id] = this.fillBlocks(list, (user as any).optional);
                    this.model.bookings[user.id] = user.optional ? [] : list;
                    this.model.loading[user.id] = false;
                    this.updateFree();
                }, () => this.model.loading[user.id] = false);
            });
        }
    }

    public groupUsers() {
        this.model.groups = ['None'];
        this.model.group_list = {};
        for (const user of this.users) {
            let index = 0;
            if (user.timezone && user.timezone !== this.model.current_timezone) {
                if (this.model.groups.indexOf(user.timezone) < 0 && this.model.timezones.indexOf(user.timezone) >= 0) {
                    this.model.groups.push(user.timezone);
                }
                index = this.model.groups.indexOf(user.timezone);
            }
            if (index < 0) { index = 0; }
            if (!this.model.group_list[this.model.groups[index]]) {
                this.model.group_list[this.model.groups[index]] = [];
            }
            this.model.group_list[this.model.groups[index]].push(user);
        }
    }

    private fillBlocks(list: any[], optional: boolean = false) {
        const start_time = this.model.settings.start_hour || 6.5;
        const end_time = this.model.settings.end_hour || 21;
        const blocks: any = [];
        let index = 999;
        const block_size = this.model.settings.block_size || 15;
        const start = moment(this.date).hours(Math.floor(start_time)).minutes(Math.floor((start_time * 60) % 60)).seconds(0);
        const end = moment(this.date).hours(Math.floor(end_time)).minutes(Math.floor((end_time * 60) % 60) + 30).seconds(0);
        while (start.isBefore(end)) {
            const bookings = [];
            const time = start.format('HH:mm');
            for (const item of list) {
                const date = moment(item.date);
                const bkn_end = moment(date).add(item.duration, 'm');
                if (date.isSame(start, 'h') && Math.floor(date.minutes() / block_size) === Math.floor(start.minutes() / block_size)) {
                    item.length = item.duration / block_size;
                    bookings.push(item);
                }
            }
            blocks.push({ time, date: start.valueOf(), bookings, 'z-index': --index });
            start.add(block_size, 'm');
        }
        return blocks;
    }

    public updateState(user) {
        this.remove(user, user.optional);
        this.timeout('state_add', () => this.add(user), 100);
    }

    public remove(user: any, change: boolean = false) {
        this.model.bookings[user.id] = [];
        this.users.splice(this.users.indexOf(user), 1);
        this.post();
    }

    public updateFree() {
        if (!this.model.bookings) { return; }
        this.model.user_blocks = {};
        const block_size = this.model.settings.block_size || 15;
        for (const id in this.model.bookings) {
            if (this.model.bookings.hasOwnProperty(id)) {
                const list = this.model.bookings[id];
                for (const bkn of list) {
                    const bkn_start = moment(bkn.date);
                    bkn_start.minutes(Math.floor(bkn_start.minutes() / block_size) * block_size);
                    const bkn_end = moment(bkn_start).add(bkn.duration, 'm');
                    for (; bkn_start.isBefore(bkn_end, 'm'); bkn_start.add(5, 'm')) {
                        const time = bkn_start.format('HH:mm');
                        if (!this.model.user_blocks[time]) { this.model.user_blocks[time] = 0; }
                        this.model.user_blocks[time]++;
                    }
                }
            }
        }
    }

    public accept() {
        if (!this.hasConflicts()) {
            this.post();
            this.event.emit({ type: 'Accept' });
        } else {
            this.service.confirm({
                title: `Accept time?`,
                message: `There are scheduling conflicts with the selected time.<br>Are you sure you want to continue with the selected date and time?`,
                icon: 'event_busy',
                accept: 'Ok',
                cancel: true,
            }, (e) => {
                if (e.type === 'Accept') {
                    this.post();
                    this.event.emit({ type: 'Accept' });
                }
                e.close();
            });
        }
    }

    public hasConflicts() {
        const start = moment(this.date);
        const end = moment(start).add(this.duration, 'm');
        for (; start.isBefore(end); start.add(5, 'm')) {
            const time = start.format('HH:mm');
            if (this.model.user_blocks[time] && this.model.user_blocks[time] > 0) {
                return true;
            }
        }
        return false;
    }

    public search() {
        this.timeout('search', () => {
            this.model.show = true;
            if (!this.model.loading) { this.model.loading = {}; }
            this.model.loading['searching'] = true;
            this.model.search_results = [];
            this.service.Users.query({ q: this.model.search, limit: 10 }).then((list) => {
                const fields = ['name', 'email'];
                this.model.search_results = Utils.filter(this.model.search, list, fields);
                for (const user of this.model.search_results) {
                    for (const f of fields) {
                        if (user[`match_${f}`]) {
                            // Format match name
                            user[`match_${f}`] = user[`match_${f}`].replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                            user[`match_${f}`] = user[`match_${f}`].replace(/\`/g, '');
                        }
                    }
                }
                this.model.loading['searching'] = false;
            }, () => this.model.loading['searching'] = false);
        });
    }

    public back() {
        this.event.emit({ type: 'back' });
    }

    public updateHeader() {
        if (this.content && this.header) {
            const scroll = this.model.vertical ? 'scrollTop' : 'scrollLeft';
            this.header.nativeElement[scroll] = this.content.nativeElement[scroll];
        }
    }

    public updateContent(tries: number = 0) {
        if (this.content && this.header) {
            const scroll = this.model.vertical ? 'scrollTop' : 'scrollLeft';
            this.content.nativeElement[scroll] = this.header.nativeElement[scroll];
            this.interval('normalise', () => this.updateContent(), 200);
        }
    }

    public show() {
        this.model.show = true;
    }

    public hide() {
        this.timeout('hide', () => this.model.show = false);
    }

    public move(e) {
        this.timeout('move', () => {
            if (this.model.move && this.content) {
                const center = {
                    x: e.touches && e.touches.length > 0 ? e.touches[0].clientX || e.clientX : e.clientX,
                    y: e.touches && e.touches.length > 0 ? e.touches[0].clientY || e.clientY : e.clientY
                };
                const content_box = this.content.nativeElement.getBoundingClientRect();
                const percent_w = (center.x - content_box.left) / content_box.width;
                const percent_h = (center.y - content_box.top) / content_box.height;
                const percent = this.model.vertical ? percent_w : percent_h;

                const start_time = this.model.settings.start_hour || 6.5;
                const end_time = (this.model.settings.end_hour || 21) + .5;
                const diff_time = end_time - start_time;
                const block_size = this.model.settings.block_size || 15;
                const hour = Math.ceil((diff_time * percent + start_time) * (60 / block_size)) / (60 / block_size);
                if (this.model.move === 'bottom') {
                    let date = moment(this.date);
                    const end = moment(this.date).hours(Math.floor(hour)).minutes(Math.floor((hour * 60) % 60));
                    if (end.isSameOrBefore(date, 'm')) {
                        date = moment(end).add(-this.duration, 'm');
                        this.date = date.valueOf();
                    } else {
                        const duration = Math.floor(moment.duration(end.diff(date)).asMinutes());
                        this.duration = duration || block_size;
                    }
                } else if (this.model.move === 'top') {
                    const date = moment(this.date).hours(Math.floor(hour)).minutes(Math.floor((hour * 60) % 60));
                    this.date = date.valueOf();
                }
                this.updateBlock();
                this.post();
            }
        }, 10);
    }

    public add(item: IUser) {
        if (item && item !== this.model.user && item.email !== this.model.user.email) {
            let found = false;
            if (!this.users) { this.users = []; }
            for (const user of this.users) {
                if (item.id === user.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.users.push(item);
                this.post();
            }
            this.updateAvailability(item);
        }
        this.groupUsers();
    }

    public changeDate() {
        this.model.show_calendar = false;
        const date = moment(this.date);
        const now = moment();
        this.model.today = now.isSame(date, 'd');
        this.loadAvailability();
        this.updateBlock();
        this.post();
    }

    public changeDay(change: number) {
        const date = moment(this.date).add(change, 'd');
        const now = moment();
        if (now.isSameOrBefore(date, 'd')) {
            this.date = date.valueOf();
            this.changeDate();
        }
    }

    public post() {
        this.usersChange.emit(this.users);
        this.dateChange.emit(this.date);
        this.durationChange.emit(this.duration);
    }

    public updateBlock() {
        const block_size = this.model.settings.block_size || 15;
        const date = moment(this.date);
        date.minutes(Math.round(date.minutes() / block_size) * block_size);
        this.model.date = date.format('DD MMMM YYYY');
        const end = moment(date).add(this.duration || 15, 'm');
        const start_time = this.model.settings.start_hour || 6.5;
        const end_time = (this.model.settings.end_hour || 21) + .5;
        const diff_time = end_time - start_time;
        const date_time = date.hours() + date.minutes() / 60;
        this.model.block = {
            start: (date_time - start_time) / diff_time,
            length: ((this.duration || 60) / 60) / diff_time,
            start_time: date.format('hh:mm A'),
            end_time: end.format('hh:mm A')
        };
    }

    public updateTime() {
        const now = moment();
        const date = moment(this.date);
        const start_time = this.model.settings.start_hour || 6.5;
        const end_time = (this.model.settings.end_hour || 21) + .5;
        const now_time = now.hours() + now.minutes() / 60;
        this.model.today = now.isSame(date, 'd');
        this.model.time = (now_time - start_time) / (end_time - start_time);
    }

    public setTime(block, move: boolean = false) {
        if (move) {
            this.model.move = 'bottom';
        }
        if (block && block.date) {
            this.date = block.date;
            this.updateBlock();
            this.post();
        }
    }

    public checkSize() {
        const w = document.body.clientWidth;
        const h = document.body.clientHeight;
        this.model.vertical = w > 480 && h > 480;
    }
}
