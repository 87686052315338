import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { AppService } from '../../services/app.service';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-control',
    templateUrl: './control.template.html',
    styleUrls: ['./control.styles.scss'],
})
export class ControlComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.model.settings = {};
        // Clean banner when navigation change
        this.service.set('BANNER.block_height', 0);
        this.init();
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            this.model.items = this.service.Rooms.list();
            this.filter();
        });
        this.subs.obs.rooms = this.service.Rooms.listen('list', (bld) => {
            this.model.items = this.service.Rooms.list();
            this.filter();
        });
    }

    public init() {
        this.model.loading = true;
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.service.Rooms.listen('room_list', () => {
            this.model.loading = false;
            this.model.items = this.service.Rooms.list(true);
            this.filter();
        });
        this.model.items = this.service.Rooms.list(true);
        this.model.route = this.service.Settings.get('app.style.popout');
        this.model.settings = this.service.Settings.get('app.control') || {};
        if (this.model.settings.banner && this.model.settings.banner.search) {
            this.service.set('BANNER.block_height', 3.5);
        }
        this.service.Analytics.screen('Control');
        this.service.Analytics.page('/control');
    }

    public filter() {
        this.model.filtered_items = [];
        if (this.model.search) {
            const search = (this.model.search || '').toLowerCase();
            for (const item of this.model.items) {
                const name = item.name ? item.name.toLowerCase() : '';
                const level = item.level && item.level.name ? item.level.name.toLowerCase() : '';
                if (
                    (name.indexOf(search) >= 0 || level.indexOf(search) >= 0) &&
                    item.controllable !== false &&
                    item.support_url
                ) {
                    this.model.filtered_items.push(item);
                }
            }
        } else {
            this.model.filtered_items = (this.model.items || []).filter(
                (i) => i.controllable !== false && i.support_url
            );
        }
    }

    public close() {
        this.model.search = '';
        this.filter();
    }
}
