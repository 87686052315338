
import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { IUser } from '../../../services/data/users.service';

export class FormValidators {

    public static date(control: AbstractControl) {
        const now = moment();
        const date = moment(control.value || undefined);
        if (date.isBefore(now, 'd')) {
            return { date: false, message: 'Booking needs to be made in the future' };
        }
        return null;
    }

    public static startTime(d: number) {
        return (control: AbstractControl) => {
            const now = moment();
            const parts = control.value.split(':');
            const date = moment(d).hours(+parts[0]).minutes(+parts[1]);
            if (date.isBefore(now, 'm')) {
                return { start: false, message: 'Booking time needs to be made in the future' };
            }
            return null;
        };
    }

    public static accept(name: string = 'terms & conditions') {
        return (control: AbstractControl) => {
            return control.value === true ? null : { accept: true, message: `You must accept the ${name}` };
        };
    }

    public static duration(len: number, min: number = 15, max: number = 480) {
        return (control: AbstractControl) => {
            if (control.value < (min || 30)) {
                return { duration: true, message: `Minimum booking duration is ${min} minute${min === 1 ? 's' : ''}` };
            } else if (control.value > (max || 480)) {
                return { length: true, message: `Maximum booking duration is ${max} minute${max === 1 ? 's' : ''}` };
            }
            return null;
        };
    }

    public static attendees(user: IUser, min: number = 0, capacity: number = 0) {
        return (control: AbstractControl) => {
            if (!control || (!(control.value instanceof Array) && !control.dirty)) {
                return { invalid: true };
            }
            if (capacity > 0) {
                const peeps = control.value;
                const warn = capacity && capacity <= peeps.length;
                if (warn) {
                    const error = {
                        count: true,
                        message: 'There are more attendess than the capacity of the space',
                    };
                    return error;
                }
            }
            const message = {
                count: true,
                message: `Minimum of ${min} attendee${min === 1 ? ' is' : 's are'} required other than you`
            };
            if ((!(control.value instanceof Array) && control.dirty)) {
                return message;
            }
            const list: IUser[] = control.value;
            if (user) {
                const result = list.find(a => a.email === user.email);
                if (result) { list.splice(list.indexOf(result), 1); }
            }
            return list.length >= min ? null : message;
        };
    }

    constructor() {
        throw new Error('This is a static class');
    }
}
