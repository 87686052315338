
import { Routes } from '@angular/router';
import { AppShellComponent } from './shell/shell.component';
import { HomeComponent } from './shell/home/home.component';
import { HelpComponent } from './shell/help/help.component';
import { ControlComponent } from './shell/control/control.component';
import { ChatComponent } from './shell/chat/chat.component';
import { WifiComponent } from './shell/wifi/wifi.component';
import { ProfileComponent } from './shell/profile/profile.component';
import { TermsComponent } from './shell/terms/terms.component';
import { VisitorComponent } from './shell/visitor/visitor.component';

export const ROUTES: Routes = [
    { path: '', component: AppShellComponent, children: [
        { path: 'home', component: HomeComponent },
        { path: 'book-old', loadChildren: () => import('./shell/booking/booking.module').then(m => m.AppBookingModule) },
        { path: 'book', loadChildren: () => import('./shell/book/book.module').then(m => m.AppBookModule) },
        { path: 'explore', loadChildren: () => import('./shell/explore/explore.module').then(m => m.AppExploreModule) },
        { path: 'schedule', loadChildren: () => import('./shell/schedule/schedule.module').then(m => m.AppScheduleModule) },
        { path: 'help', component: HelpComponent },
        { path: 'chat', component: ChatComponent },
        {
            path: 'visitor', children: [
                { path: '', component: VisitorComponent },
                { path: ':page', component: VisitorComponent }
            ]
        },
        { path: 'wifi', component: WifiComponent },
        { path: 'catering', loadChildren: () => import('./shell/catering/catering.module').then(m => m.AppCateringModule) },
        { path: 'lockers', loadChildren: () => import('./shell/lockers/lockers.module').then(m => m.AppLockersModule) },
        { path: 'directory', loadChildren: () => import('./shell/directory/directory.module').then(m => m.AppDirectoryModule) },
        { path: 'support', component: HelpComponent },
        { path: 'profile', component: ProfileComponent },
        { path: 'control', component: ControlComponent },
        { path: 'terms', component: TermsComponent },
        { path: '**',      redirectTo: 'home' },
    ] },
    { path: '**',      redirectTo: 'home' },
];
