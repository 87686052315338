
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';
import { AppService } from '../../../../services/app.service';
import { IExtra } from '../../../../services/data/buildings.service';

@Component({
    selector: 'custom-preference-field',
    templateUrl: './preference-field.component.html',
    styleUrls: ['./preference-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('200ms ease-in')),
        ])
    ]
})
export class CustomPreferenceFieldComponent extends BaseComponent implements ICustomField<IExtra[]>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<IExtra[]>;

    public extras: IExtra[] = [];
    public show: boolean;

    constructor(private service: AppService) {
        super();
    }

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => this.updateDisplay());
            }

            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.init();
        }
    }

    public init() {
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            if (bld && bld.loan_items) {
                const extras = [ ...bld.loan_items ];
                const old_extras = this.control.value || [];
                for (const extra of old_extras) {
                    const item = extras.find(i => i.id === extra.id);
                    if (item) { item.active = !!extra.active; }
                }
                this.timeout('set', () => {
                    this.setValue(extras);
                    this.extras = extras;
                }, 100);
            }
        });
        this.setValue([]);
        this.updateDisplay();
    }

    public updateDisplay() {
        this.extras = this.control.value || [];
    }

    public setValue(value: IExtra[]) {
        this.control.setValue(value);
    }
}
