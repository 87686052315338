<ng-container *ngIf="form">
    <div class="pay-type-field" [formGroup]="form">
        <button
            class="type"
            [class.active]="use_card"
            [disabled]="form.get('room')?.value?.rate === 0"
            (click)="setValue(true)"
            click-responder
        >
            <div class="icon">
                <i class="towers-pay-card"></i>
            </div>
            <div class="text">Pay by Card</div>
        </button>
        <div class="divider">OR</div>
        <button class="type" [class.active]="!use_card" (click)="setValue(false)" click-responder>
            <div class="icon">
                <i class="towers-charge-back"></i>
            </div>
            <div class="text">Charge Back</div>
        </button>
    </div>
</ng-container>
