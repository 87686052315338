import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';
import { Utils } from '../../../utility.class';

import * as moment from 'moment';

@Component({
    selector: 'custom-duration-field',
    templateUrl: './duration-field.component.html',
    styleUrls: ['./duration-field.component.scss']
})
export class CustomDurationFieldComponent extends BaseComponent implements ICustomField<number>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<number>;

    public model: any = {};

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => this.updateDisplay());
            }
            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.updateDisplay();
        }
    }

    public updateDisplay() {
        this.timeout('update', () => {
            const value = this.ref_control ? this.ref_control.value || '' : '';
            const date = (value ? moment(value) : moment());
            const times = this.generateBlocks(date.valueOf(), !!value);
            this.model.list_height = Math.min(times.values.length, 8);
            this.model.blocks = times.values;
            if (!this.model.index) { this.model.index = times.index; }
            if (!this.control.value) { this.setValue(30); }
        });
    }

    public generateBlocks(datestamp: number, ref: boolean = false) {
        const duration = {
            values: [],
            index: 0
        };
        const date = moment(datestamp);
        date.minutes(Math.round(date.minutes() / 5) * 5);
        const end = moment(datestamp).add(240, 'm');
        date.add(30, 'm');
        let dur = 30;
        for (; date.isBefore(end, 'm'); date.add(15, 'm')) {
            const value = (s?) => Utils.humaniseDuration(dur, s);
            duration.values.push({
                id: dur,
                name: ref ? `${date.format('hh:mm A')} (${value(true)})` : value()
            });
            dur += 15;
        }
        return duration;
    }

    public getIndex(value: number, values: { id: number, name: string }[], name?: string) {
        const exists = values.find(a => a.id === value);
        const index = values.indexOf(exists);
        return index < 0 ? 0 : index;
    }

    public setValue(value: number) {
        this.control.setValue(value);
        this.model.show = false;
        this.model.index = this.getIndex(value, this.model.blocks);
    }
}
