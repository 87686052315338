<div class="container">
    <div class="header">
        <div class="text">Select {{ model.simple ? 'time' : 'Booking Period' }}</div>
        <div class="close" (tap)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body" *ngIf="model.form">
        <div class="field" [class.focus]="model.focus.start">
            <div class="label">{{ model.simple ? 'Time' : 'Start time'}}:</div>
            <div class="value">
                <input type="time" [(ngModel)]="model.form.start" (ngModelChange)="adjustEnd()" (focus)="model.focus.start = true" (blur)="model.focus.start = false;">
            </div>
        </div>
        <div class="field" *ngIf="!model.simple" [class.focus]="model.focus.end">
            <div class="label">End time:</div>
            <div class="value">
                <input type="time" [(ngModel)]="model.form.end" (ngModelChange)="adjustDuration()" (focus)="model.focus.end = true" (blur)="model.focus.end = false;">
            </div>
        </div>
        <div class="field" *ngIf="!model.simple">
            <div class="label">Duration:</div>
            <div class="value">{{model.display.duration}}</div>
        </div>
    </div>
    <div class="footer">
        <btn name="full-width normal" (tapped)="event('Accept')">Ok</btn>
    </div>
</div>