import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';
import { AppService } from '../../../../services/app.service';

interface IWeekday {
    id: number;
    value: number;
    month: string;
    day: string;
    past: boolean;
}

@Component({
    selector: 'custom-terms-field',
    templateUrl: './terms-field.component.html',
    styleUrls: ['./terms-field.component.scss']
})
export class CustomTermsFieldComponent extends BaseComponent implements ICustomField<string>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<string>;

    constructor(private service: AppService) {
        super();
    }

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            this.init();
        }
    }

    public init() {

    }

    public viewTerms() {
        this.service.viewTerms();
    }

    public setValue(value: number) {
        this.control.setValue(value);
    }
}
