import { OverlayContentComponent, MapPinComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'view-room-modal',
    templateUrl: './view-room-modal.template.html',
    styleUrls: ['./view-room-modal.styles.scss'],
})
export class ViewRoomModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        if (!this.model.room) return this.fn.close();
        this.model.lock = true;
        if (!this.model.date) this.model.date = 'Today';
        this.model.colours = {
            rooms: this.service.Settings.get('app.colors.rooms') || {},
        };
        this.model.pins = [
            {
                id: `area-${this.model.room.map_id}-status`,
                prefix: 'pin',
                content: MapPinComponent,
                data: {
                    back: this.model.colours.rooms.pin || '#03A9F4',
                    text: this.model.room.name,
                },
            },
        ];
        this.model.style = { Zones: { display: 'none' } };
        const bld = this.service.Buildings.buildingFromZones(this.model.room.zones);
        if (!bld) return this.fn.close();
        for (const lvl of bld.levels) {
            if (this.model.room.zones.includes(lvl.id)) {
                this.model.level = lvl;
                break;
            }
        }
    }
}
