import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../base.component';
import { AppService } from '../../../services/app.service';

@Component({
    selector: 'terms-display',
    templateUrl: './terms-display.template.html',
    styleUrls: ['./terms-display.styles.scss']
})
export class TermsDisplayComponent extends BaseComponent implements OnInit {

    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.logo = this.service.Settings.get('app.logo');
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            if (bld) {
                this.model.terms = bld.terms;
            }
        });
    }
}
