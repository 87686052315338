<div class="cntnr" [style.height]="model.vh + 'px'">
    <div class="header">
        {{model.edit ? 'Edit Meeting' : 'View Meeting' }}
    </div>
    <form #booking_details *ngIf="!model.loading">
        <div class="body">
            <div class="details" *ngIf="model.booking && !model.edit">
                <div class="field">
                    <div class="label">Title:</div>
                    <div class="value">{{model.booking.title || 'No title'}}</div>
                </div>
                <div class="field">
                    <div class="label">Space:</div>
                    <div class="value">{{model.booking.room ? model.booking.room.name || 'No selected space' : 'No selected space'}}</div>
                    <div class="option" *ngIf="model.booking.room && model.booking.room.map_id"><btn (tapped)="locate()">Map</btn></div>
                </div>
                <div class="field" *ngIf="model.booking.description">
                    <div class="label">Description:</div>
                    <div class="value">{{model.booking.description || 'No description'}}</div>
                </div>
                <div class="field organiser">
                    <div class="label">Organiser:</div>
                    <div class="value">
                        <div class="list variable">
                            <div class="item">
                                <div class="details">
                                    <div class="grp">
                                        <div class="name">{{model.booking.organiser ? model.booking.organiser.name : 'Not you'}}</div>
                                        <div class="minor">{{model.booking.organiser && model.booking.organiser.organisation ? model.booking.organiser.organisation.name
                                            : ''}}</div>
                                    </div>
                                    <div class="minor" *ngIf="model.booking.organiser && model.booking.organiser.email">
                                        {{model.booking.organiser?.email}}
                                    </div>
                                </div>
                                <div class="dot" *ngIf="model.user && model.organiser === model.user.email"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field top">
                    <div class="label">Attendees:</div>
                    <div class="value">
                        <div class="list variable">
                            <ng-container *ngIf="model.booking.attendees">
                                <ng-container  *ngFor="let item of model.booking.attendees">
                                    <div class="item" *ngIf="model.organiser !== item.email">
                                        <div class="details">
                                            <div class="grp">
                                                <div class="name">{{item.name}}</div>
                                                <div class="minor">{{item.organisation?.name}}</div>
                                            </div>
                                            <div class="minor" *ngIf="item.email">{{item.email}}</div>
                                        </div>
                                        <div class="dot" *ngIf="model.user && model.user.email === item.email"></div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <span *ngIf="!model.booking.attendees || model.booking.attendees.length <= 1">No Attendees</span>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div class="label">Date:</div>
                    <div class="value">{{model.booking.display?.date}}, {{model.booking.display?.time}}</div>
                </div>
                <div class="field" *ngIf="model.booking.cost">
                    <div class="label">Cost:</div>
                    <div class="value">{{(model.booking.cost || 0) | currency}}</div>
                </div>
            </div>
            <div class="form" *ngIf="!model.booking || model.edit">
                <booking-form #bookingForm [(form)]="model.form" [edit]="true" [(error)]="model.error" [settings]="{ edit: true, recurrence: false }" [(valid)]="model.valid"
                    (event)="event($event)" (formChange)="formChange($event)"></booking-form>
            </div>
        </div>
        <div class="footer">
            <btn name="modal inverse" (tapped)="fn.close()">Close</btn>
            <btn name="modal inverse" *ngIf="model.booking && model.booking.room && model.booking.room.support_url && !model.edit && (!model.settings || model.settings.control !== false)"
                [disabled]="!model.soon" (tapped)="control()">
                Control
            </btn>
            <btn name="modal inverse" *ngIf="model.booking && !model.edit && !model.is_past" (tapped)="edit()">Edit</btn>
            <btn name="modal error" *ngIf="model.booking && model.can_cancel" (tapped)="delete()">
                {{model.booking && model.booking.is_organiser ? 'Delete' : 'Decline'}}
            </btn>
            <btn name="modal" *ngIf="model.booking && model.edit" [disabled]="!model.form || !model.form.room || !model.form.date || !model.valid"
                (tapped)="!model.is_booking ? update() : ''">
                <div class="text" *ngIf="!model.is_booking">Save</div>
                <div class="icon" *ngIf="model.is_booking">
                    <spinner type="circle-ring" color="rgba(0,0,0, .87)"></spinner>
                </div>
            </btn>
        </div>
    </form>
    <div class="no-item" center *ngIf="model.loading">
        <div class="icon">
            <i class="material-icons" *ngIf="!model.processing">done</i>
            <spinner type="circle-ring" color="rgba(0,0,0, .2)" *ngIf="model.processing"></spinner>
        </div>
        <div class="text">
            <ng-container *ngIf="model.processing">Processing request...</ng-container>
            <ng-container *ngIf="!model.processing && model.edit">Updated meeting</ng-container>
            <ng-container *ngIf="!model.processing && model.deleting">
                Successfully {{model.booking && model.booking.is_organiser ? 'declined' : 'deleted'}} meeting
            </ng-container>
            <ng-container *ngIf="!model.processing && !model.edit && !model.deleting">Successfully created meeting</ng-container>
        </div>
    </div>
    <div class="close" *ngIf="!model.processing || !model.loading" (touchrelease)="fn.close()">
        <i class="material-icons">close</i>
    </div>
</div>
