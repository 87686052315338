<div class="container">
    <div class="header">
        {{model.edit ? 'Edit Visitor' : (model.visitor ? model.visitor.name + ' details' : 'New Visitor') }} {{!model.visitor &&
        model.group ? 'Group' : ''}}
    </div>
    <form #visitor_details *ngIf="!model.loading">
        <div class="body">
            <div class="details" *ngIf="model.visitor">
                <div class="field">
                    <div class="label">Name:</div>
                    <div class="value">{{model.visitor.name || model.visitor.visitors[0]?.name}}</div>
                </div>
                <div class="field top" *ngIf="model.visitor.group">
                    <div class="label">Visitors:</div>
                    <div class="value">
                        <div class="list variable">
                            <ng-container *ngIf="model.visitor.visitors">
                                <div class="item" *ngFor="let item of model.visitor.visitors">
                                    <div class="details">
                                        <div class="grp">
                                            <div class="name">{{item.name}}</div>
                                            <div class="minor">{{item.organisation?.name || item.organisation}}</div>
                                        </div>
                                        <div class="minor" *ngIf="item.email">{{item.email}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="fields" *ngIf="!model.visitor.group && model.visitor.visitors">
                    <div class="field" *ngIf="model.visitor.visitors[0].organisation">
                        <div class="label">Organisation:</div>
                        <div class="value">{{model.visitor.visitors[0].organisation?.name || model.visitor.visitors[0].organisation}}</div>
                    </div>
                    <div class="field">
                        <div class="label">Email:</div>
                        <div class="value">{{model.visitor.visitors[0].email}}</div>
                    </div>
                    <div class="field" *ngIf="model.visitor.visitors[0].phone || model.visitor.visitors[0].mobile">
                        <div class="label">Mobile:</div>
                        <div class="value">{{model.visitor.visitors[0].phone || model.visitor.visitors[0].mobile}}</div>
                    </div>
                </div>
                <div class="field">
                    <div class="label">Arrival:</div>
                    <div class="value">{{model.visitor.arrival}}</div>
                </div>
            </div>
            <div class="form" *ngIf="!model.visitor || model.edit">
                <div class="group" *ngIf="!model.edit">
                    <radioset [list]="['Individual', 'Group']" [(model)]="model.group" [inline]="true"></radioset>
                </div>
                <div class="field" *ngIf="model.group && !model.edit">
                    <div class="label">Group name:</div>
                    <div class="value">
                        <input name="group_name" [(ngModel)]="model.group_name" placeholder="Group name">
                    </div>
                </div>
                <user-form *ngIf="!model.visitor" [(form)]="model.form" [visitors]="model.contacts" [orgs]="model.orgs" [clear]="model.clear" [edit]="model.edit && (!model.visitor || !model.visitor.group)" [select]="!!model.group" (event)="onUserSelect($event)"></user-form>
                <ng-container *ngIf="!!model.group && !model.edit">
                    <div class="options">
                        <btn name="inverse" [disabled]="(!model.form.users || model.form.users.length <= 0) && !model.form.email" (tapped)="addToGroup()">
                            Add user
                        </btn>
                    </div>
                    <div class="heading">
                        Visitor Group ({{model.visitor_list && model.visitor_list.length > 0 ? model.visitor_list.length : 'No people'}})
                    </div>
                    <div class="list">
                        <ng-container *ngIf="model.visitor_list">
                            <div class="item" *ngFor="let item of model.visitor_list">
                                <div class="details">
                                    <div class="grp">
                                        <div class="name">{{item.name}}</div>
                                        <div class="minor">{{item.org}}</div>
                                    </div>
                                    <div class="minor" *ngIf="item.phone">{{item.phone}}</div>
                                    <div class="minor" *ngIf="item.email">{{item.email}}</div>
                                </div>
                                <div class="btn" (touchrelease)="removeFromGroup(item)">
                                    <i class="material-icons">close</i>
                                </div>
                            </div>
                        </ng-container>
                        <div class="no-item" center *ngIf="!model.visitor_list || model.visitor_list.length <= 0">
                            <div class="icon">
                                <i class="material-icons">people</i>
                            </div>
                            <div class="text">No visitors in group</div>
                        </div>
                    </div>
                </ng-container>
                <div class="fieldset">
                    <div class="field">
                        <div class="label">Arrival Date:</div>
                        <div class="value ul" (touchrelease)="selectDate()">{{model.display?.date}}</div>
                    </div>
                    <div class="field">
                        <div class="label">Visit Period:</div>
                        <div class="value ul" (touchrelease)="selectPeriod()">{{model.display?.time}}</div>
                    </div>
                    <div class="field">
                        <div class="label">Location<span *ngIf="!model.edit">*</span>:</div>
                        <div class="value">
                            <input name="location" [readonly]="model.edit" type="text" [(ngModel)]="model.location" placeholder="Meeting location">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <btn name="inverse" *ngIf="model.visitor && !model.edit && model.editable" (tapped)="edit()">Edit</btn>
            <btn name="error" *ngIf="model.visitor && !model.edit && model.editable" (tapped)="delete()">Delete</btn>
            <btn name="" *ngIf="model.visitor && model.edit" [disabled]="!model.form || !model.location || (!model.form.email && (!model.visitor_list || model.visitor_list.length <= 0))"
                (tapped)="update()">
                Save
            </btn>
            <btn name="inverse" *ngIf="!model.visitor && !model.edit && model.csv">
                Upload CSV <input type="file" #file_input (change)="upload($event)">
            </btn>
            <btn name="" *ngIf="!model.visitor && !model.edit" [disabled]="(!model.form || !model.location  || (!model.form.email && (!model.form.users || model.form.users.length <= 0))) && (!model.visitor_list || model.visitor_list.length <= 0)"
                (tapped)="add(false)">
                Add
            </btn>
            <btn name="" *ngIf="!model.visitor && !model.edit" [disabled]="(!model.form || !model.location  || (!model.form.email && (!model.form.users || model.form.users.length <= 0))) && (!model.visitor_list || model.visitor_list.length <= 0)"
                (tapped)="add()">
                Add &amp; Finish
            </btn>
        </div>
    </form>
    <div class="no-item" center *ngIf="model.loading">
        <div class="icon">
            <i class="material-icons success" *ngIf="!model.processing">done</i>
            <spinner type="circle-ring" color="rgba(0,0,0, .87)" *ngIf="model.processing"></spinner>
        </div>
        <div class="text">
            {{model.processing ? 'Processing request...' : (model.deleting ? 'Successfully deleted visitor' : (model.edit ? 'Updated visitor' : 'Successfully added visitor'))}}
        </div>
    </div>
</div>
