import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HotkeyService {
    private listeners: { [name: string]: Subject<any> } = {};
    private observers: { [name: string]: Observable<any> } = {};
    private timers: { [name: string]: number } = {};
    private key_queue: string[] = [];
    private ignore_combinations: string[] = ['control', 'shift', 'alt', 'meta', 'os'];
    private replace_code: { [code: string]: string } = {
        controlleft: 'control',
        controlright: 'control',
        shiftleft: 'shift',
        shiftright: 'shift',
        altleft: 'alt',
        altright: 'alt'
    };

    constructor() {
        this.listeners.keydown = new Subject();
        this.observers.keydown = this.listeners.keydown.asObservable();
        window.addEventListener('keydown', (event: KeyboardEvent) => {
            const code = (event.code || '').toLowerCase();
            if (code && this.key_queue.indexOf(code) < 0) {
                const key = this.replace_code[code] || code;
                this.key_queue.push(key);
                this.listeners.keydown.next(event);
                this.clearQueue();
            }
        });
        this.listeners.keyup = new Subject();
        this.observers.keyup = this.listeners.keyup.asObservable();
        window.addEventListener('keyup', (event: KeyboardEvent) => {
            const code = (event.code || '').toLowerCase();
            if (code && this.key_queue.indexOf(code) < 0) {
                const key = this.replace_code[code] || code;
                this.postCombination();
                this.key_queue.splice(this.key_queue.indexOf(key), 1);
            }
            this.listeners.keyup.next(event);
        });
    }

    public listen(combination: string[] | string, next: () => void) {
        let combo = combination instanceof Array ? combination.join('+').toLowerCase() : (combination || '').toLowerCase();
        combo = combo.replace(/ /g, 'space');
        if (combo && !this.listeners[combo]) {
            this.listeners[combo] = new Subject();
            this.observers[combo] = this.listeners[combo].asObservable();
        }
        return combo && this.observers[combo] ? this.observers[combo].subscribe(next) : null;
    }

    private postCombination() {
        if (this.key_queue.length <= 1 && document.activeElement && document.activeElement.tagName !== 'BODY') { return; }
        let combo = this.key_queue.join('+');
        combo = combo.replace(/ /g, 'space');
        if (this.listeners[combo] && this.ignore_combinations.indexOf(combo) < 0) {
            this.listeners[combo].next();
        }
    }

    private clearQueue() {
        if (this.timers.clear) {
            clearTimeout(this.timers.clear);
        }
        this.timers.clear = <any>setTimeout(() => {
            this.key_queue = [];
            this.timers.clear = null;
        }, 1000);
    }
}
