
import { Component } from '@angular/core';

@Component({
    selector: 'register-form',
    templateUrl: './register-form.template.html',
    styleUrls: ['./register-form.styles.scss']
})
export class RegisterFormComponent {

}

