
<div class="container" [class.modal]="modal" [class.route]="!modal && model.route">
    <div class="header" *ngIf="model.state !== 'select'">
        <div class="text">Participants</div>
    </div>
    <searchbar [(filter)]="model.search" *ngIf="model.state === 'select'"></searchbar>
    <div class="body">
        <div class="page" *ngIf="model.state !== 'select'">
            <user-search-display [(users)]="model.users"></user-search-display>
        </div>
        <div class="page" *ngIf="model.state === 'select' && !model.hide">
            <user-search-list [modal]="modal" [(users)]="model.users" [(search)]="model.search"></user-search-list>
        </div>
    </div>
    <div class="footer">
        <div class="buttons" *ngIf="model.state !== 'select'">
            <btn [name]="modal ? 'full-width' : ''" (tapped)="setState('select')">Add People</btn>
            <btn [name]="modal ? 'full-width normal' : ''" (tapped)="confirm()">Finish</btn>
        </div>
        <div class="buttons" *ngIf="model.state === 'select'">
            <btn [name]="modal ? 'modal inverse' : ''" (tapped)="closeSearch()">Cancel</btn>
            <btn [name]="modal ? 'modal' : ''" (tapped)="setState('display')">Confirm</btn>
        </div>
    </div>
</div>
