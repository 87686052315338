/*
 * @Author: alex.sorafumo
 * @Date:   2017-04-03 15:50:46
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:27:00
 */

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import { BaseMockBackend } from './base.mock';
import { Utils } from '../../utility.class';

export class MockBuildingsBackend extends BaseMockBackend {

    constructor(protected model) {
        super(model);
    }

    protected load() {
        MOCK_REQ_HANDLER.unregister(`/control/api/zones`);
        this.loadBuildings();
    }

    private loadBuildings() {
        if (this.model.log) { this.model.log('MOCK', 'Loading request for building list'); }
        // Add response for building zones
        this.model.bld_data = {
            'zone_bld-01': {
                id: 'zone_bld-01',
                name: 'Barangaroo Tower 2',
                settings: {
                    discovery_info: {
                        timezone: 'Australia/Sydney',
                        phone: {
                            emergency: '0412345678',
                            av_help: '0412345678',
                            concierge: '0412345678'
                        },
                        locations: { },
                        desk_tracking: 'sys-B0',
                        messaging: 'sys-B0',
                        features: [],
                        catering: {},
                        neighbourhoods: {
                            'zone_lvl-01': {
                                'Test Searchable': 'Device_Bench'
                            }
                        },
                        visitor_space: 'sydney-10.05@acaprojects.com',
                        locker_structure: {
                            'zone_lvl-31': {
                                West: {
                                    pantry: [
                                        ['31001', '31002', '31003', '31004'],
                                        ['31005', '31006', '31007', '31008'],
                                        [false, false, '31009', '31010'],
                                        ['31011', '31012', '31013', '31014'],
                                        ['31015', '31016', '31017', '31018'],
                                        ['31019', '31020', '31021', '31022'],
                                        ['31023', '31024', '31025', '31026'],
                                        ['31027', '31028', '31029', '31030'],
                                        ['31031', '31032', '31033', '31034'],
                                        ['31035', '31036', '31037', '31038'],
                                        ['31039', '31040', '31041', '31042'],
                                        ['31043', false, false, '31046'],
                                        ['31047', '31048', '31049', '31050'],
                                        ['31051', '31052', '31053', '31054'],
                                        ['31055', '31056', '31057', '31058'],
                                        ['31059', '31060', '31061', '31062'],
                                        ['31063', '31064', false, '31066'],
                                        ['31067', '31068', '31069', '31070'],
                                        ['31071', '31072', '31073', '31074'],
                                        ['31075', '31076', '31077', '31078'],
                                        ['31079', '31080', '31081', '31082'],
                                        ['31083', '31084', '31085', '31086'],
                                        [false, false, '31087', '31088'],
                                        ['31089', '31090', '31091', '31092'],
                                        ['31093', '31094', '31095', '31096']
                                    ],
                                    personal: [
                                        ['31001', '31002', '31003', '31004'],
                                        ['31005', '31006', '31007', '31008'],
                                        [false, false, '31009', '31010'],
                                        ['31011', '31012', '31013', '31014'],
                                        ['31015', '31016', '31017', '31018'],
                                        ['31019', '31020', '31021', '31022'],
                                        ['31023', '31024', '31025', '31026'],
                                        ['31027', '31028', '31029', '31030'],
                                        ['31031', '31032', '31033', '31034'],
                                        ['31035', '31036', '31037', '31038'],
                                        ['31039', '31040', '31041', '31042'],
                                        ['31043', '31044', '31045', '31046'],
                                        ['31047', '31048', '31049', '31050'],
                                        ['31051', '31052', '31053', '31054'],
                                        ['31055', '31056', '31057', '31058'],
                                        ['31059', '31060', '31061', '31062'],
                                        ['31063', '31064', '31065', '31066'],
                                        ['31067', '31068', '31069', '31070'],
                                        ['31071', '31072', '31073', '31074'],
                                        ['31075', '31076', '31077', '31078'],
                                        ['31079', '31080', '31081', '31082'],
                                        ['31083', '31084', '31085', '31086'],
                                        [false, false, '31087', '31088'],
                                        ['31089', '31090', '31091', '31092'],
                                        ['31093', '31094', '31095', '31096']
                                    ],
                                    storage: [
                                        ['31001', '31002', '31003', '31004'],
                                        ['31005', '31006', '31007', '31008'],
                                        [false, false, '31009', '31010'],
                                        ['31011', '31012', '31013', '31014'],
                                        ['31015', '31016', '31017', '31018'],
                                        ['31019', false, false, false],
                                        ['31023', '31024', '31025', '31026'],
                                        ['31027', '31028', '31029', '31030'],
                                        ['31031', '31032', '31033', '31034'],
                                        ['31035', '31036', '31037', '31038'],
                                        ['31039', '31040', '31041', '31042'],
                                        ['31043', '31044', '31045', '31046'],
                                        ['31047', '31048', '31049', '31050'],
                                        ['31051', '31052', '31053', '31054'],
                                        ['31055', '31056', '31057', '31058'],
                                        ['31059', '31060', '31061', '31062'],
                                        ['31063', '31064', '31065', '31066'],
                                        ['31067', '31068', '31069', '31070'],
                                        ['31071', '31072', '31073', '31074'],
                                        ['31075', '31076', '31077', '31078'],
                                        ['31079', '31080', '31081', '31082'],
                                        ['31083', '31084', '31085', '31086'],
                                        [false, false, '31087', '31088'],
                                        ['31089', '31090', '31091', '31092'],
                                        ['31093', '31094', '31095', '31096']
                                    ]
                                }
                            }
                        },
                        levels: [
                            {
                                level_id: 'zone_lvl-30',
                                level_name: 'Level 30',
                                number: 1,
                                map_url: 'assets/maps/barangaroo_tower_2/level_30.svg',
                                floor_type: 'staff',
                                settings: {
                                    map_features: [
                                        { id: 'Device_Bench', name: 'Device Bench' },
                                        { id: 'people', name: 'People' }
                                    ]
                                },
                                desks: 6,
                            },
                            {
                                level_id: 'zone_lvl-31',
                                level_name: 'Level 31',
                                number: 2,
                                map_url: 'assets/maps/barangaroo_tower_2/level_31.svg',
                                floor_type: 'visitor',
                                desks: 6,
                            },
                            {
                                level_id: 'zone_lvl-38',
                                level_name: 'Level 38',
                                number: 3,
                                map_url: 'assets/maps/barangaroo_tower_2/level_38.svg',
                                floor_type: 'staff',
                                desks: 6,
                            },
                            {
                                level_id: 'zone_lvl-40',
                                level_name: 'Level 40',
                                number: 10,
                                map_url: 'assets/maps/barangaroo_tower_2/level_40.svg',
                                floor_type: 'staff',
                                desks: 186,
                            }
                        ],
                        roles: {
                            'fire-warden': [
                                { name: 'Alex Sorafumo', email: `alex.sorafumo@${this.model.domain}`, phone: '0412345678' },
                                { name: 'Bob Jane', email: `bob.jane@${this.model.domain}`, phone: '0423456789' },
                                { name: 'Rupert', email: `rupert@${this.model.domain}`, phone: '0434567890' }
                            ],
                            'first-aider': [
                                { name: 'Ben Hoad', email: `ben.hoad@${this.model.domain}`, phone: '0412345678' },
                                { name: 'Kim Burgess', email: `kim.burgess@${this.model.domain}`, phone: '0423456789' },
                                { name: 'Mr Pop', email: `mr.pop@${this.model.domain}`, phone: '0434567890' }
                            ]
                        },
                        extras: [
                            { extra_id: 'VidConf', extra_name: 'Video Conference' },
                            { extra_id: 'AV', extra_name: 'AV' },
                            { extra_id: 'presentation', extra_name: 'Wireless Presentation' },
                            { extra_id: 'phone', extra_name: 'Conference Phone' },
                        ],
                        loan_items: [
                            { extra_id: 'ConfKit', extra_name: 'Conference Kit' },
                            { extra_id: 'Chairs', extra_name: 'Chairs' },
                        ],
                        terms: [
                            {
                                title: 'Room bookings',
                                details: 'Please release booked meeting rooms or spaces when you don\'t need them anymore, so everyone else knows they\'re free.'
                            },
                            {
                                title: 'Noise levels',
                                details: 'Please be considerate of others and keep the volume down when having conversations or conference calls.'
                            },
                            {
                                title: 'Be considerate',
                                details: 'When you\'ve finished using a space, please ensure it is left clean and tidy for the next person.'
                            },
                            {
                                title: 'Centralised waste management',
                                details: 'We\'re part of one of the most sustainable commercial precincts in the world. Please make an effort to place the right waste into the right bins, located in the Pantries.'
                            },
                            {
                                title: 'Lost and found',
                                details: 'Any personal or team items left behind in activity rooms or communal spaces will be taken to \'Lost and found\' at the concierge points.'
                            },
                            {
                                title: 'Invoice Statement',
                                details: 'For every confirmed booking, you will receive invoice statement via email for your record.'
                            },
                            {
                                title: 'Billing',
                                details: 'Monthly invoice will be issued by 20th of every month for previous month. Invoice is due by end of the month it is issued.'
                            }
                        ],
                        layouts: { },
                    },
                },
                levels: [],
            },
            'zone_bld-02': {
                id: 'zone_bld-02',
                name: 'Melbourne',
                settings: {
                    discovery_info: {
                        levels: [
                            {
                                level_id: 'zone_lvl-04',
                                level_name: 'Level 13',
                                number: 1,
                                map_url: 'assets/maps/level_01.svg',
                                floor_type: 'staff',
                                desks: 6,
                            },
                            {
                                level_id: 'zone_lvl-05',
                                level_name: 'Level 14',
                                number: 2,
                                map_url: 'assets/maps/level_02.svg',
                                floor_type: 'visitor',
                                desks: 6,
                            },
                            {
                                level_id: 'zone_lvl-06',
                                level_name: 'Level 15',
                                number: 3,
                                map_url: 'assets/maps/level_03.svg',
                                floor_type: 'staff',
                                desks: 6,
                            }
                        ],
                    }
                },
                levels: [],
            }
        };
        for (const bld in this.model.bld_data) {
            if (this.model.bld_data.hasOwnProperty(bld)) {
                this.model.bld_data[bld].levels = this.model.bld_data[bld].settings.discovery_info.levels;
                this.generateDesksForBuilding(this.model.bld_data[bld]);
            }
        }
        // Add response for Global zone
        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/zones`, this.model.bld_data, (e) => this.handleZone(e));
        MOCK_REQ_HANDLER.register(`/control/api/zones`, this.model.bld_data, (e) => this.handleZone(e));
        this.state.next(true);
    }

    private handleZone(event) {
        if (event.fragment.type === 'org' || event.fragment.tags === 'org') {
            return [{
                settings: {
                    discovery_info: {
                        buildings: [{
                            name: this.model.city,
                            findme_id: this.model.city,
                            zone_id: 'zone_bld-01',
                            office_id: this.model.city,
                            location_code: 'syd',
                            orientations: { landscape: 0 },
                        }, {
                            name: this.model.city,
                            findme_id: this.model.city,
                            zone_id: 'zone_bld-02',
                            office_id: this.model.city,
                            location_code: 'mel',
                            orientations: { landscape: 0 },
                        }],
                        organisations: [
                            { name: 'ACA', id: '12' }
                        ],
                        attribute_orders: ['Sit-to-Stand Desk', 'Seated Desk', 'Dual Monitor', 'Single Monitor', 'No Monitor'],
                        layouts: {},
                    },
                },
            }];
        } else if (event.fragment.type === 'building' || event.fragment.tags === 'building') {
            const blds: any[] = [];
            for (const k in this.model.bld_data) {
                if (event.data.hasOwnProperty(k)) {
                    blds.push(this.model.bld_data[k]);
                }
            }
            return blds;
        } else if (event.fragment.type === 'level' || event.fragment.tags === 'level') {
            let lvls: any[] = [];
            for (const k in this.model.bld_data) {
                if (event.data.hasOwnProperty(k)) {
                    lvls = lvls.concat(this.model.bld_data[k].levels);
                }
            }
            return lvls;
        }
        return null;
    }

    private generateDesksForBuilding(bld: any) {
        const system = (window as any).control.systems['sys-B0'];
        const dm = system.DeskManagement[0];
        for (const lvl of bld.levels) {
            if (lvl.level_id) {
                if (dm && !dm[`${lvl.level_id}`]) {
                    dm[`${lvl.level_id}`] = [];
                    dm[`${lvl.level_id}:desk_ids`] = [];
                    dm[`${lvl.level_id}:occupied_count`] = 0;
                    dm[`${lvl.level_id}:desk_count`] = lvl.desks;
                    dm[`${lvl.level_id}:free_count`] = lvl.desks;
                    dm[`${lvl.level_id}:reserved`] = [];
                }
                for (let i = 1; i <= lvl.desks; i++) {
                    dm[`${lvl.level_id}:desk_ids`]
                        .push(`table-${Utils.padZero(lvl.number, 1)}.${Utils.padZero(i, 3)}`);
                        if (!this.model.desk_id_pool) { this.model.desk_id_pool = []; }
                        this.model.desk_id_pool.push({
                            id: `table-${Utils.padZero(lvl.number, 1)}.${Utils.padZero(i, 3)}`,
                            level: lvl.level_id
                        });
                }
                setInterval(() => {
                        // Update desk state every 10 seconds
                    const use = Math.floor(Math.random() * lvl.desks);
                    const desk_list: string[] = [];
                    const reserved: string[] = [];
                    dm[`${lvl.level_id}:occupied_count`] = use;
                    dm[`${lvl.level_id}:free_count`] = lvl.desks - use;
                    for (let d = 0; d < use; d++) {
                        const index = Math.floor(Math.random() * lvl.desks);
                        const id = dm[`${lvl.level_id}:desk_ids`][index];
                        if (desk_list.indexOf(id) < 0) {
                            desk_list.push(id);
                            if (Math.floor(Math.random() * 29716319) % 3 === 0) { reserved.push(id); }
                        } else {
                            d--;
                        }
                    }
                    desk_list.sort();
                    dm[`${lvl.level_id}`] = desk_list;
                    dm[`${lvl.level_id}:reserved`] = reserved;
                }, 30 * 1000);
            }
        }
    }
}
