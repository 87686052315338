
<div class="container" *ngIf="model.menu && model.show && model.menu.footer">
    <div [class]="'menu ' + model.menu.footer" [class.named]="model.menu.named">
        <div class="item" *ngFor="let item of model.tiles" [class.active]="item.active"
            [style.background-color]="(model.menu.footer === 'bg' || model.menu.footer === 'background') && item.active ?  item.color : ''"
            [style.color]="(model.menu.footer !== 'bg' && model.menu.footer !== 'background') && item.active ?  item.color : ''"
            (touchrelease)="goto(item)">

            <div class="icon" [style.background-color]="model.menu.footer === 'circles' && item.active ? item.color : ''">
                <i *ngIf="item.icon" [class]="item.icon.class">{{item.icon.value}}</i>
                <img *ngIf="item.img" [src]="item.active ? item.img : item.img_inactive || item.img" [alt]="item.name">
            </div>
            <div class="text" *ngIf="model.menu.named">{{item.name}}</div>
        </div>
    </div>
</div>
