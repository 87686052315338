<div class="container">
    <div class="block">
        <div class="id-card">
            <div class="white"></div>
            <div class="avatar"></div>
            <div class="name">{{model.user?.name}}</div>
            <div class="role">{{model.user?.role}}</div>
            <div class="edit" (touchrelease)="edit()">
                <i class="material-icons">edit</i>
            </div>
        </div>
        <div class="payment-card">
            <div class="card">
                <payment-card number="4111111111111111" name="Ms Test User" expiry="02/**"></payment-card>
            </div>
            <div class="update">
                <btn name="full-width primary">Update card</btn>
            </div>
        </div>
    </div>
    <div class="block">
        <div class="catering">
            <div class="header">
                <div class="heading">{{ model.orders && model.orders.length > 0 ? 'Your orders' : 'Create an order now' }}</div>
                <div class="info" [class.active]="model.orders && model.orders.length > 0">
                    {{ model.orders && model.orders.length > 0 ? '*estimated 10 minute arrival' : 'No orders yet' }}
                </div>
                <div class="options">
                    <btn format="mini-action" (tapped)="goto({ id: 'catering' })">
                        <i class="material-icons">add</i>
                    </btn>
                    <btn format="mini-action" [disabled]="true">
                        <i class="material-icons">edit</i>
                    </btn>
                </div>
            </div>
            <div class="list">
                <div class="no-item">
                    <div class="icon">
                        <img src="assets/img/coffee.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>