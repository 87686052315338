
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

@Component({
    selector: 'routing-breadcrumbs',
    templateUrl: './routing-breadcrumbs.template.html',
    styleUrls: ['./routing-breadcrumbs.styles.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, width: '*', height: '*' })),
            state('hide', style({ opacity: 0, width: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class RoutingBreadcrumbsComponent extends BaseComponent implements OnInit {
    @Input() public show = false;
    @Output() public showChange = new EventEmitter();

    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.init();
        this.model.routing = {};
        this.subs.obs.router_events = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.menu = this.service.Settings.get('app.menu');
        this.model.route = this.service.Settings.get('app.routing');
        this.model.titles = this.service.Settings.get('app.page.titles');
        this.subs.obs.blk_height = this.service.listen('BANNER.block_height', (v: number) => this.model.show = !v);
        this.checkRoute();
    }

    public tap(route: string) {
        if (route) {
            this.service.navigate(route);
        }
    }


    public checkRoute() {
        this.timeout('route', () => {
            const route = this.router.url.split('?')[0];
            this.model.routing = {};
            this.model.is_home = route === '/' || route === '/home' || route === '/help';
            if (this.model.menu && this.model.menu.list) {
                let found = false;
                for (const i of this.model.menu.list) {
                    i.active = route === `/${i.id}` && !found;
                    if (!found) {
                        found = route === `/${i.id}`;
                        if (found) {
                            const parts = i.id.split('/');
                            const first = parts.shift();
                            this.model.routing.main = first;
                        }
                        if (i.items) {
                            for (const itm of i.items) {
                                itm.active = route === `/${itm.id}` && !found;
                                if (!found) {
                                    found = route === `/${itm.id}`;
                                    if (found) {
                                        const parts = itm.id.split('/');
                                        const first = parts.shift();
                                        this.model.routing.main = first;
                                        this.model.routing.sub = this.model.titles[itm.id] || parts.join(' ').split('-').join(' ') || '';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }, 50);
    }
}
