
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

@Component({
    selector: 'footer-menu',
    templateUrl: './footer-menu.template.html',
    styleUrls: ['./footer-menu.styles.scss']
})
export class FooterMenuComponent extends BaseComponent implements OnInit {

    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.model.hide_routes = ['/', '/home'];
        this.init();
        this.subs.obs.router_events = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.menu = this.service.Settings.get('app.menu');
        this.model.tiles = this.service.Settings.get('app.tiles');
        this.checkRoute();
    }

    public goto(item: any) {
        if (item.id) {
            this.service.navigate(item.id, item.query || {});
        } else if (item.link || item.url) {
            window.open(item.link || item.url, 'blank_');
        }
        if (this.model.menu) {
            this.model.menu.show = false;
        }
    }

    public checkRoute() {
        this.timeout('route', () => {
            const route = this.router.url.split('?')[0];
            if (this.model.menu) {
                this.model.show = this.model.hide_routes.indexOf(route) < 0;
                if (this.model.menu.list) {
                    let found = false;
                    for (const i of this.model.menu.list) {
                        i.active = route === `/${i.id}` && !found;
                        if (!found) { found = route === `/${i.id}`; }
                        if (i.items) {
                            for (const itm of i.items) {
                                itm.active = route === `/${itm.id}` && !found;
                                if (!found) { found = route === `/${itm.id}`; }
                            }
                        }
                    }
                }
            }
        }, 50);
    }
}
