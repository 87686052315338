
<div class="container" *ngIf="model.menu">
    <div class="tapzone" (window:tap)="close()" (window:swipe)="swipe($event)"></div>
    <div class="overlay" [class.show]="show"></div>
    <div class="menu" [class.show]="show" [class.sidebar]="model.menu.type === 'sidebar'" [class.rev]="model.menu.reverse" (touchrelease)="tap()">
        <div class="topbar" [style.background-image]="model.menu.background ? 'url(' + model.menu.background + ')' : ''">
            <i class="material-icons" *ngIf="model.bld_count > 1" (touchrelease)="building.emit(true)">location_city</i>
            <div class="location" *ngIf="model.bld_count > 1" (touchrelease)="building.emit(true)">{{model.bld?.name}}</div>
        </div>
        <div class="list">
            <div class="menu-item" *ngFor="let item of model.list">
                <div class="item" [class.active]="item.active" (touchrelease)="goto(item)">
                    <div class="name">{{item.name}}</div>
                    <div class="icon" *ngIf="item.id">
                        <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                </div>
                <div class="submenu" [class.show]="item.show" *ngIf="item.items && item.items.length > 0">
                    <div class="item" *ngFor="let itm of item.items" [class.active]="item.active" (touchrelease)="goto(itm)">
                        <div class="name">{{itm.name}}</div>
                        <div class="icon" *ngIf="itm.id">
                            <i class="material-icons">keyboard_arrow_right</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-footer">
            <div class="copyright">{{model.copyright}}</div>
        </div>
    </div>
</div>
