
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild, ElementRef, ViewChildren, QueryList, OnInit, OnDestroy } from '@angular/core';

import { AppService } from '../../services/app.service';

import * as moment from 'moment';
import { Utils } from '../../shared/utility.class';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.template.html',
    styleUrls: ['./chat.styles.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [style({ transform: 'scale(0)' }), animate(200, style({ transform: 'scale(1)' }))]),
            transition(':leave', [style({ transform: 'scale(1)' }), animate(200, style({ transform: 'scale(0)' }))]),
        ]),
    ],
})
export class ChatComponent implements OnInit, OnDestroy {
    public model: any = {};

    private timers: any = {};

    @ViewChild('input', { static: true }) private input: ElementRef;
    @ViewChild('search', { static: true }) private search: ElementRef;
    @ViewChild('body', { static: true }) private body: ElementRef;
    @ViewChildren('message') private msg_items: QueryList<ElementRef>;

    constructor(private service: AppService) {
        const win = window as any;
        this.model.speech = !!(win.SpeechRecognition || win.webkitSpeechRecognition);
        this.model.messages = [];
        this.service.Buildings.listen((bld) => {
            if (bld) {
                this.model.system = bld.systems ? bld.systems.messaging : '';
                this.model.phone = bld.phone ? bld.phone.concierge : '';
                this.getMessages();
            }
        });
    }

    public ngOnInit() {
        if (this.timers.time) {
            clearInterval(this.timers.time);
            this.timers.time = null;
        }
        if (this.timers.messages) {
            clearInterval(this.timers.messages);
            this.timers.messages = null;
        }
        this.model.is_iOS = Utils.isMobileSafari();
        this.model.bottom = true;
        this.timers.time = setInterval(() => this.updateDisplay(), 1000);
        this.timers.messages = setInterval(() => this.getMessages(), 2 * 1000);
        this.service.set('BANNER.block_height', 0);
        this.init();
    }

    public ngOnDestroy() {
        if (this.timers.time) {
            clearInterval(this.timers.time);
            this.timers.time = null;
        }
        if (this.timers.messages) {
            clearInterval(this.timers.messages);
            this.timers.messages = null;
        }
    }

    public init() {
        this.model.user = this.service.Users.current();
        if (!this.service.Settings.setup || !this.model.user) {
            return setTimeout(() => this.init, 500);
        }
        this.getMessages();
        this.updateDisplay();
        this.service.Analytics.screen('Chat');
        this.service.Analytics.page('/chat');
    }

    public updateDisplay() {
        const now = moment();
        for (const msg of this.model.messages) {
            const time = moment(Math.round(msg.ts));
            msg.time = now.isSame(time, 'd') ? time.fromNow() : time.format('Do MMM, h:mma');
        }
    }
    public getMessages() {
        if (this.model.system) {
            if (this.timers.get_messages) {
                clearTimeout(this.timers.get_messages);
                this.timers.get_messages = null;
            }
            this.timers.get_messages = setTimeout(() => {
                const mod = this.service.Systems.getModule(this.model.system, 'Slack');
                if (mod) {
                    mod.exec('get_historic_messages', [])
                        .then((resp: any) => {
                            if (resp.messages) {
                                for (const msg of resp.messages) {
                                    let found = false;
                                    for (const i of this.model.messages) {
                                        if (i.ts === msg.ts * 1000) {
                                            found = true;
                                            i.read = resp.last_read >= i.ts * 1000 || resp.last_sent > i.ts * 1000;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        const username = msg.username ? (this.model.user && msg.username !== 'Concierge' ? this.model.user.name : msg.username) : 'Concierge';
                                        this.model.messages.push({
                                            id: ((new Date()).getTime() % 9999999).toString() + Math.floor(Math.random() * 9999),
                                            content: msg.text,
                                            user: {
                                                name: username,
                                                image: '',
                                            },
                                            other: username !== 'Concierge',
                                            time: '',
                                            ts: msg.ts * 1000,
                                            read: resp.last_read >= msg.ts * 1000 || resp.last_sent > msg.ts * 1000
                                        });
                                    }
                                }
                                this.model.messages.sort((a, b) => a.ts - b.ts);
                            }
                            this.filter();
                            this.updateDisplay();
                            setTimeout(() => {
                                this.scrollBottom(999999);
                                if (this.model.messages.length === this.model.fltrd_msgs.length) {
                                    this.scrollBottom(999999);
                                } else {
                                    this.scrollToActive();
                                }
                                this.timers.get_messages = null;
                            }, 200);
                        }, () => this.timers.get_messages = null);
                } else {
                    setTimeout(() => this.getMessages(), 500);
                    this.timers.get_messages = null;
                }
            });
        }
    }

    public newMessage(msg: any) {
        if (!msg || !msg.text) { return; }
        const username = msg.username ? msg.username.split(' (')[0] : 'Concierge';
        const message = {
            id: ((new Date()).getTime() % 999999).toString() + Math.floor(Math.random() * 8999 + 1000),
            content: msg.text,
            user: {
                name: username,
                image: '',
            },
            time: '',
            ts: msg.ts,
        };
        let found = false;
        for (const m of this.model.messages) {
            if (m.ts === message.ts) {
                found = true;
                break;
            }
        }
        if (!found) {
            this.model.messages.push(message);
            this.filter();
            setTimeout(() => this.scrollBottom(), 200);
        }
    }

    public post() {
        if (!this.model.msg) { return; }
        const mod = this.service.Systems.getModule(this.model.system, 'Slack');
        if (mod) {
            mod.exec('send_message', [this.model.msg]).then(() => {
                this.newMessage(this.model.msg);
                setTimeout(() => this.getMessages(), 500);
            }, () => null);
        } else {
            return setTimeout(() => this.getMessages(), 500);
        }
        setTimeout(() => this.model.msg = '', 200);
        // const js_date = chrono.parseDate(this.input_msg);
        // const date = moment(js_date);
        // const now = moment();
        // if (now.isBefore(date)) {
        //     const msg = this.input_msg;
        //     this.waiting = true;
        //     setTimeout(() => {
        //         this.messages.push({
        //             content: `Would you like to book Activity Space 7.18 on ${date.format('Do MMMM, YYYY')} at ${date.format('h:mmA')}?`,
        //             user: this.c_user,
        //             time: moment().fromNow(),
        //             confirm: true,
        //         });
        //         this.waiting = false;
        //         setTimeout(() => {
        //             this.scrollBottom();
        //         }, 200);
        //     }, 1000);
        // }
        this.model.msg = '';
        if (this.input && this.input.nativeElement) {
            this.input.nativeElement.blur();
        }
    }

    public call() {
        if (this.model.phone) {
            window.open(`tel:${this.model.phone}`);
        }
    }

    public startDictation() {
        if (!this.input) { return; }
        if (this.model.recognition) {
            this.model.recognition.stop();
            this.model.dictate = false;
            this.model.recognition = null;
            return;
        }
        const win = self as any;
        const Speech: any = win.SpeechRecognition || win.webkitSpeechRecognition;
        if (Speech) {
            this.model.recognition = new Speech();

            this.model.recognition.continuous = false;
            this.model.recognition.interimResults = false;

            this.model.recognition.lang = 'en-US';
            this.model.recognition.start();
            this.model.dictate = true;

            this.model.recognition.onresult = (e: any) => {
                this.input.nativeElement.value = e.results[0][0].transcript;
                this.model.msg = e.results[0][0].transcript;
                this.model.recognition.stop();
                setTimeout(() => this.post(), 1000);
                this.model.dictate = false;
            };

            this.model.recognition.onerror = (e: any) => {
                this.model.recognition.stop();
                this.model.dictate = false;
            };
        }
    }

    public focusSearch() {
        this.model.focus_search = true;
        if (this.search) {
            this.model.focus_search = true;
            setTimeout(() => {
                this.model.focus_search = true;
                this.search.nativeElement.focus();
            }, 300);
        }
    }

    public updateScroll() {
        if (this.body) {
            const el = this.body.nativeElement;
            this.model.bottom = el.scrollTop + el.clientHeight >= el.scrollHeight;
        }
    }

    public nextActive() {
        this.model.active++;
        this.model.active %= this.model.fltrd_msgs.length;
        this.model.match = this.model.messages.indexOf(this.model.fltrd_msgs[this.model.active]);
        setTimeout(() => this.scrollToActive(), 200);
    }

    public prevActive() {
        this.model.active--;
        if (this.model.active < 0) {
            this.model.active = this.model.fltrd_msgs.length - 1;
        }
        this.model.match = this.model.messages.indexOf(this.model.fltrd_msgs[this.model.active]);
        setTimeout(() => this.scrollToActive(), 200);
    }

    public filter() {
        this.model.fltrd_msgs = [];
        const search = (this.model.search || '').toLowerCase();
        if (!search) {
            this.model.fltrd_msgs = this.model.messages;
            for (const msg of this.model.messages) {
                msg.disable = false;
            }
        } else {
            for (const msg of this.model.messages) {
                msg.disable = true;
                for (const k in msg) {
                    if (msg.hasOwnProperty(k)) {
                        if (typeof msg[k] === 'string' && msg[k].toLowerCase().indexOf(search) >= 0) {
                            this.model.fltrd_msgs.push(msg);
                            msg.disable = false;
                            break;
                        }
                    }
                }
            }
        }
        if (this.model.previous_search !== search) {
            this.model.active = 0;
        }
        this.model.previous_search = search;
        this.model.match = this.model.messages.indexOf(this.model.fltrd_msgs[this.model.active]);
        setTimeout(() => this.scrollToActive(), 200);
    }

    public clearFilter() {
        this.model.search = '';
        this.model.focus_search = false;
        this.filter();
    }

    private scrollToActive() {
        let msg: any = {};
        if (this.model.search && this.model.fltrd_msgs.length > 0) {
            msg = this.model.fltrd_msgs[this.model.active];
            if (msg) {
                const id = `chat-msg-${msg.id}`;
                const elems = this.msg_items.toArray();
                for (const el of elems) {
                    if (el && el.nativeElement.id === id) {
                        el.nativeElement.scrollIntoView();
                        break;
                    }
                }
            }
        }
        for (const m of this.model.messages) {
            m.active = (m.id === msg.id);
        }
    }

    private scrollBottom(delta: number = 30, attempt: number = 0) {
        if (this.body) {
            const el = this.body.nativeElement;
            el.scrollTop += delta;
            if (el.scrollTop + el.clientHeight >= el.scrollHeight - 50) {
                el.scrollTop = el.scrollHeight;
                this.model.bottom = true;
            } else if (attempt < this.model.messages.length * (delta % 100 + 20) * 20) {
                setTimeout(() => this.scrollBottom(delta, ++attempt), 10);
            }
        }
    }
}
