
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.template.html',
    styleUrls: ['./home.styles.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.model.settings = {};
        this.service.set('BANNER.block_height', 0);
        this.init();
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.tiles = this.service.Settings.get('app.tiles');
        this.model.banner = this.service.Settings.get('app.banner.home');
        this.model.background = this.service.Settings.get('app.home.background');
        this.model.disclaimer = this.service.Settings.get('app.home.disclaimer');
        this.model.settings = this.service.Settings.get('app.home') || {};
        if (this.model.tiles.length < 4) {
            this.model.tile_height = 100 / this.model.tiles.length;
        } else {
            this.model.tile_height = 100 / ((this.model.tiles.length + this.model.tiles.length % 2) / 2);
        }
        this.service.Analytics.screen('Home');
        this.service.Analytics.page('/');
        this.subs.obs.banner = this.service.listen('BANNER.block_height', (value) => {
            if (value !== 0){
                this.service.set('BANNER.block_height', 0);
            }
        });
    }

    public goto(tile: any) {
        if (tile.id) {
            this.model.selected_tile = tile;
            setTimeout(() => {
                this.service.navigate(tile.id, tile.query);
                setTimeout(() => this.model.selected_tile = null, 500);
            }, 1.5 * 1000);
        } else if (tile.url) {
            window.open(tile.url, 'blank_');
        }
    }
}
