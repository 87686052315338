<ng-container *ngIf="form">
    <div class="preferences-field" [formGroup]="form">
        <div class="header" [class.active]="show">
            <div class="bar"></div>
            <div class="title" (touchrelease)="show = !show">
                <div class="text">{{ field.label }}</div>
                <div class="icon"><i class="material-icons">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</i></div>
            </div>
            <div class="bar"></div>
        </div>
        <div class="body" [@show]="show ? 'show' : 'hide'">
            <ng-container *ngFor="let item of (extras || [])">
                <checkbox [(model)]="item.active" [label]="item.name"></checkbox>
            </ng-container>
            <ng-container *ngIf="!extras || extras.length <= 0">
                <div class="no-items">
                    <div class="text">No {{field.label}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
