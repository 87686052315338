/**
 * @Author: Alex Sorafumo <alex.sorafumo>
 * @Date:   12/01/2017 2:25 PM
 * @Email:  alex@yuion.net
 * @Filename: app.service.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 03/02/2017 10:25 AM
 */

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';

import { SystemsService, CommsService } from '@acaprojects/ngx-composer';
import { OverlayService, NotificationComponent } from '@acaprojects/ngx-widgets';

import { SettingsService } from './settings.service';
import { AnalyticsService } from './data/analytics.service';
import { Utils } from '../shared/utility.class';

import { UsersService } from './data/users.service';
import { BuildingsService } from './data/buildings.service';
import { CateringService } from './data/catering.service';
import { CommentsService } from './data/comments.service';
import { RoomsService } from './data/rooms.service';
import { BookingsService } from './data/bookings.service';
import { VisitorsService } from './data/visitors.service';
import { HotkeyService } from './hotkey.service';
import { LockersService } from './data/lockers.service';
import { ContactsService } from './data/contacts.service';
import { LocationService } from './data/location.service';

import { ConfirmModalComponent } from '../overlays/confirm-modal/confirm-modal.component';
import { TimePeriodModalComponent } from '../overlays/time-period-modal/time-period-modal.component';
import { CalendarModalComponent } from '../overlays/calendar-modal/calendar-modal.component';
import { BookingModalComponent } from '../overlays/booking-modal/booking-modal.component';
import { AttendeeModalComponent } from '../overlays/attendee-modal/attendee-modal.component';
import { RoomFilterModalComponent } from '../overlays/room-filter-modal/room-filter-modal.component';
import { LevelSelectModalComponent } from '../overlays/level-select-modal/level-select-model.component';
import { MapFilterModalComponent } from '../overlays/map-filter-modal/map-filter-modal.component';
import { ViewRoomModalComponent } from '../overlays/view-room-modal/view-room-modal.component';
import { VisitorDetailsOverlayComponent } from '../overlays/visitor-details-overlay/visitor-details-overlay.component';
import { UserDetailsOverlayComponent } from '../overlays/user-details-overlay/user-details-overlay.component';
import { MeetingDetailsOverlayComponent } from '../overlays/meeting-details-overlay/meeting-details-overlay.component';
import { TermsOverlayComponent } from '../overlays/terms-overlay/terms-overlay.component';
import { ItemSelectModalComponent } from '../overlays/item-select-modal/item-select-model.component';
import { CateringModalComponent } from '../overlays/catering-modal/catering-modal.component';
import { RoomSelectModalComponent } from '../overlays/room-select-modal/room-select-modal.component';
import { AvailabilityOverlayComponent } from '../overlays/availability-overlay/availability-overlay.component';
import { DeviceListModalComponent } from '../overlays/device-list-modal/device-list-modal.component';
import { DatePeriodModalComponent } from '../overlays/date-period-modal/date-period-modal.component';
import { OldTimePeriodModalComponent } from '../overlays/old-time-period-modal/time-period-modal.component';

declare global {
    interface Window {
        application: AppService;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private api_base = 'api/staff';

    private subjects: { [name: string]: BehaviorSubject<any> } = {};
    private observers: { [name: string]: Observable<any> } = {};

    private prev_route: string[] = [];
    private model: { [name: string]: any } = {};
    private timers: { [name: string]: number } = {};

    constructor(
        private _title: Title,
        private version: SwUpdate,
        private router: Router,
        private comms: CommsService,
        private overlay: OverlayService,
        private analytics: AnalyticsService,
        private settings: SettingsService,
        private systems: SystemsService,
        private hotkeys: HotkeyService,
        private lockers: LockersService,
        private buildings: BuildingsService,
        private bookings: BookingsService,
        private catering: CateringService,
        private comments: CommentsService,
        private rooms: RoomsService,
        private users: UsersService,
        private visitors: VisitorsService,
        private location: LocationService,
        private contacts: ContactsService
    ) {
        // Set parent service on child services
        this.buildings.parent = this.bookings.parent = this.rooms.parent = this.comments.parent = this;
        this.users.parent = this.analytics.parent = this.catering.parent = this.visitors.parent = this;
        this.lockers.parent = this.contacts.parent = this.location.parent = this;
        // Create subjects
        this.subjects.system = new BehaviorSubject('');
        this.observers.system = this.subjects.system.asObservable();
        this.subjects.systems = new BehaviorSubject<any[]>([]);
        this.observers.systems = this.subjects.systems.asObservable();
        // Register modals/overlay components
        this.overlay.registerService(this);
        this.overlay.setupModal('attendees', { cmp: AttendeeModalComponent });
        this.overlay.setupModal('booking-form', { cmp: BookingModalComponent });
        this.overlay.setupModal('calendar', { cmp: CalendarModalComponent });
        this.overlay.setupModal('catering', { cmp: CateringModalComponent });
        this.overlay.setupModal('confirm', { cmp: ConfirmModalComponent });
        this.overlay.setupModal('room-filter', {
            cmp: RoomFilterModalComponent,
        });
        this.overlay.setupModal('time-period', {
            cmp: TimePeriodModalComponent,
        });
        this.overlay.setupModal('old-time-period', {
            cmp: OldTimePeriodModalComponent,
        });
        this.overlay.setupModal('level-select', {
            cmp: LevelSelectModalComponent,
        });
        this.overlay.setupModal('map-filter', { cmp: MapFilterModalComponent });
        this.overlay.setupModal('view-room', { cmp: ViewRoomModalComponent });
        this.overlay.setupModal('item-select', {
            cmp: ItemSelectModalComponent,
        });
        this.overlay.setupModal('user-details', {
            cmp: UserDetailsOverlayComponent,
            name: 'overlay',
        });
        this.overlay.setupModal('visitor-details', {
            cmp: VisitorDetailsOverlayComponent,
            name: 'overlay',
        });
        this.overlay.setupModal('meeting-details', {
            cmp: MeetingDetailsOverlayComponent,
            name: 'overlay',
        });
        this.overlay.setupModal('terms', {
            cmp: TermsOverlayComponent,
            name: 'overlay',
        });
        this.overlay.setupModal('availability', {
            cmp: AvailabilityOverlayComponent,
            name: 'overlay',
        });
        this.overlay.setupModal('select-room', {
            cmp: RoomSelectModalComponent,
        });
        this.overlay.setupModal('device-list', {
            cmp: DeviceListModalComponent,
        });
        this.overlay.setupModal('date-period', {
            cmp: DatePeriodModalComponent,
        });
        this.init();
    }

    get endpoint() {
        const host = this.Settings.get('composer.domain');
        const protocol = this.Settings.get('composer.protocol');
        const port = protocol === 'https:' ? '443' : '80';
        const url = `${protocol || location.protocol}//${host || location.host}`;
        const endpoint = `${url}`;
        return endpoint;
    }

    get api_endpoint() {
        return `${this.endpoint}/${this.api_base}`;
    }

    public init(tries: number = 0) {
        if (!this.settings.setup) {
            if (tries === 5) {
                this.settings.init();
            }
            return setTimeout(() => this.init(), 500);
        }
        this.version.available.subscribe((event) => {
            const current = `current version is ${event.current.hash}`;
            const available = `available version is ${event.available.hash}`;
            this.settings.log('CACHE', `Update available: ${current} ${available}`);
            this.info('Newer version of the app is available', 'Refresh', () => {
                location.reload();
            });
        });
        if (this.settings.get('debug')) {
            window.application = this;
            this.hotkeys.listen(['Alt', 'Shift', 'KeyK'], () => {
                this.comms.token.then((t: string) => {
                    Utils.copyToClipboard(t);
                    this.info('Copied access token to clipboard');
                });
            });
        }
        this.model.title = this.settings.get('app.title') || 'Angular Application';
        this.initialiseComposer();
        if (this.users) {
            const sub = this.users.listen('state', (state) => {
                if (state === 'available') {
                    this.loadSystems();
                    sub.unsubscribe();
                }
            });
        } else {
            this.loadSystems();
        }
        this.analytics.init();
        // Initialise data services
        this.buildings.init();
        this.bookings.init();
        this.users.init();
        this.rooms.init();
        this.catering.init();
        this.visitors.init();
        this.lockers.init();
        this.contacts.init();
        NotificationComponent.timeout(5000);
        // setTimeout(() => this.overlay.openModal('availability', { data: {} }, (e) => e.close()), 1000);
        this.hotkeys.listen(['Alt', 'Shift', 'KeyH'], () => this.navigate(''));
        setInterval(() => this.checkCache(), 5 * 60 * 1000);
    }

    public ready() {
        const mock = this.settings.get('mock') ? (window as any).backend : null;
        const app_ready = this.settings.setup && this.users.current() && this.buildings.current();
        return app_ready && (!mock || mock.is_loaded);
    }

    public initialiseComposer(tries: number = 0) {
        this.settings.log('SYSTEM', 'Initialising Composer...');
        // Get domain information for configuring composer
        const host = this.settings.get('composer.domain') || location.hostname;
        const protocol = this.settings.get('composer.protocol') || location.protocol;
        const port = protocol.indexOf('https') >= 0 ? '443' : '80';
        const url = this.settings.get('composer.use_domain')
            ? `${protocol}//${host}`
            : location.origin;
        const route = this.settings.get('composer.route') || '';
        // Generate configuration for composer
        const config: any = {
            id: 'AcaEngine',
            scope: 'public',
            protocol,
            host,
            port,
            oauth_server: `${url}/auth/oauth/authorize`,
            oauth_tokens: `${url}/auth/token`,
            redirect_uri: `${location.origin}${route}/oauth-resp.html`,
            api_endpoint: `${url}/control/`,
            proactive: true,
            login_local:
                this.settings.get('composer.local_login') ||
                (location.search || '').indexOf('prevent_login=true') >= 0 ||
                false,
            http: true,
        };
        // Enable mock/development environment if the settings is defined
        const mock = this.settings.get('mock');
        if (mock) {
            config.mock = true;
            config.http = false;
        }
        // Setup/Initialise composer
        this.systems.setup(config);
    }
    /**
     * Listen to changes of given property
     * @param name Name of the property
     * @param next Callback for changes to properties value
     */
    public listen(name: string, next: (data: any) => void) {
        if (this.subjects[name]) {
            return this.observers[name].subscribe(next);
        } else {
            // Create new variable to store property's value
            this.subjects[name] = new BehaviorSubject<any>(
                this[name] instanceof Function ? null : this[name]
            );
            this.observers[name] = this.subjects[name].asObservable();
            // Create raw getter and setter for property
            if (!(this[name] instanceof Function)) {
                Object.defineProperty(this, name, {
                    get: () => this.get(name),
                    set: (v: any) => this.set(name, v),
                });
            }
            return this.observers[name].subscribe(next);
        }
    }

    /**
     * Get the current value of the given property
     * @param name Name of the property
     */
    public get(name: string) {
        return this.subjects[name] ? this.subjects[name].getValue() : null;
    }

    /**
     * Set the value of the given property
     * @param name Name of the property
     * @param value New value to assign to the property
     */
    public set(name: string, value: any) {
        if (this.subjects[name]) {
            console.warn(name, value);
            this.subjects[name].next(value);
        } else {
            // Create new variable to store property's value
            this.subjects[name] = new BehaviorSubject<any>(value);
            this.observers[name] = this.subjects[name].asObservable();
            // Create raw getter and setter for property
            if (!(this[name] instanceof Function)) {
                Object.defineProperty(this, name, {
                    get: () => this.get(name),
                    set: (v: any) => this.set(name, v),
                });
            }
        }
    }

    get Analytics() {
        return this.analytics;
    }
    get Settings() {
        this.settings.get = (setting: string) =>
            this.buildings.getSetting(setting) || this.settings.getSetting(setting);
        return this.settings;
    }
    get Overlay() {
        return this.overlay;
    }
    get Systems() {
        return this.systems;
    }
    get Hotkeys() {
        return this.hotkeys;
    }
    get system() {
        return this.subjects.system.getValue();
    }
    set system(value: string) {
        this.subjects.system.next(value);
    }
    // Getters for data/API services
    get Buildings() {
        return this.buildings;
    }
    get Bookings() {
        return this.bookings;
    }
    get Catering() {
        return this.catering;
    }
    get Rooms() {
        return this.rooms;
    }
    get Users() {
        return this.users;
    }
    get Visitors() {
        return this.visitors;
    }
    get Lockers() {
        return this.lockers;
    }
    get Contacts() {
        return this.contacts;
    }
    get Location() {
        return this.location;
    }

    /**
     * Set the page title
     * @param str New value to set the page title
     */
    set title(str: string) {
        if (!this.model.title) {
            this.model.title = this.settings.get('app.title') || '';
        }
        const title = `${str ? str : ''}${this.model.title ? ' | ' + this.model.title : ''}`;
        this._title.setTitle(title || this.settings.get('app.title'));
    }

    /**
     * Get the current page title
     */
    get title() {
        return this._title.getTitle();
    }

    /**
     * Open Terms and conditions modal
     */
    public viewTerms() {
        this.overlay.openModal('terms', {}).then((inst: any) => inst.subscribe((e) => e.close()));
    }

    public logout() {
        this.users.logout();
    }

    /**
     * Wrapper for Angular Router navigate method
     * @param path Path to navigate
     * @param query Query parameter to add to the route
     */
    public navigate(path: string | string[], query?: { [name: string]: any }) {
        let path_list = [];
        if (path instanceof Array) {
            path_list = path_list.concat(path);
        } else {
            path_list.push(path);
        }
        this.prev_route.push(this.router.url);
        // if (!this.systems.resources.authLoaded) {
        this.router.navigate(path_list, { queryParams: query });
        // } else {
        // this.router.navigate([path]);
        // }
    }

    /**
     * Return to the previous page
     */
    public back() {
        if (this.prev_route.length > 0) {
            this.navigate(this.prev_route.pop());
            this.prev_route.pop();
        } else {
            this.navigate('');
        }
    }

    /**
     * Log message to the console
     * @param type Message prefix
     * @param msg Message to emit
     * @param args Arguments to attach to log
     * @param stream Stream to output the message
     */
    public log(type: string, msg: string, args?: any, stream: string = 'debug') {
        this.settings.log(type, msg, args, stream);
    }

    /**
     * Open confirm modal
     * @param options Options to pass to the modal
     * @param next Callback for events on the modal
     */
    public confirm(options: any, next: (event: any) => void) {
        this.Overlay.openModal('confirm', {
            data: options,
        }).then((inst: any) => inst.subscribe(next));
    }

    /**
     * Create a new error notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public error(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Error`;
        this.overlay.notify('success', {
            html: `<div class="display-icon error" style="font-size:2.0em"></div><div class="msg">${message}</div>`,
            name: 'ntfy error',
            action,
        });
    }

    /**
     * Create a new success notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public success(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Success`;
        this.overlay.notify(
            'success',
            {
                html: `<div class="display-icon success" style="font-size:2.0em"></div><div class="msg">${message}</div>`,
                name: 'ntfy success',
                action,
            },
            event
        );
    }

    /**
     * Create a new informational notification
     * @param msg Message to display
     * @param action User action to display
     * @param event Callback for user action
     */
    public info(msg: string, action?: string, event?: () => void) {
        const message = msg ? msg : `Information`;
        this.overlay.notify(
            'info',
            {
                html: `<div class="display-icon info" style="font-size:2.0em"></div></div><div class="msg">${message}</div>`,
                name: 'ntfy info',
                action,
            },
            event
        );
    }

    get iOS() {
        return Utils.isMobileSafari();
    }

    public getSystem(id: string) {
        const system_list = this.subjects.systems.getValue();
        if (system_list) {
            for (const system of system_list) {
                if (system.id === id) {
                    return system;
                }
            }
        }
        return {};
    }

    private addSystems(list: any[]) {
        const system_list = this.subjects.systems.getValue().concat(list);
        system_list.sort((a, b) => a.name.localeCompare(b.name));
        this.subjects.systems.next(system_list);
    }

    private loadSystems(tries: number = 0) {
        if (this.timers.system) {
            clearTimeout(this.timers.system);
            this.timers.system = null;
        }
        if (tries > 20) {
            return;
        }
        const systems = this.systems.resources.get('System');
        if (systems) {
            tries = 0;
            systems.get({ offset: '0', limit: 500 }).then(
                (sys_list: any) => {
                    this.subjects.systems.next([]);
                    if (sys_list) {
                        const count = sys_list.total;
                        if (count > 500) {
                            const iter = Math.ceil((count - 500) / 500);
                            for (let i = 0; i < iter; i++) {
                                systems
                                    .get({ offset: (i + 1) * 500, limit: 500 })
                                    .then((list: any) => {
                                        if (list) {
                                            this.addSystems(list.results);
                                        }
                                    });
                            }
                        }
                        this.addSystems(sys_list.results);
                    } else {
                        this.timers.system = <any>(
                            setTimeout(() => this.loadSystems(tries), 200 * ++tries)
                        );
                    }
                },
                (err: any) => {
                    this.timers.system = <any>(
                        setTimeout(() => this.loadSystems(tries), 200 * ++tries)
                    );
                }
            );
        } else {
            this.timers.system = <any>setTimeout(() => this.loadSystems(tries), 200 * ++tries);
        }
    }

    private checkCache() {
        if (this.version.isEnabled) {
            this.settings.log('SYSTEM', 'Checking cache for updates');
            this.version
                .checkForUpdate()
                .then(() => this.settings.log('SYSTEM', 'Finished checking cache for updates'))
                .catch((err) => this.settings.log('SYSTEM', err, null, 'error'));
        }
    }
}
