import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { ICateringItem, ICart } from '../../services/data/catering.service';

const max_order_number_per_item = 10;
@Component({
    selector: 'catering-modal',
    templateUrl: './catering-modal.template.html',
    styleUrls: ['./catering-modal.styles.scss'],
})
export class CateringModalComponent extends OverlayContentComponent {
    public filtered_items: ICateringItem[] = [];
    public ordered_items: ICateringItem[] = [];
    public model: any = {
        item_count: {},
    };

    @ViewChild('tablist') private tabs: ElementRef;
    @ViewChildren('tab') private tab_list: QueryList<ElementRef>;

    public init() {
        this.model.item_count = 0;
        this.ordered_items = [];
        this.filtered_items = [];
        if (this.service) {
            this.model.list = JSON.parse(JSON.stringify(this.service.Catering.list()));
            this.model.items = [];
            if (!this.model.cart) {
                this.model.cart = {} as ICart;
            }
            this.model.category_list = [];
            this.model.count = 0;
            for (const cat in this.model.list) {
                if (this.model.list.hasOwnProperty(cat) && this.model.list[cat] && this.model.list[cat].length > 0) {
                    this.model.category_list.push(cat);
                    this.model.count += this.model.list[cat].length;
                    if (this.model.catering && this.model.catering.items) {
                        for (const itm of this.model.list[cat]) {
                            for (const item of this.model.catering.items) {
                                if (itm.id === item.id) {
                                    itm.quantity = item.quantity;
                                    this.model.item_count += item.quantity;
                                }
                            }
                        }
                    }
                    this.model.items = this.model.items.concat(this.model.list[cat]);
                }
            }

            if (this.model.catering) {
                this.model.cart.details = this.model.catering.details;
            }
            setTimeout(() => this.setCategory(''), 500);
        } else {
            this.fn.close();
        }
    }

    public setCategory(value: string) {
        this.model.category = this.model.list[value];
        const tabs = this.tab_list.toArray();
        const tab = tabs[this.model.category_list.indexOf(value) + 1];
        if (tab) {
            const root_rect = this.tabs.nativeElement.getBoundingClientRect();
            const rect = tab.nativeElement.getBoundingClientRect();
            this.model.tab = {
                left: rect.left - root_rect.left,
                width: rect.width,
            };
        }
        this.filter();
    }

    public filter() {
        this.filtered_items = [];
        if (!this.model.category || this.model.category.length <= 0) {
            this.filtered_items = this.model.items;
        } else {
            this.filtered_items = this.model.category;
        }
    }

    public add(item: ICateringItem, amount: number, order: boolean = false) {
        if (!item.quantity) { item.quantity = 0; }
        item.quantity += amount;
        if (item.quantity < 0) {
            item.quantity = 0;
        } else if (item.quantity > 20) {
            item.quantity = 20;
        } else {
            this.model.item_count += amount;
        }
        if (order) {
            this.updateOrder();
        }
    }

    public removeItem(item: any) {
        for (const itm of this.model.cart.items) {
            if (itm.id === item.id) {
                item.quantity = 0;
            }
        }
        this.updateOrder();
    }

    public showOrder() {
        this.model.show_order = true;
        this.updateOrder();
    }

    public order() {
        if (this.model.item_count <= 0) {
            return;
        }
        this.updateOrder();
        this.model.order = this.ordered_items;
        this.model.total = this.model.item_count;
        this.fn.event('Accept');
    }

    private updateOrder() {
        this.model.cart.items = [];
        (this.model.cart as ICart).total = 0;
        (this.model.cart as ICart).total_price = 0;
        for (const item of this.model.items) {
            if (item.quantity > 0) {
                this.model.cart.items.push(item);
                this.model.cart.total += item.quantity;
                this.model.cart.total_price += item.price * item.quantity;
            }
        }
        this.model.item_count = this.model.cart.total;
        if (this.model.cart.total <= 0) {
            this.model.show_order = false;
        }
    }
}
