<div class="container">
    <div class="overlay"></div>
    <div class="login-container">
        <div class="logo" [style.background]="(model.logo ? model.logo.background : '') | safestyle">
            <img *ngIf="model.logo && (model.logo.type==='image' || model.logo.type==='img')" [src]="model.logo.src" alt="Logo">
            <i *ngIf="model.logo && (model.logo.type!=='image' && model.logo.type!=='img')" [class]="model.logo.src"></i>
        </div>
        <div class="form" *ngIf="!model.loading">
            <login-form *ngIf="model.show === 'login'" (event)="processLogin($event)"></login-form>
            <register-form *ngIf="model.show === 'register'"></register-form>
            <forgot-pass-form *ngIf="model.show === 'forgot'"></forgot-pass-form>
        </div>
        <div class="loader" *ngIf="model.loading">
            <div class="icon">
                <spinner type="circle-ring" color="rgba(255, 255, 255, .87)"></spinner>
            </div>
            <div class="text">
                Loading user data...
            </div>
        </div>
        <div class="base">
            <div class="header">Welcome to International Towers Digital System</div>
            <div class="info">
                User login details will be sent to you by e-mail.<br>
                For further assistance contact the help desk.
            </div>
        </div>
    </div>
</div>