<div class="container">
    <div class="header">
        <div class="text">Select Level</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="model.level && model.level.list">
            <div class="item" *ngFor="let item of model.level.list" (touchrelease)="select(item)">
                <div class="text">{{item.name}}</div>
                <div class="icon" *ngIf="item.id === model.active">
                    <i class="material-icons">done</i>
                </div>
            </div>
        </ng-container>
        <div class="no-item" center *ngIf="!model.level || !model.level.list || model.level.list.length <= 0">
            <div class="icon"><i class="material-icons">layers_clear</i></div>
            <div class="text">No levels to select</div>
        </div>
    </div>
</div>