import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { UsersService } from './data/users.service';
import { ITDEAnalyticsService } from './itde-analytics.service';
import { SettingsService } from './settings.service';

import * as dayjs from 'dayjs';

export enum ITDEPaymentMethod {
    CreditCard = 0,
    ChargeCode = 1,
}

@Injectable({
    providedIn: 'root',
})
export class ITDEPaymentsService {
    private _token = () => this._analytics.token;
    private _email = () => this._users.current()?.email || '';
    private _itde_settings = () => this._settings.get('app.ITDE');
    private _prices: { [key: string]: number } = {};

    public readonly has_token = this._analytics.has_token;

    constructor(
        private _analytics: ITDEAnalyticsService,
        private _http: HttpClient,
        private _users: UsersService,
        private _settings: SettingsService
    ) {
        this._prices = JSON.parse(localStorage.getItem('APP.space_prices') || '{}');
    }

    /** Get last price for the given space email */
    public lastPrice(email: string): number {
        return this._prices[email] || 0;
    }

    public async getPrice(
        email: string,
        start_time: number = new Date().valueOf(),
        duration: number = 60
    ) {
        const { token, domain, serviceId } = this._itde_settings();
        const url = `${domain}/odata/v1/pricing?email=${encodeURIComponent(this._email())}`;
        const start = dayjs(start_time);
        const end = start.add(duration, 'm');
        const data = {
            ServiceExternalKey: serviceId,
            ItemNumber: 0,
            Type: 'space',
            ResourceEmail: email,
            Dates: [
                {
                    StartDate: start.format('DD/MM/YYYY'),
                    EndDate: end.format('DD/MM/YYYY'),
                    StartTime: start.format('HH:mm'),
                    EndTime: end.format('HH:mm'),
                },
            ],
        };
        const details: any = await this._http
            .post(url, data, {
                headers: { Authorization: `Bearer ${this._token() || token}` },
            })
            .toPromise()
            .catch(() => ({}));
        const discount = Math.abs(details.details[0]?.discountRate || 0);
        const rate = details.details[0]?.ratePerFrequency * 100;
        this._prices[email] = rate * (1 - discount / 100);
        localStorage.setItem('APP.space_prices', JSON.stringify(this._prices || {}));
        return {
            available: details.isAvailable,
            min_duration: details.frequencyRate === 'Hourly' ? 60 : 30,
            rate,
            discounted_rate: rate * (1 - discount / 100),
            discount,
        };
    }

    public processPayment(
        title: string,
        name: string,
        email: string,
        start_time: number,
        duration: number,
        method: ITDEPaymentMethod = ITDEPaymentMethod.CreditCard
    ) {
        const { token, domain, serviceId } = this._itde_settings();
        const success_url = encodeURIComponent(`${location.href}?complete=true`);
        const error_url = encodeURIComponent(`${location.href}?error=true`);
        const sku = name;
        const amount = this.lastPrice(email) * (duration / 60);
        const start = dayjs(start_time);
        const end = start.add(duration, 'm');
        const data = {
            jwt: token,
            sku,
            userName: this._email(),
            amount: amount / 100,
            successURL: success_url,
            errorURL: error_url,
            paymentMethod: method,
            orderRequests: [
                {
                    Title: name,
                    ServiceExternalKey: serviceId,
                    Dates: [
                        {
                            StartDate: start.format('DD/MM/YYYY'),
                            EndDate: end.format('DD/MM/YYYY'),
                            StartTime: start.format('HH:mm'),
                            EndTime: end.format('HH:mm'),
                        },
                    ],
                    Type: 'space',
                    ResourceEmail: email,
                },
            ],
        };
        return this._http
            .post(`${domain}/payment/add-to-cart`, data)
            .pipe(map((resp: any) => resp.paymentUrl))
            .toPromise();
    }
}
