<div class="container" [class.multi]="model.multi">
    <div class="header">
        <div class="text">Select room</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="model.rooms">
            <div class="item" *ngFor="let item of model.rooms">
                <div class="details">
                    <div class="name">{{item.name}}</div>
                    <div class="level">{{item.level?.name}}</div>
                </div>
                <div class="button">
                    <btn (tapped)="select(item)">Select</btn>
                </div>
            </div>
        </ng-container>
    </div>
</div>