
<div class="card-display" [class.flip]="flip">
    <div class="front" *ngIf="!show_back">
        <div class="arc"></div>
        <div class="logo sm">
            <img [src]="'assets/img/' + (type || 'visa') + '.svg'" alt="Card Type">
        </div>
        <div class="logo lg">
            <img [src]="'assets/img/' + (type || 'visa') + '.svg'" alt="Card Type">
        </div>
        <div class="field card-holder">
            <div class="label">Card Holder</div>
            <div class="value">{{name}}</div>
        </div>
        <div class="card-number">
            <div class="block" *ngFor="let part of number_parts; let i = index">
                {{i < 3 ? '****' : part}}
            </div>
            <div class="block" *ngIf="!number_parts || number_parts.length <= 0">****</div>
            <div class="block" *ngIf="!number_parts || number_parts.length <= 0">****</div>
            <div class="block" *ngIf="!number_parts || number_parts.length <= 0">****</div>
            <div class="block" *ngIf="!number_parts || number_parts.length <= 0">____</div>
        </div>
        <!--div class="bank">Bank of Australia</div-->
        <div class="field expiry">
            <div class="label">Expires</div>
            <div class="value">{{expiry}}</div>
        </div>
    </div>
    <div class="back" *ngIf="show_back">
        <div class="strip"><div class="bar"></div></div>
        <div class="signature">
            <div class="label">Signature</div>
            <div class="value">{{name}}</div>
        </div>
        <div class="cvv">
            <div class="label">CVV</div>
            <div class="value">{{cvv}}</div>
        </div>
    </div>
</div>