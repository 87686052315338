
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'terms-overlay',
    templateUrl: './terms-overlay.template.html',
    styleUrls: ['./terms-overlay.styles.scss']
})
export class TermsOverlayComponent extends OverlayContentComponent {

}
