
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DynamicField } from '@acaprojects/ngx-widgets';

import { AppService } from '../../../services/app.service';
import { BaseComponent } from '../base.component';
import { FormFormatters } from './form.formatters';
import { FormValidators } from './form.validators';

import { CustomDateFieldComponent } from '../custom-fields/date-field/date-field.component';
import { CustomTimeFieldComponent } from '../custom-fields/time-field/time-field.component';
import { CustomDurationFieldComponent } from '../custom-fields/duration-field/duration-field.component';
import { CustomUserListFieldComponent } from '../custom-fields/user-list-field/user-list-field.component';
import { CustomWeekFieldComponent } from '../custom-fields/week-field/week-field.component';
import { CustomTermsFieldComponent } from '../custom-fields/terms-field/terms-field.component';
import { CustomPreferenceFieldComponent } from '../custom-fields/preference-field/preference-field.component';
import { CustomCostFieldComponent } from '../custom-fields/cost-field/cost-field.component';
import { CustomRecurrenceFieldComponent } from '../custom-fields/recurrence-field/recurrence-field.component';
import { CustomPayTypeFieldComponent } from '../custom-fields/pay-type-field/pay-type-field.component';

import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { TimeSelectModalComponent } from '../../../shell/book/space-flow/time-modal.component';

export interface IBookingFormSettings {
    edit?: boolean;
    week?: boolean;
    recurrence?: boolean;
    attendees?: number;
    fixed?: string[];
    hide_errors?: boolean;
}

@Component({
    selector: 'booking-form',
    templateUrl: './booking-form.template.html',
    styleUrls: ['./booking-form.styles.scss']
})
export class BookingFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public form: any = {};
    @Input() public check: boolean;
    @Input() public edit: boolean;
    @Input() public valid: boolean;
    @Input() public settings: IBookingFormSettings = {};
    @Input() public error: any = {};
    @Output() public errorChange = new EventEmitter();
    @Output() public formChange = new EventEmitter();
    @Output() public validChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: { [name: string]: any } = { form: {}, error: {}, display: {} };

    constructor(
        private service: AppService,
        private _dialog: MatDialog) {
        super();
    }

    public ngOnInit() {
        if (!this.form) { this.form = {}; }
        this.model.initialised = false;
        if (!this.model.formatters) { this.model.formatters = {}; }
        if (!this.model.validators) { this.model.validators = {}; }
        if (!this.model.actions) { this.model.actions = {}; }
        if (!this.model.custom_elements) { this.model.custom_elements = {}; }
        this.init();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.form && this.form) {
            console.log('Form:', this.form, this.model.form);
            if (JSON.stringify(this.form) !== JSON.stringify(this.model.form)) {
                this.processFields();
            }
        }
        if (changes.check && this.model.initialised) {
            this.timeout('valid', () => this.processFields(true), 50);
        }
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.user = this.service.Users.current();
        this.model.settings = this.service.Settings.get('app.booking');
        this.model.actions = {
            room: () => this.event.emit('Space'),
            attendees: () => this.event.emit('Attendees'),
            time: () => this.setPeriod(),
            date: () => this.setDate(),
            // availability: () => this.selectAvailability(),
            catering: () => this.event.emit('catering'),
            // recurrence: () => this.setRecurrence(),
            // conferencing: (v?) => this.setConferencing(v)
        };
        this.model.validators = {
            date: [FormValidators.date],
            duration: [FormValidators.duration],
            attendees: [FormValidators.attendees(this.model.user, this.model.settings.min_attendees)],
            terms: [FormValidators.accept()]
        };
        this.model.formatters = {
            attendees: FormFormatters.attendees(this.model.user),
            date: FormFormatters.date,
            room: FormFormatters.space
        };
        this.model.custom_elements = {
            date: CustomDateFieldComponent,
            start: CustomTimeFieldComponent,
            duration: CustomDurationFieldComponent,
            attendees: CustomUserListFieldComponent,
            weekday: CustomWeekFieldComponent,
            terms: CustomTermsFieldComponent,
            preferences: CustomPreferenceFieldComponent,
            extras: CustomPreferenceFieldComponent,
            cost: CustomCostFieldComponent,
            recurrence: CustomRecurrenceFieldComponent,
            card_payment: CustomPayTypeFieldComponent
        };
        this.model.defaults = {
            date: () => moment().valueOf(),
            time: () => {
                const date = moment();
                if (date.minutes() % 5 === 0) { date.minutes(date.minutes() + 1); }
                return date.minutes(Math.ceil(date.minutes() / 5) * 5);
            },
            booking_type: () => 'internal'
        };
        this.model.initialised = true;
        this.processFields();
    }

    public processFields(dirty: boolean = false) {
        const settings = this.service.Settings.get('app') || { booking: {} };
        if (!this.model.formatters) this.model.formatters = {};
        if (!this.model.actions) this.model.actions = {};
        if (!this.model.validators) this.model.validators = {};
        if (!this.model.defaults) this.model.defaults = {};
        if (!this.model.settings) this.model.settings = {};
        this.model.formatters.time = FormFormatters.period(this.form.duration || 60);
        const date = moment(this.form.date);
        date.minutes(Math.ceil(date.minutes() / 5) * 5);
        this.form.time = date.format('HH:mm');
        let fields = [ ...settings.booking.fields ] || [];
        this.model.formatters.time = FormFormatters.period(this.form.duration || 60);
        this.model.form = { ...this.model.form, ...this.form };
        const process_item = (field: DynamicField<any>) => {
            const f = { ...field };
            f.action = this.model.actions[f.key];
            f.format = this.model.formatters[f.key];
            f.validators = this.model.validators[f.key];
            if (f.control_type === 'custom') {
                f.cmp = this.model.custom_elements[f.key];
            }
            if (this.model.defaults[f.key]) {
                const value = this.model.defaults[f.key];
                f.value = value instanceof Function ? value() : value;
            }
            f.dirty = dirty;
            f.hide = f.hide || ((f as any).no_edit === true && this.edit) || ((f as any).edit_only === true && !this.edit);
            if (this.model.form && this.model.form[f.key]) { f.value = this.model.form[f.key]; }
            if (f.children) { f.children.forEach(process_item); }
            this.setValue(f);
            return f;
        };
        fields = fields.map(process_item);
        if (this.form.simple && settings.map.simple) { fields = fields.filter(i => i.simple !== false); }
        this.model.fields = fields;
    }

    public setValue(field) {
        switch (field.key) {
            case 'attendees':
                field.dirty = (this.form[field.key] ? this.form[field.key].length > 0 : false) || field.dirty;
                field.value = this.form[field.key] === undefined ? field.value : this.form[field.key];
                break;
            case 'room':
                const fn = FormValidators.attendees(this.model.user, this.model.settings.min_attendees, this.form[field.key]?.capacity);
                this.model.validators.attendees = [fn],
                field.dirty = field.dirty = (this.form[field.key] ? this.form[field.key].length > 0 : false) || field.dirty;
                field.value = this.form[field.key] === undefined ? null : this.form[field.key];
                break;
            default:
                field.dirty = !!this.form[field.key] || field.dirty;
                field.value = this.form[field.key] === undefined ? field.value : this.form[field.key];
                break;
        }
        if (field.value === undefined) {
            field.value = field.default;
        }
    }

    public to(name: string) {
        if (!name) { return Promise.reject(); }
        this.service.navigate(['book', name]);
        return Promise.reject();
    }

    public post(form) {
        this.model.form = form;
        this.formChange.emit(form);
    }

    public setDate() {
        return new Promise((resolve) => {
            this.service.Overlay.openModal('calendar', { data: { date: this.form.date } }, (event) => {
                if (event.type === 'Accept') {
                    const old_date = moment(this.form.date);
                    this.form.date = moment(event.data.form.date).hours(old_date.hours()).minutes(old_date.minutes()).valueOf();
                    this.processFields();
                    this.post(this.form);
                } else {
                    resolve(this.form.date);
                }
                event.close();
            });
        });
    }

    public setPeriod() {
        const {date, duration} = this.form;
        const min_duration = 30;
        const ref = this._dialog.open(TimeSelectModalComponent, {
            data: { date, duration, min_duration },
        });
        const res = ref.afterClosed().toPromise().then(
            result => {
                this.form = {...this.form, ...result};
                this.processFields();
                this.post(this.form);
            }
        );

        // Old implementation
        // const date = moment(this.form.date);
        // const time = date.format('HH:mm');
        // const end_time = date.add(this.form.duration || 60, 'm').format('HH:mm');
        // this.service.Overlay.openModal('old-time-period', { data: {
        //     start: time,
        //     end: end_time
        // } }, (event) => {
        //     if (event.type === 'Accept') {
        //         const d = moment(this.form.date);
        //         const parts = event.data.form.start.split(':');
        //         d.hours(parts[0]).minutes(+parts[1]);
        //         this.form.date = d.valueOf();
        //         this.form.duration = event.data.form.duration;
        //         this.processFields();
        //         this.post(this.form);
        //     }
        //     event.close();
        // });
    }
}
