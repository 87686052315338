
import { Component, OnInit } from '@angular/core';
import { PointOfInterest } from '@acaprojects/ngx-widgets';

import { AppService } from '../../../services/app.service';

interface IDeskDetails {
    id: string;
    name: string;
    username?: string;
    reserved_by?: string
}

@Component({
    selector: 'desk-info',
    templateUrl: './desk-info.template.html',
    styleUrls: ['./desk-info.styles.scss']
})
export class DeskInfoComponent implements OnInit {
    /** Whether component should be displayed */
    public display: boolean;
    /** Map overlay metadata */
    public context: PointOfInterest;
    /** Whether the user data is loading */
    public loading: boolean;
    /** Desk data */
    public desk: IDeskDetails;

    constructor(context: PointOfInterest, private service: AppService) {
        this.context = context;
    }

    public ngOnInit(): void {
        this.loading = true;
        if (this.service) {
            const mod = this.service.Systems.getModule(this.context.data.system, 'DeskManagement');
            if (mod) {
                mod.exec('desk_details', [this.context.data.desk_id]).then((result) => {
                    this.desk = result[0];
                    if (this.desk) {
                        const name = (this.desk.username || this.desk.reserved_by || '').split('_');
                        const username = name[name.length - 1];
                        if (username) {
                            this.desk.name = username.split('.').join(' ');
                        }
                    }
                    this.loading = false;
                }, () => this.display = false);
            }
        } else {
            this.display = false;
        }
    }
}
