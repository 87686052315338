
import { Component } from '@angular/core';

import { BaseComponent } from '../../shared/components/base.component';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.template.html',
    styleUrls: ['./terms.styles.scss']
})
export class TermsComponent extends BaseComponent {
    public model: any = {};

    constructor(private service: AppService) {
        super();
    }
}
