
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'item-select-model',
    templateUrl: './item-select-modal.template.html',
    styleUrls: ['./item-select-modal.styles.scss']
})
export class ItemSelectModalComponent extends OverlayContentComponent {

    public init() {
        if (!this.model.options) {
            this.model.options = [];
        }
    }

    public select(item: any) {
        this.model.selected = this.model.options.indexOf(item);
        this.fn.event('Select');
    }

}
