<ng-container *ngIf="form">
    <div class="recurrence-field" [formGroup]="form">
        <div class="header" [class.active]="show">
            <div class="bar"></div>
            <div class="title" (touchrelease)="show = !show">
                    <div class="text">{{ field.label }}</div>
                    <div class="icon"><i class="material-icons">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</i></div>
            </div>
            <div class="bar"></div>
        </div>
        <div class="body" [@show]="show ? 'show' : 'hide'">
            <div class="group">
                <label [for]="field.key + '-period'">Period</label>
                <div class="value">
                    <dropdown [id]="field.key + '-period'" klass="form" [list]="period_options" [(model)]="details.period" (modelChange)="setValue(details)"></dropdown>
                </div>
            </div>
            <div class="group" *ngIf="details.period > 0">
                <label [for]="field.key + '-end'">End Date</label>
                <div class="value">
                    <div class="date-field" (touchrelease)="show_tooltip = !show_tooltip" tooltip [(show)]="show_tooltip" [template]="calendar" [triangle]="false" position="top" offset="middle">
                        <div class="text">{{date || 'Never'}}</div>
                        <div class="icon">
                            <i class="material-icons">{{ show_tooltip ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
                        </div>
                    </div>
                    <ng-template #calendar>
                        <div>
                            <a-date-picker [(ngModel)]="details.end" [options]="{ from: before }" (ngModelChange)="setValue(details)" [id]="field.key + '-end'"></a-date-picker>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
