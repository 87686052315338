<div class="container">
    <div class="search" *ngIf="model.external && !model.edit && search">
        <div class="input">
            <input
                [(ngModel)]="model.search"
                (ngModelChange)="find()"
                name="search_user"
                placeholder="Search for existing user by name or email"
                (focus)="model.focus = true"
                autocomplete="off"
                (blur)="blur()"
            />
        </div>
        <div class="list" [class.show]="model.search || model.focus || model.selected_user">
            <ng-container *ngIf="model.results && !model.searching">
                <div
                    class="item"
                    *ngFor="let item of model.results"
                    [class.fade]="model.selected_user && !item.selected"
                    (touchrelease)="selectUser(item)"
                >
                    <div class="details">
                        <div class="grp">
                            <div class="name">{{ item.name }}</div>
                            <div class="minor">{{ item.org }}</div>
                        </div>
                        <div class="minor" *ngIf="item.phone">{{ item.phone }}</div>
                        <div class="minor" *ngIf="item.email">{{ item.email }}</div>
                    </div>
                    <div class="add">
                        <div class="added-circle" *ngIf="item.selected">
                            <i center class="material-icons">done</i>
                        </div>
                        <btn
                            [name]="item.selected ? 'error' : model.selected_user ? 'pending' : 'success'"
                            format="mini-action"
                            [class.error]="item.selected"
                            (tap)="selectUser(item)"
                        >
                            <i class="material-icons">{{ item.selected ? 'close' : 'add' }}</i>
                        </btn>
                    </div>
                </div>
            </ng-container>
            <div class="no-item" center *ngIf="!model.results || model.results.length <= 0 || model.searching">
                <div class="icon">
                    <i class="material-icons" *ngIf="!model.searching">people</i>
                    <spinner type="circle-ring" *ngIf="model.searching" color="rgba(0,0,0, .2)"></spinner>
                </div>
                <div class="text">{{ model.searching ? 'Searching for user...' : 'No users matching query' }}</div>
            </div>
        </div>
    </div>
    <div class="form" [class.hide]="model.search || model.focus || model.selected_user">
        <!-- <div class="field" *ngIf="model.org && model.org.list && (model.org.list.length > 0 || model.can_add_org)">
            <div class="label">Organisation<span>*</span></div>
            <div class="value no-border">
                <btn format="mini-action" *ngIf="model.can_add_org" (tapped)="model.org.active = model.org.list.length; updateOrg()">
                    <i class="material-icons">add</i>
                </btn>
                <div class="input">
                    <dropdown [list]="model.org.name" [(model)]="model.org.active" [filter]="true" (modelChange)="updateOrg()" [placeholder]="model.org.active === model.org.list.length ? 'New Organisation' : 'Select Organisation'"></dropdown>
                </div>
            </div>
        </div>
        <div class="input-error" *ngIf="model.error && model.error.org">{{model.error.org.msg}}</div> -->
        <div class="field">
            <div class="label">Organisation<span>*</span></div>
            <div class="value" tooltip [(show)]="model.show_org_tooltip" [template]="tooltip" [triangle]="false">
                <input
                    name="organisation"
                    type="text"
                    [(ngModel)]="model.form.organisation"
                    (ngModelChange)="updateOrganisationTypeahead(); post()"
                    placeholder="Organistation name"
                />
                <ng-template #tooltip>
                    <div class="orgs list">
                        <div
                            class="item"
                            *ngFor="let item of (model.filtered_orgs | slice: 0:5)"
                            [innerHTML]="item.match_name || item.name | safe"
                            (touchrelease)="setOrg(item)"
                        ></div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="input-error" *ngIf="model.error && model.error.org_name">{{ model.error.org_name.msg }}</div>
        <div class="field">
            <div class="label">First name<span>*</span></div>
            <div class="value">
                <input
                    name="given_name"
                    type="text"
                    [(ngModel)]="model.form.first_name"
                    (ngModelChange)="post()"
                    placeholder="First name"
                    autocomplete="given-name"
                />
            </div>
        </div>
        <div class="input-error" *ngIf="model.error && model.error.first_name">{{ model.error.first_name.msg }}</div>
        <div class="field">
            <div class="label">Last name<span>*</span></div>
            <div class="value">
                <input
                    name="family_name"
                    type="text"
                    [(ngModel)]="model.form.last_name"
                    (ngModelChange)="post()"
                    placeholder="Last name"
                    autocomplete="family-name"
                />
            </div>
        </div>
        <div class="input-error" *ngIf="model.error && model.error.last_name">{{ model.error.last_name.msg }}</div>
        <div class="field">
            <div class="label">Email<span>*</span></div>
            <div class="value">
                <input
                    name="email"
                    type="email"
                    [(ngModel)]="model.form.email"
                    (ngModelChange)="checkEmail(); post()"
                    placeholder="Email"
                    autocomplete="email"
                />
                <spinner *ngIf="model.checking_email" type="circle-ring"></spinner>
            </div>
        </div>
        <div class="input-error" *ngIf="model.error && model.error.email">{{ model.error.email.msg }}</div>
        <div class="field">
            <div class="label">Mobile</div>
            <div class="value">
                <input
                    name="mobile"
                    type="tel"
                    [(ngModel)]="model.form.phone"
                    (ngModelChange)="post()"
                    placeholder="Mobile number"
                    autocomplete="tel"
                />
            </div>
        </div>
    </div>
</div>
