<ng-container *ngIf="form">
    <div class="date-field" [class.error]="control?.errors" (touchrelease)="model.show_tooltip = !model.show_tooltip">
        <div class="text">{{model.display || 'No date'}}</div>
        <div class="icon">
            <i class="material-icons">{{ model.show_tooltip ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
        </div>
        <div class="hook" tooltip [(show)]="model.show_tooltip" [template]="calendar" [triangle]="false" position="bottom" offset="start"></div>
    </div>
    <ng-template #calendar>
        <div>
            <a-date-picker [ngModel]="model.value" [options]="{ from: model.today }" (ngModelChange)="setValue($event)" [id]="field.key"></a-date-picker>
        </div>
    </ng-template>
</ng-container>