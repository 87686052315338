
<div class="container" [class.route]="!modal && model.route">
    <div class="list" #list (scroll)="updateScroll()" *ngIf="model.user_list && model.user_list.length > 0">
        <div class="group" *ngFor="let group of model.user_groups">
            <div class="start" [id]="'user-letter-start-' + group.letter"></div>
            <div class="header" *ngIf="group.users.length">{{group.letter}}</div>
            <ng-container *ngFor="let item of group.users">
                <div class="item" *ngIf="!model.user || item.id !== model.user.id">
                    <div class="status" [style.opacity]="item.free === undefined ? 0 : 1" [class.free]="item.free"></div>
                    <div class="details">
                        <div class="name">{{item.name}} <span class="org">{{item.organisation_name}}</span></div>
                        <div class="role">{{item.role || item.email}}</div>
                        <!-- <div class="role" *ngIf="item.select"><checkbox [(model)]="item.optional" label="Optional"></checkbox></div> -->
                    </div>
                    <div class="add">
                        <div class="added-circle" *ngIf="item.select">
                            <i center class="material-icons">done</i>
                        </div>
                        <a [href]="'tel:' + item.clean_phone">
                            <btn name="black inverse" format="mini-action" *ngIf="item.phone">
                                <i class="material-icons">phone</i>
                            </btn>
                        </a>
                        <btn [name]="item.select ? 'error' : 'success'" format="mini-action" [class.error]="item.select"
                            (touchrelease)="item.select ? remove(item, true) : add(item)">
                            <i class="material-icons">{{item.select ? 'close' : 'add'}}</i>
                        </btn>
                    </div>
                </div>
            </ng-container>
            <div class="end" [id]="'user-letter-end-' + group.letter"></div>
        </div>
    </div>
    <div class="list mid" *ngIf="model.loading || (!model.user_list || model.user_list.length <= 0)">
        <div class="external" *ngIf="model.is_email && !model.can_add && model.external">
            <btn (tapped)="add({ id: search, name: model.name, email: search }, false, true)">Add external user</btn>
        </div>
        <div class="no-item">
            <div class="icon">
                <spinner *ngIf="model.loading" type="circle-ring" color="#999"></spinner>
                <i *ngIf="!model.loading" class="material-icons">{{search && search.length >= (model.min_search || 0) ? 'close' : 'keyboard'}}</i>
            </div>
            <div class="text">
                {{model.loading ? 'Searching...' : (search && search.length >= (model.min_search || 0) ? 'No matches found' : 'Type above to search for users')}}
            </div>
        </div>
    </div>
    <div class="letter-list">
        <div class="letter" *ngFor="let group of model.user_groups"
            [class.disabled]="group.users.length <= 0" [class.active]="group.letter === model.location"
            (touchrelease)="scrollTo(group.letter)">
            {{group.letter}}
        </div>
    </div>
</div>
