
import { CustomDateFieldComponent } from './date-field/date-field.component';
import { CustomTimeFieldComponent } from './time-field/time-field.component';
import { CustomDurationFieldComponent } from './duration-field/duration-field.component';
import { CustomUserListFieldComponent } from './user-list-field/user-list-field.component';
import { CustomWeekFieldComponent } from './week-field/week-field.component';
import { CustomTermsFieldComponent } from './terms-field/terms-field.component';
import { CustomPreferenceFieldComponent } from './preference-field/preference-field.component';
import { CustomCostFieldComponent } from './cost-field/cost-field.component';
import { CustomPayTypeFieldComponent } from './pay-type-field/pay-type-field.component';
import { CustomRecurrenceFieldComponent } from './recurrence-field/recurrence-field.component';

export const CUSTOM_FORM_FIELDS: any[] = [
    CustomDateFieldComponent,
    CustomTimeFieldComponent,
    CustomDurationFieldComponent,
    CustomUserListFieldComponent,
    CustomWeekFieldComponent,
    CustomTermsFieldComponent,
    CustomPreferenceFieldComponent,
    CustomCostFieldComponent,
    CustomRecurrenceFieldComponent,
    CustomPayTypeFieldComponent
];
