
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { WidgetsModule, DynamicFormComponentModule } from '@acaprojects/ngx-widgets';

import { BaseComponent } from './base.component';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { BuildingSelectComponent } from './building-select/building-select.component';
import { CardComponent } from './card/card.component';
import { LOGIN_COMPONENTS } from './login';
import { RoomInfoComponent } from './room-info/room-info.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UserFormComponent } from './user-form/user-form.component';
import { USER_SEARCH_COMPONENTS } from './user-search';
import { VisitorFormComponent } from './visitor-form/visitor-form.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { DeskInfoComponent } from './desk-info/desk-info.component';
import { UserAvailabilityComponent } from './user-availability/user-availability.component';
import { AvatarComponent } from './avatar/avatar.component';
import { UserTagComponent } from './user-tag/user-tag.component';
import { TermsDisplayComponent } from './terms-display/terms-display.component';
import { CUSTOM_FORM_FIELDS } from './custom-fields';

import { ADatePickerModule } from '@acaprojects/ngx-date-picker';
import { TimeFieldComponent } from './time-field.component';
import { DurationFieldComponent } from './duration-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { DaySelectFieldComponent } from './day-select-field.component';

const COMPONENTS: any[] = [
    BaseComponent,
    AvatarComponent,
    BookingFormComponent,
    BuildingSelectComponent,
    CardComponent,
    ...LOGIN_COMPONENTS,
    SearchbarComponent,
    TimelineComponent,
    UserFormComponent,
    ...USER_SEARCH_COMPONENTS,
    VisitorFormComponent,
    UserAvailabilityComponent,
    UserTagComponent,
    TermsDisplayComponent,
    TimeFieldComponent,
    DurationFieldComponent,
    DaySelectFieldComponent
];

const ENTRY_COMPONENTS: any[] = [
    RoomInfoComponent,
    DeskInfoComponent,
    ...CUSTOM_FORM_FIELDS
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS
    ],
    imports: [
        CommonModule,
        FormsModule,
        WidgetsModule,
        ReactiveFormsModule,
        ScrollingModule,
        DynamicFormComponentModule,
        ADatePickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule
    ],
    exports: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        DynamicFormComponentModule,
        ADatePickerModule
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ]
})
export class SharedComponentModule { }
