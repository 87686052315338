import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'room-select-modal',
    templateUrl: './room-select-modal.template.html',
    styleUrls: ['./room-select-modal.styles.scss'],
})
export class RoomSelectModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.updateList();
    }

    public updateList() {
        const date = moment(this.model.date || null);
        this.service.Rooms.available({
            date: date.valueOf(),
            duration: this.model.duration || 60
        }).then((list) => {
            this.model.rooms = list;
            this.model.rooms.sort((a, b) => a.level.name.localeCompare(b.level.name) || a.name.localeCompare(b.name));
        });
    }

    public select(item) {
        this.model.selected = item;
        this.event('Select');
    }
}
