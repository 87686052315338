
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';
import { IBannerSettings } from '../page-banner/page-banner.component';

@Component({
    selector: 'topbar-header',
    templateUrl: './topbar-header.template.html',
    styleUrls: ['./topbar-header.styles.scss']
})
export class TopbarHeaderComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        this.init();
        this.subs.obs.router_events = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.logo = this.service.Settings.get('app.logo') || {};
        this.model.menu = this.service.Settings.get('app.menu') || {};
        this.model.menu_keys = !!this.model.menu.type;
        this.model.banner = this.service.Settings.get('app.banner');
        this.model.page = this.service.Settings.get('app.page') || { titles: {}, info: {} };
        this.model.hide_heading = this.service.Settings.get('app.hide.heading');
        this.model.route_settings = this.service.Settings.get('app.route_settings') || {};
        this.model.user = this.service.Users.current();
        this.model.bld_count = (this.service.Buildings.list() || []).length;
        this.checkRoute();
    }

    public logo() {
        if (this.model.logo && this.model.logo.link) {
            location.href = this.model.logo.link;
        } else {
            this.service.navigate('');
        }
    }

    public checkRoute() {
        this.timeout('route', () => {
            const route = this.router.url.split('?')[0];
            this.model.is_home = (route === '/' || route === '/home' || route === '/help') && (!this.model.banner || !this.model.banner.allow_image_on_home);
            if (this.model.tiles) {
                for (const tile of this.model.tiles) {
                    tile.active = route.indexOf(`/${tile.id}`) === 0;
                }
            }
            if (this.model.page) {
                    // Get page heading
                this.model.heading = this.getRouteDetails(this.model.page.titles, route);
                this.service.title = this.model.heading || 'Home';
                    // Get page banner info
                this.model.info = this.getRouteDetails(this.model.page.info, route);
                this.model.info = this.model.info.replace(/{{user}}/g, this.model.user ? this.model.user.name || 'Guest' : 'Guest');
            }
            this.service.set('APP.background', '');
            if (route && route !== '/' && route !== '/home') {
                    // Load banner settings for route
                const parts = route.substr(1).split('/');
                this.model.banner.page = this.service.Settings.get(`app.${this.model.route_settings[parts[0]] || parts[0]}.banner`);
                if (!this.model.banner.page) {
                    this.model.banner.page = (this.service.Settings.get('app.banner') || { enabled: false }) as IBannerSettings;
                }
                if (this.model.banner.page.enabled !== false && this.model.banner.page.fullback) {
                    this.service.set('APP.background', this.model.banner.page.background || this.model.banner.background || '');
                }
            } else {
                    // Disable banner when no route or home page
                this.model.banner.page = { enabled: false } as IBannerSettings;
            }

        }, 50);
    }

    public home() {
        this.service.navigate('');
    }

    private getRouteDetails(map: any, route: string) {
        let current_route = '';
        let current_value = '';
        const keys = Object.keys(map || {});
        for (const id of keys) {
            if ((route.indexOf(`/${id}`) === 0 || route.indexOf(id) === 0) && id.length > current_route.length) {
                current_value = map[id];
                current_route = id;
            }
        }
        return current_value || '';
    }
}
