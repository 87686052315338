<div class="container" *ngIf="model.banner && ((model.banner.enabled && model.banner.page && model.banner.page.enabled !== false) || (model.banner.header && heading && heading !== 'Home') || model.blk_height)"
    [style.background-image]="!model.banner.full || model.is_home ? 'url(' + (model.banner.page ? model.banner.page.background || model.banner.background : model.banner.background) + ')' : ''">
    <div class="overlay" *ngIf="!model.banner.full" [style.background-color]="model.banner.page ? model.banner.page.color : model.banner.color"></div>
    <div class="heading" *ngIf="model.banner.header && heading && heading !== 'Home'" [class.margin]="model.banner.page && model.banner.page.links && model.banner.page.links.length > 0">
        {{heading}}
    </div>
    <div class="info" *ngIf="info">
        {{info}}
    </div>
    <div class="btns" *ngIf="model.banner.page && model.banner.page.links">
        <btn [name]="'banner '" *ngFor="let btn of model.banner.page.links" (tapped)="goto(btn)">{{btn.name}}</btn>
    </div>
    <div class="block" [style.height]="(model.blk_height || 0) + 'em'"></div>
</div>