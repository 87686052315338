<div class="container">
    <div class="form-container" *ngIf="!model.page">
        <visitor-form></visitor-form>
    </div>
    <div class="result" *ngIf="model.page === 'result' && model.booking">
        <div class="icon">
            <i class="material-icons">done</i>
        </div>
        <div class="heading">
            Visitor Booking Details
        </div>
        <div class="field-list">
            <div class="field">
                <div class="label">
                    Booked by
                </div>
                <div class="value">{{ model.display?.organiser}}</div>
            </div>
            <div class="field">
                <div class="label">Location</div>
                <div class="value">{{model.booking?.location}}</div>
            </div>
            <div class="field">
                <div class="label">Date</div>
                <div class="value">{{model.display?.date}}</div>
            </div>
            <div class="field">
                <div class="label">Period</div>
                <div class="value">{{model.display?.duration}}</div>
            </div>
            <div class="field">
                <div class="label">Attendees</div>
                <div class="value">{{model.display?.attendees}}</div>
            </div>
        </div>
        <div class="back">
            <btn (tapped)="onConfirm()">Confirm</btn>
        </div>
    </div>
</div>
