<div class="cntnr" [style.height]="model.vh + 'px'">
    <div class="header">
        {{model.user ? model.user.name + ' details' : (model.edit ? 'Edit Contact' : 'New Contact') }}
    </div>
    <form #user_details *ngIf="!model.loading">
        <div class="body">
            <div class="details" *ngIf="model.user">
                <div class="field">
                    <div class="label">Name:</div>
                    <div class="value">{{model.user.name}}</div>
                </div>
                <div class="field" *ngIf="model.user.organisation">
                    <div class="label">Organisation:</div>
                    <div class="value">{{model.user.organisation?.name}}</div>
                </div>
                <div class="field">
                    <div class="label">Email:</div>
                    <div class="value">{{model.user.email}}</div>
                </div>
                <div class="field">
                    <div class="label">Mobile:</div>
                    <div class="value">{{model.user.phone || model.user.mobile}}</div>
                </div>
            </div>
            <div class="form" *ngIf="!model.user">
                <user-form [(form)]="model.form" [search]="false" [visitors]="!model.backend" [edit]="model.edit"></user-form>
            </div>
        </div>
        <div class="footer">
            <btn name="modal" *ngIf="model.user">Edit</btn>
            <btn name="modal" *ngIf="!model.user && model.edit"
                [disabled]="!model.form || (!model.form.email && (!model.form.users || model.form.users.length <= 0))"
                (tapped)="update()">
                Save
            </btn>
            <btn name="modal" *ngIf="!model.user && !model.edit"
                [disabled]="!model.form || (!model.form.email && (!model.form.users || model.form.users.length <= 0))"
                (tapped)="add(false)">
                Add
            </btn>
            <btn name="modal" *ngIf="!model.user && !model.edit"
                [disabled]="!model.form || (!model.form.email && (!model.form.users || model.form.users.length <= 0))"
                (tapped)="add()">
                Add &amp; Finish
            </btn>
        </div>
    </form>
    <div class="no-item" center *ngIf="model.loading">
        <div class="icon">
            <i class="material-icons" *ngIf="!model.processing">done</i>
            <spinner type="circle-ring" color="rgba(0,0,0, .2)" *ngIf="model.processing"></spinner>
        </div>
        <div class="text">
            {{model.processing ? 'Processing request...' : (model.edit ? 'Updated user' : 'Successfully added user')}}
        </div>
    </div>
    <div class="close" *ngIf="!model.processing || !model.loading" (touchrelease)="fn.close()">
        <i class="material-icons">close</i>
    </div>
</div>
