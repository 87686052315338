<div class="container">
    <div class="logo" [style.background]="(model.logo ? model.logo.background : '') | safestyle">
        <img *ngIf="model.logo && (model.logo.type==='image' || model.logo.type==='img')" alt="Logo" [src]="model.logo.src">
        <i *ngIf="model.logo && (model.logo.type!=='image' && model.logo.type!=='img')" [class]="model.logo.src"></i>
    </div>
    <div class="header">
        Terms &amp; Conditions
    </div>
    <div class="body">
        <ng-container>
            <div class="item" *ngFor="let item of model.terms">
                <div class="heading">{{item.title}}</div>
                <div class="content" [innerHTML]="item.details"></div>
            </div>
        </ng-container>
    </div>
</div>
