import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'date-period-modal',
    templateUrl: './date-period-modal.template.html',
    styleUrls: ['./date-period-modal.styles.scss'],
})
export class DatePeriodModalComponent extends OverlayContentComponent {
    public model: any = { form: {} };

    public init() {
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.form = {};
        this.model.form.start = this.model.start || moment().valueOf();
        this.model.form.end = this.model.end || moment().valueOf();
        this.model.limit = this.service.Settings.get('app.booking.limit') || 6;
        this.model.end = moment().add(this.model.limit, 'M').valueOf();
        this.model.today = moment();
        this.updateDisplay();
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        setTimeout(() => this.fn.event(name), 300);
    }

    public updateStart() {
        const form = this.model.form;
        form.end++;
        if (!form.end || form.end < form.start) {
            form.end = form.start;
        }
        this.updateDisplay();
    }

    public updateEnd() {
        const form = this.model.form;
        if (!form.end || form.end < form.start) {
            form.end = form.start;
        }
        this.updateDisplay();
    }

    public updateDisplay() {
        const start = moment(this.model.form.start);
        const end = moment(this.model.form.end);
        this.model.start_display = start.format('ddd DD MMMM');
        this.model.end_display = end.format('ddd DD MMMM YYYY');
        const duration = Math.floor(moment.duration(end.diff(start)).asDays() + 1);
        this.model.duration = `${duration} day${duration === 1 ? '' : 's'}`;
    }
}
