
import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'payment-card',
    templateUrl: './card.template.html',
    styleUrls: ['./card.styles.scss'],
})
export class CardComponent implements OnChanges {
    @Input() public number = '';
    @Input() public name = '';
    @Input() public brand = '';
    @Input() public expiry = '';
    @Input() public cvv = '';
    @Input() public type = 'visa';
    @Input() public back = false;

    public number_parts: string[] = [];
    public flip = false;
    public show_back = false;

    public ngOnChanges(changes: any) {
        if (changes.number) {
            this.updateNumber();
        }
        if (changes.back) {
            this.flipCard();
        }
    }

    public updateNumber() {
        if (this.number) {
            this.number_parts = this.number.split('-');
        }
    }

    private flipCard() {
        this.flip = true;
        setTimeout(() => {
            this.show_back = this.back;
            setTimeout(() => this.flip = false, 250);
        }, 250);
    }
}
