import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';

@Component({
    selector: 'custom-pay-type-field',
    templateUrl: './pay-type-field.component.html',
    styleUrls: ['./pay-type-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ]),
    ],
})
export class CustomPayTypeFieldComponent
    extends BaseComponent
    implements ICustomField<boolean>, OnDestroy
{
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<boolean>;

    public use_card: boolean;

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.init();
        }
        if (this.is_disabled) this.setValue(false);
    }

    public get is_disabled() {
        return this.form.get('room')?.value?.rate === 0;
    }

    public init() {
        this.updateDisplay();
        if (this.is_disabled) this.setValue(false);
    }

    public updateDisplay() {
        this.use_card = !!this.control.value;
    }

    public setValue(value: boolean) {
        this.control.setValue(value);
    }
}
