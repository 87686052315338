
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-wifi',
    templateUrl: './wifi.template.html',
    styleUrls: ['./wifi.styles.scss']
})
export class WifiComponent extends BaseComponent implements OnInit {

    public model: any = {};

    constructor(private service: AppService, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit() {
        this.subs.obs.query = this.route.queryParamMap.subscribe((params) => {

        });
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.settings = this.service.Settings.get('app.wifi');
        this.service.Analytics.screen('WiFi');
        this.service.Analytics.page('/wifi');
    }

}
