<div class="container" [class.shadow]="model.banner && model.banner.full && !model.is_home" [class.border]="model.banner && model.banner.page && model.banner.page.fullback">
    <div class="top-section" [style.background]="model.banner && model.banner.full && !model.is_home ? (model.banner.page ? model.banner.page.background || model.banner.background : model.banner.background) : ''"
        [style.background-image]="model.banner && model.banner.full && !model.is_home  ? 'url(' + (model.banner.page ? model.banner.page.src || model.banner.src  : model.banner.src) + ')' : ''">

        <div class="overlay" *ngIf="model.banner && model.banner.full && !model.is_home" [style.background-color]="model.banner.page ? model.banner.page.color : model.banner.color"></div>
        <div class="topbar" *ngIf="model.menu_keys" [class.reverse]="model.menu.reverse" [class.full]="model.banner && model.banner.full && !model.is_home">
            <div class="block">
                <div *ngIf="!model.menu.home" class="logo" (touchrelease)="logo()" [style.background]="(model.logo ? model.logo.background : '') | safestyle">
                    <img *ngIf="model.logo.type==='image' || model.logo.type==='img'" alt="Logo" [src]="model.banner && model.banner.full && !model.is_home ? model.logo.inverse || model.logo.src : model.logo.src">
                    <i *ngIf="model.logo.type!=='image' && model.logo.type!=='img'" [class]="model.banner && model.banner.full && !model.is_home ? model.logo.inverse || model.logo.src : model.logo.src"></i>
                </div>
                <div class="icon" *ngIf="model.menu.home" (touchrelease)="home()">
                    <i class="material-icons">home</i>
                </div>
            </div>
            <div class="heading" *ngIf="((!model.banner || !model.banner.header) && !model.hide_heading) || model.menu.home">
                <div *ngIf="!model.menu.home && !model.hide_heading && (!model.banner || !model.banner.header)">{{model.heading}}</div>
                <div *ngIf="model.menu.home" class="logo" (touchrelease)="logo()" [style.background]="(model.logo ? model.logo.background : '') | safestyle">
                    <img *ngIf="model.logo.type==='image' || model.logo.type==='img'" alt="Logo" [src]="model.banner && model.banner.full && !model.is_home ? model.logo.inverse || model.logo.src : model.logo.src">
                    <i *ngIf="model.logo.type!=='image' && model.logo.type!=='img'" [class]="model.banner && model.banner.full && !model.is_home ? model.logo.inverse || model.logo.src : model.logo.src"></i>
                </div>
            </div>
            <div class="block menu">
                <div [class]="'menu-icon ' + model.menu.type" *ngIf="model.menu && model.menu.enabled !== false"
                    [class.show]="model.menu.show" (touchrelease)="model.menu.show = !model.menu.show">
                    <div class="bar one"></div>
                    <div class="bar two"></div>
                    <div class="bar three"></div>
                </div>
                <i class="material-icons" *ngIf="!model.menu && model.bld_count > 1" (touchrelease)="model.show_building = true">location_city</i>
                <i class="material-icons" *ngIf="!model.menu" (touchrelease)="home()">home</i>
            </div>
        </div>
        <div class="banner">
            <page-banner [heading]="model.heading" [info]="model.info"></page-banner>
        </div>
    </div>
    <div class="route">
        <routing-breadcrumbs></routing-breadcrumbs>
    </div>
    <div class="menu-overlay">
        <overlay-menu [(show)]="model.menu.show" (building)="model.show_building = true"></overlay-menu>
    </div>
    <div class="bld">
        <building-select *ngIf="model.show_building" (event)="model.show_building = false"></building-select>
    </div>
</div>
