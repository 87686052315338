/**
 * @Author: Alex Sorafumo
 * @Date:   17/10/2016 4:10 PM
 * @Email:  alex@yuion.net
 * @Filename: simple.component.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 01/02/2017 1:37 PM
 */

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AppService } from '../services/app.service';
import { BaseComponent } from '../shared/components/base.component';

import * as moment from 'moment';

@Component({
    selector: 'app-shell',
    styleUrls: ['./shell.styles.scss'],
    templateUrl: './shell.template.html'
})
export class AppShellComponent extends BaseComponent implements OnInit {

    public model: any = {};
    public timers: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
        this.model.routing = {};
    }

    public ngOnInit() {
        this.subs.obs.router = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) { this.checkRoute(); }
        });
        this.model.year = moment().format('YYYY');
        this.subs.obs.user = this.service.Users.listen('user', (user) => this.model.user = user);
        this.init();
    }

    public init() {
        this.model.loading = true;
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 200);
        }
        this.model.loading = false;
        this.model.tiles = this.service.Settings.get('app.tiles');
        this.model.unlicensed = this.service.Settings.get('unlicensed');
        this.subs.obs.background = this.service.listen('APP.background', (bg) => {
            this.model.background = bg;
        });
        this.subs.obs.debug = this.service.listen('APP.enable_debug_options', (state) => this.model.debugging = state);
        if (typeof this.model.unlicensed === 'string') {
            this.model.msg = this.model.unlicensed;
        }
        if (!this.model.msg) {
            this.model.msg = 'This product has not been paid for. Please contact Administrator to fix this';
        }
        this.checkRoute();
        this.initBuilding();
    }

    public home() {
        this.service.navigate('');
    }

    public initBuilding() {
        const list = this.service.Buildings.list();
        if (!list || list.length <= 0) {
            return setTimeout(() => this.initBuilding(), 500);
        }
        this.model.bld_count = list.length;
        let bld = '';
        if (localStorage) {
            bld = localStorage.getItem('STAFF.building');
        }
        this.model.show_building = !bld && this.model.bld_count > 1;
        this.service.Buildings.listen((building) => this.model.bld = building);
    }

    public route() {
        if (this.model.routing.sub) {
            this.service.navigate(this.model.routing.back);
        }
    }

    public startDebug() {
        console.debug('Enabling debug options');
        this.timeout('start_debug', () => {
            console.debug('Enabled debug options');
            this.service.set('APP.enable_debug_options', true);
        }, 10 * 1000);
    }

    public cancelDebug() {
        console.debug('Cancel debug options');
        this.clearTimer('start_debug');
    }

    public checkRoute() {
        if (this.router.url.indexOf('logout') >= 0) {
            this.service.logout();
        }
        const route = this.router.url.split('?')[0];
        if (this.model.menu) {
            this.model.menu.show_footer = this.model.hide_routes.indexOf(route) < 0;
        }
        if (this.model.banner) {
            this.model.banner.show = this.model.hide_routes.indexOf(route) < 0;
        }
        if (this.model.tiles) {
            for (const tile of this.model.tiles) {
                tile.active = route.indexOf(`/${tile.id}`) === 0;
                if (tile.active && this.model.banner) {
                    this.model.explore = tile.id.indexOf('explore') >= 0 || route.indexOf('book/room') >= 0;
                    this.model.banner.color = this.model.banner.color || tile.color !== '#fff' ? tile.color : '';
                    this.model.banner.page = {
                        links: this.service.Settings.get(`app.${tile.settings}.banner.links`) || this.model.banner.links || []
                    };
                }
            }
        }

    }

    public toggleMenu(e, state: boolean = true) {
        if (this.model.menu && !this.model.explore) {
            if (state && window.innerWidth / 8 * 7 < e.center.x + -e.deltaX) {
                this.model.menu.show = state;
            } else if (!state && window.innerWidth / 8 > e.center.x + -e.deltaX) {
                this.model.menu.show = state;
            }
        }
    }
}
