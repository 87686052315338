
import { Component } from '@angular/core';

@Component({
    selector: 'forgot-pass-form',
    templateUrl: './forgot-pass-form.template.html',
    styleUrls: ['./forgot-pass-form.styles.scss']
})
export class ForgotPassFormComponent {

}

