<div class="container">
    <div class="header">
        <btn name="black inverse" *ngIf="model.phone" (tapped)="call()">Call</btn>
        <div class="search">
            <div class="icon" (touchrelease)="focusSearch()">
                <i class="material-icons">search</i>
            </div>
            <div class="details" [class.focus]="model.search || model.focus_search">
                <div class="input">
                    <input #search [(ngModel)]="model.search" (ngModelChange)="filter()" (blur)="model.focus_search = false" placeholder="Filter messages">
                </div>
                <div class="results" *ngIf="model.search && model.fltrd_msgs && model.fltrd_msgs.length > 0">
                    {{(model.active || 0) + 1}}/{{model.fltrd_msgs.length}}
                </div>
                <div class="icon" *ngIf="model.search" (touchrelease)="prevActive()">
                    <i class="material-icons">keyboard_arrow_up</i>
                </div>
                <div class="icon" *ngIf="model.search" (touchrelease)="nextActive()">
                    <i class="material-icons">keyboard_arrow_down</i>
                </div>
                <div class="icon close" (touchrelease)="clearFilter()">
                    <i class="material-icons">close</i>
                </div>
            </div>
        </div>
    </div>
    <div class="body" #body (scroll)="updateScroll()">
        <div class="list">
            <div class="item" #message *ngFor="let msg of model.messages; let i = index" [id]="'chat-msg-' + msg.id" [class.other]="msg.other">
                <div class="content" [class.disabled]="msg.disable" [class.selected]="model.match == i && model.search">
                    <div class="message">{{msg.content || '=== No Content ==='}}</div>
                    <div class="msg-footer">
                        <div class="user">{{msg.user.name || (!msg.other ? 'Concierge' : 'You')}}</div>
                        <div class="gap"></div>
                        <div class="timestamp">{{msg.time}}</div>
                    </div>
                    <div class="status" *ngIf="(!msg.other && !msg.read) || (msg.other && msg.read)" [class.read]="msg.read">
                        <i class="material-icons">{{ msg.read ? 'visibility' : 'new_releases' }}</i>
                    </div>
                </div>
            </div>
            <div class="no-item" center *ngIf="!model.messages || model.messages.length <= 0">
                <div class="icon">
                    <i class="material-icons">question_answer</i>
                </div>
                <div class="text">No messages</div>
                <div class="info">Start typing below to start a conversion</div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="messaging" [class.focus]="model.focus">
            <div class="input">
                <input #input [(ngModel)]="model.msg" (keyup.enter)="post()" (focus)="model.focus = true" (blur)="model.focus = false" placeholder="Message">
            </div>
            <div class="icon" [class.disabled]="!model.msg" (touchrelease)="post()" click-responder>
                <i class="material-icons">send</i>
            </div>
        </div>
        <div class="to-bottom">
            <div *ngIf="!model.bottom" [@appear]>
                <btn name="black" format="mini-action" (tapped)="scrollBottom()">
                    <i class="material-icons">arrow_downward</i>
                </btn>
            </div>
        </div>
    </div>
</div>