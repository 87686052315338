
import * as moment from 'moment';

import { IRoom } from '../../../services/data/rooms.service';
import { IUser } from '../../../services/data/users.service';
import { Utils } from '../../utility.class';

export class FormFormatters {
    public static space(list: IRoom | IRoom[]) {
        if (!list) { return null; }
        if (list instanceof Array) {
            return `${list.length} Spaces`;
        } else {
            const capacity = list.capacity ? '(' + list.capacity + ' ' + (list.capacity === 1 ? 'person' : 'people') + ')' : '';
            return `${list.name} ${capacity}`;
        }
    }

    public static attendees(user: IUser) {
        return (list: IUser[]) => {
            let attendee_str: string;
            if (list && list.length > 0) {
                const users = [...list];
                const result = users.find((a) => a.email === user.email);
                if (result) {
                    users.splice(users.indexOf(result), 1);
                }
                attendee_str = `${users.length + 1} Attendee${users.length + 1 === 1 ? '' : 's'}; ${user.name}(Host)`;
                for (const item of users) {
                    if (attendee_str) { attendee_str += ', '; }
                    attendee_str += item.name;
                }
            } else {
                attendee_str = `1 Attendee; ${user.name}(Host)`;
            }
            return attendee_str;
        };
    }

    public static date(timestamp: number) {
        return moment(timestamp).format('Do MMMM YYYY');
    }

    public static time(timestamp: number) {
        return moment(timestamp).format('h:mm A');
    }

    public static period(duration: number) {
        return (timestamp: string) => {
            const parts = timestamp.split(':');
            const date = moment().hours(+parts[0]).minutes(+parts[1]);
            return `${date.format('h:mm A')} - ${date.add(duration, 'm').format('h:mm A')} (${Utils.humaniseDuration(duration)})`;
        };
    }

    public static duration(duration: number) {
        return Utils.humaniseDuration(duration);
    }

    public static catering(list: any[]) {
        return 'No catering';
    }
}
