<ng-container *ngIf="form">
    <div class="cost-field" [formGroup]="form">
        <div class="row">
            <div class="label">Rate:</div>
            <div class="value">{{rate / 100 | currency}}</div>
            <div class="info">per hour</div>
        </div>
        <div class="row">
            <div class="label">GST:</div>
            <div class="value">{{gst / 100 | currency}}</div>
            <div class="info"></div>
        </div>
        <div class="row">
            <div class="label">Total:</div>
            <div class="value">{{total / 100 | currency}}</div>
            <div class="info">inc. GST</div>
        </div>
    </div>
</ng-container>
