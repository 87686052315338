<div class="container">
    <div class="header">
        <div class="text">Booking period</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body">
        <a-date-picker klass="modal disabled" [(ngModel)]="model.form.start" (ngModelChange)="updateStart()" [options]="{ from: model.today }"></a-date-picker>
        <div class="divider"></div>
        <a-date-picker klass="modal" [(ngModel)]="model.form.end" (ngModelChange)="updateEnd()" [options]="{ from: model.form.start }"></a-date-picker>
    </div>
    <div class="footer">
        <div class="text">From <strong>{{model.start_display}}</strong> to <strong>{{model.end_display}}</strong> ({{model.duration}})</div>
        <btn klass="modal inverse" (tapped)="event('Cancel')">Cancel</btn>
        <btn klass="modal" (tapped)="event('Accept')">Ok</btn>
    </div>
</div>
