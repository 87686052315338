<div class="container">
    <form name="login">
        <div class="field">
            <div class="label">Email</div>
            <input name="Email" [(ngModel)]="form.user" [placeholder]="model.user_field || 'Email'">
        </div>
        <div class="field">
            <div class="label">Password<span>*</span></div>
            <input name="Password" [type]="model.show_pass ? 'text' : 'password'" (keyup.enter)="login()" [(ngModel)]="form.pass" [placeholder]="model.user_field || 'Password'">
        </div>
        <div class="forgot" *ngIf="!model.settings || model.settings.forgot !== false" (touchrelease)="forgot()">Forgot?</div>
        <div class="options">
            <btn type="submit" (tapped)="login()">Login</btn>
        </div>
    </form>
</div>