<div class="container">
    <div class="header">
        <div class="text">
            <div class="name">{{model.room?.name || 'View Room'}}</div>
            <div class="capacity" *ngIf="model.room && model.room.capacity">
                ({{model.room.capacity}} {{ model.room.capacity === 1 ? 'Person' : 'People' }})
            </div>
        </div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body" *ngIf="model.room && model.date">
        <div class="timeline">
            <timeline
                [now]="(model.date && model.date === 'Today')"
                [start]="model.start_time || 7"
                [end]="model.end_time || 19"
                [events]="(model.date && model.date === 'Today' ? model.room.today : model.room.timeline ? model.room.timeline[model.date] : []) || []"
                [booking]="model.booking ? model.booking : null">
            </timeline>
        </div>
        <div class="map">
            <map [src]="model.level?.map_url"
                [poi]="model.pins"
                [styles]="model.style"
                [lock]="model.lock"
                [focus]="{ id: 'area-' + model.room.map_id + '-status', zoom: 150 }">
            </map>
            <div class="level">
                {{model.level?.name}}
            </div>
        </div>
    </div>
</div>