import { Component, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';
import { AppService } from '../../../../services/app.service';
import { IUser } from '../../../../services/data/users.service';
import { Utils } from '../../../utility.class';

import * as moment from 'moment';

@Component({
    selector: 'custom-user-list-field',
    templateUrl: './user-list-field.component.html',
    styleUrls: ['./user-list-field.component.scss']
})
export class CustomUserListFieldComponent extends BaseComponent implements ICustomField<number>, OnDestroy {
    public control: AbstractControl;
    public ref_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<number>;

    public model: any = {};

    @ViewChild('file_input', { static: true }) private file_input: ElementRef;

    constructor(private service: AppService) {
        super();
        this.model.colours = [
            '#00ADEF', 'rgb(216, 27, 96)'];
    }

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.ref_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.ref_control.valueChanges.subscribe(() => this.updateDisplay());
            }
            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.updateDisplay();
        }
    }

    public search(filter) {
        this.timeout('search', () => {
            if (filter && filter.length >= 3) {
                if (!this.model.results) {
                    this.service.Users.query({ q: filter }).then((users) => {
                        this.model.result = users;
                        const group = [...this.model.result, this.service.Contacts.list()];
                        this.model.filtered = Utils.filter(filter, group, ['name', 'email']);
                        this.model.filtered.forEach(i => {
                            i.match_name = i.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                            i.match_name = i.match_name.replace(/\`/g, '');
                        });
                        this.model.show = true;
                    });
                } else {
                    const group = [...this.model.result, this.service.Contacts.list()];
                    this.model.filtered = Utils.filter(filter, group, ['name', 'email']);
                    this.model.filtered.forEach(i => {
                        i.match_name = i.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                        i.match_name = i.match_name.replace(/\`/g, '');
                    });
                    this.model.show = true;
                }
            } else {
                this.model.result = null;
            }
        });
    }

    public updateDisplay() {
        this.model.users = this.control.value || [];
        this.model.host = this.form.controls.host ? this.form.controls.host.value : this.service.Users.current();
    }

    public add(user: IUser | IUser[]) {
        if (!user || (user instanceof Array && user.length <= 0)) { return; }
        let users = this.control.value || [];
        user instanceof Array ? (users = users.length > 0 ? [...users, ...user] : [...user]) : users.push(user);
        const new_list = Utils.unique(users, 'email');
        this.control.setValue(new_list.length <= 0 ? [] : [...new_list]);
        this.model.search = '';
        this.model.show = false;
    }

    public remove(user: IUser) {
        const users = this.control.value || [];
        const exists = users.find(u => u.email === user.email);
        if (exists) {
            users.splice(users.indexOf(exists), 1);
            this.control.setValue(users.length < 1 ? [] : [...users]);
        }
    }

    public upload(e) {
        if (this.file_input) {
            const file = this.file_input.nativeElement.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');

                reader.onload = (evt) => this.loadFromCSV((evt.srcElement as any).result);
                reader.onerror = (evt) => this.service.error('Error reading file.');
            }
        }
    }

    private loadFromCSV(data) {
        const list = Utils.loadCSV(data) || [];
        list.forEach(el => {
            el.name = el.name || `${el.first_name} ${el.last_name}`;
            el.external = true;
        });
        this.add(list);
    }

    public hideTooltip() {
        this.timeout('hide_tooltip', () => this.model.show = false);
    }

    public selectAvailability() {
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
        const date = this.form.controls.date ? moment(this.form.controls.date.value) : moment();
        this.service.Overlay.openModal('availability', {
            data: {
                host: this.form.controls.host ? this.form.controls.host.value : this.service.Users.current(),
                date: date.valueOf(),
                duration: this.form.controls.date ? this.form.controls.duration.value || 30 : 30,
                users: this.control.value
            }
        }, (event) => {
            if (event.type === 'Accept') {
                if (event.data && event.data.date) {
                    const new_date = moment(event.data.date);
                    if (this.form.controls.date) {
                        this.form.controls.date.setValue(new_date.valueOf());
                    }
                    if (this.form.controls.duration) {
                        this.form.controls.date.setValue(event.data.duration);
                    }
                    this.model.form.attendees = event.data.users;
                }
                this.updateDisplay();
            }
            event.close();
        });
    }

    public downloadTemplate() {
        const template = `Organisation Name,First Name,Last Name,Email,Phone\nFake Org,John,Smith,john.smith@example.com,01234567898`;
        Utils.download('template.csv', template);
    }

    public addExternal() {
        this.service.Overlay.openModal('user-details', { data: {} })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Success') {
                    const form = event.data.form;
                    const user = form.users ? form.users[0] || form : form;
                    user.external = true;
                    this.add(user);
                } else {
                    event.close();
                }
            }));
    }
}
