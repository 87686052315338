
import { Component } from '@angular/core';
import { PointOfInterest } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'room-info',
    templateUrl: './room-info.template.html',
    styleUrls: ['./room-info.styles.scss']
})
export class RoomInfoComponent {
    public context: PointOfInterest

    constructor(context: PointOfInterest) {
        this.context = context;
    }
}
