
import { Component, OnDestroy } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'device-list-model',
    templateUrl: './device-list-modal.template.html',
    styleUrls: ['./device-list-modal.styles.scss']
})
export class DeviceListModalComponent extends OverlayContentComponent implements OnDestroy {

    public init() {
        this.model.interval = setInterval(() => this.load(), 5 * 1000);
        this.load();
    }

    public ngOnDestroy() {
        if (this.model.interval) {
            clearInterval(this.model.interval);
            this.model.interval = null;
        }
    }

    public load() {
        this.service.Location.query({}).then((list) => {
            this.model.list = list || [];
        }, () => null);
    }

}
