<div class="container">
    <div class="header">
        <div class="text">New Booking</div>
        <div class="close" (touchrelease)="fn.close()">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="body" *ngIf="model.form">
        <booking-form [(form)]="model.form" [(error)]="model.error" [(valid)]="model.valid" [check]="model.validate" [settings]="model.form_settings" (event)="event($event)"></booking-form>
    </div>
    <div class="footer">
        <btn klass="modal" type="submit" (tapped)="!model.booking ? book() : ''">
            <div class="text" *ngIf="!model.booking">{{model.form.room?.book_type || 'Book'}}</div>
            <div class="icon" *ngIf="model.booking">
                <spinner type="circle-ring" color="#fff"></spinner>
            </div>
        </btn>
    </div>
</div>