<div
    class="container"
    *ngIf="model.user && !model.loading"
    [style.background-image]="model.background ? 'url(' + model.background + ')' : ''"
>
    <div class="disclaimer" *ngIf="model.unlicensed">{{model.msg}}</div>
    <div class="header">
        <topbar-header></topbar-header>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <footer-menu></footer-menu>
    </div>
    <div
        class="debug-input"
        [class.enabled]="model.debugging"
        (press)="startDebug()"
        (pressup)="cancelDebug()"
        (contextmenu)="$event.preventDefault()"
    ></div>
</div>
<div class="container loader" *ngIf="model.loading">
    <div class="no-item">
        <div class="icon">
            <spinner type="circle-ring" color="#fff"></spinner>
        </div>
        <div class="text">Loading application data...</div>
    </div>
</div>
<div class="container login" *ngIf="!model.user">
    <login-display></login-display>
</div>
