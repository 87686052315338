import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    format,
    differenceInWeeks,
    addWeeks,
    startOfWeek,
    addDays,
    isSameDay,
    set,
    startOfMinute,
    isBefore,
    startOfDay,
} from 'date-fns';

@Component({
    selector: 'a-day-select-field',
    template: `
        <div class="day-select-field flex items-center" [attr.disabled]="disabled">
            <button type="button" [disabled]="index === 0" (click)="changeWeek(-1)">
                <i class="material-icons text-3xl">keyboard_arrow_left</i>
            </button>
            <div class="flex-1 flex items-center space-x-1 sm:space-x-2 scale-75 sm:scale-100 -translate-x-10 sm:translate-x-0 w-1/2">
                <div
                    class="flex items-center justify-center flex-1"
                    *ngFor="let day of week_options"
                >
                    <button
                        matRipple
                        date
                        type="button"
                        class="h-20 w-20 sm:flex rounded-full flex items-center justify-center hover:bg-primary hover:text-white border-4 border-l-primary border-t-gray-200 border-r-primary border-b-gray-200 border-opacity-0 text-black"
                        [disabled]="day.is_past"
                        [class.bg-gray-400]="day.is_past"
                        [class.border-opacity-100]="day.is_active"
                        [class.text-opacity-60]="day.is_weekend"
                        [class.hidden]="day.is_weekend"
                        (click)="setValue(day.id)"
                    >
                        <div class="flex flex-col items-center justify-center">
                            <p class="text-xs">{{ day.day }}</p>
                            <p class="text-2xl">{{ day.date }}</p>
                            <p class="text-xs">{{ day.month }}</p>
                        </div>
                    </button>
                </div>
            </div>
            <button type="button" (click)="changeWeek(1)">
                <i class="material-icons text-3xl">keyboard_arrow_right</i>
            </button>
        </div>
    `,
    styles: [
        `
            :host {
                width: 100%;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DaySelectFieldComponent),
            multi: true,
        },
    ],
})
export class DaySelectFieldComponent implements ControlValueAccessor {
    /** Whether form field is disabled */
    @Input() public disabled: boolean;

    public index = 0;
    public date: number = Date.now();

    public week_options = [];

    /** Form control on change handler */
    private _onChange: (_: number) => void;
    /** Form control on touch handler */
    private _onTouch: (_: number) => void;

    public changeWeek(change: number) {
        this.index = Math.max(0, this.index + change);
        this._updateWeekOptions();
    }

    /**
     * Update the form field value
     * @param new_value New value to set on the form field
     */
    public setValue(new_value: number): void {
        this.date = new_value;
        this._updateWeekOptions();
        /* istanbul ignore else */
        if (this._onChange) {
            this._onChange(+new_value);
        }
    }

    /* istanbul ignore next */
    /**
     * Update local value when form control value is changed
     * @param value The new value for the component
     */
    public writeValue(value: number) {
        this.date = value;
        this.index = differenceInWeeks(this.date, Date.now());
        this._updateWeekOptions();
    }

    /* istanbul ignore next */
    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * @param fn The callback function to register
     */
    public registerOnChange(fn: (_: number) => void): void {
        this._onChange = fn;
    }

    /* istanbul ignore next */
    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * @param fn The callback function to register
     */
    public registerOnTouched(fn: (_: number) => void): void {
        this._onTouch = fn;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    private _updateWeekOptions() {
        let start = startOfWeek(addWeeks(Date.now(), this.index));
        const current = startOfMinute(new Date(this.date));
        start = set(start, { hours: current.getHours(), minutes: current.getMinutes() });
        this.week_options = [];
        for (let i = 0; i < 7; i++) {
            const date = addDays(start, i);
            this.week_options.push({
                id: date.valueOf(),
                day: isSameDay(date, Date.now()) ? 'Today' : format(date, 'EEE'),
                date: format(date, 'dd'),
                month: format(date, 'MMM'),
                is_past: isBefore(startOfDay(date), startOfDay(Date.now())),
                is_active: isSameDay(current, date),
                is_weekend: date.getDay() === 0 || date.getDay() === 6,
            });
        }
    }
}
