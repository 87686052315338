
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { WidgetsModule } from '@acaprojects/ngx-widgets';
import { AttendeeModalComponent } from './attendee-modal/attendee-modal.component';
import { BookingModalComponent } from './booking-modal/booking-modal.component';
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import { CateringModalComponent } from './catering-modal/catering-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ItemSelectModalComponent } from './item-select-modal/item-select-model.component';
import { LevelSelectModalComponent } from './level-select-modal/level-select-model.component';
import { MapFilterModalComponent } from './map-filter-modal/map-filter-modal.component';
import { RoomFilterModalComponent } from './room-filter-modal/room-filter-modal.component';
import { TermsOverlayComponent } from './terms-overlay/terms-overlay.component';
import { TimePeriodModalComponent } from './time-period-modal/time-period-modal.component';
import { ViewRoomModalComponent } from './view-room-modal/view-room-modal.component';
import { RoomSelectModalComponent } from './room-select-modal/room-select-modal.component';
import { DeviceListModalComponent } from './device-list-modal/device-list-modal.component';
import { DatePeriodModalComponent } from './date-period-modal/date-period-modal.component';
import { OldTimePeriodModalComponent } from './old-time-period-modal/time-period-modal.component';

import { MeetingDetailsOverlayComponent } from './meeting-details-overlay/meeting-details-overlay.component';
import { VisitorDetailsOverlayComponent } from './visitor-details-overlay/visitor-details-overlay.component';
import { UserDetailsOverlayComponent } from './user-details-overlay/user-details-overlay.component';
import { AvailabilityOverlayComponent } from './availability-overlay/availability-overlay.component';

import { SharedComponentModule } from '../shared/components/shared.module';

const OVERLAYS: any = [
    AttendeeModalComponent,
    AvailabilityOverlayComponent,
    BookingModalComponent,
    CalendarModalComponent,
    CateringModalComponent,
    ConfirmModalComponent,
    ItemSelectModalComponent,
    LevelSelectModalComponent,
    MapFilterModalComponent,
    MeetingDetailsOverlayComponent,
    RoomFilterModalComponent,
    TermsOverlayComponent,
    TimePeriodModalComponent,
    UserDetailsOverlayComponent,
    ViewRoomModalComponent,
    VisitorDetailsOverlayComponent,
    RoomSelectModalComponent,
    DeviceListModalComponent,
    DatePeriodModalComponent,
    OldTimePeriodModalComponent
];

@NgModule({
    declarations: [
        ...OVERLAYS
    ],
    imports: [
        CommonModule,
        FormsModule,
        WidgetsModule,
        SharedComponentModule
    ],
    exports: [
        ...OVERLAYS
    ],
    entryComponents: [
        ...OVERLAYS
    ]
})
export class OverlayComponentModule { }
