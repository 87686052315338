
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../base.component';

@Component({
    selector: 'custom-cost-field',
    templateUrl: './cost-field.component.html',
    styleUrls: ['./cost-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class CustomCostFieldComponent extends BaseComponent implements ICustomField<number>, OnDestroy {
    public control: AbstractControl;
    public room_control: AbstractControl;
    public duration_control: AbstractControl;
    public form: FormGroup;
    public field: DynamicField<number>;

    public rate = 0;
    public gst = 0;
    public total = 0;
    public duration = 60;
    public show: boolean;

    public set(field, form) {
        this.field = field;
        if (form) {
            this.form = form;
            this.control = this.form.controls[this.field.key];
            if (this.field.refs && this.field.refs.length > 0 && this.form.controls[this.field.refs[0]]) {
                this.room_control = this.form.controls[this.field.refs[0]];
                this.subs.obs.ref = this.room_control.valueChanges.subscribe(() => this.updateDisplay());
            }
            if (this.field.refs && this.field.refs.length > 1 && this.form.controls[this.field.refs[1]]) {
                this.duration_control = this.form.controls[this.field.refs[1]];
                this.subs.obs.ref = this.duration_control.valueChanges.subscribe(() => this.updateDisplay());
            }

            this.subs.obs.control = this.control.valueChanges.subscribe(() => this.updateDisplay());
            this.init();
        }
    }

    public init() {
        this.updateDisplay();
    }

    public updateDisplay() {
        if (this.room_control && this.room_control.value) {
            this.rate = this.room_control.value.rate || 0;
        }
        if (this.duration_control && this.duration_control.value) {
            this.duration = this.duration_control.value || 60;
        }
        this.total = this.rate * (this.duration / 60);
        this.gst = this.total / 11;
    }

    public setValue(value: number) {
        this.control.setValue(value);
    }
}
