import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'time-select-modal',
    template: `
        <header class="flex items-center justify-between bg-secondary text-white px-4 py-2">
            <h2 class="text-xl">Select Booking Period</h2>
            <button class="w-10 h-10 flex items-center justify-center" mat-dialog-close>
                <i class="material-icons text-2xl">close</i>
            </button>
        </header>
        <main *ngIf="form" [formGroup]="form" class="flex flex-col space-y-2 p-4">
            <p class="text-sm">Minimum booking duration for rooms are 30 minutes</p>
            <p class="text-sm pb-4">Bookings can only be made in 30 minute increments</p>
            <label>Start Time</label>
            <a-time-field formControlName="date"></a-time-field>
            <label>End Time</label>
            <a-duration-field
                formControlName="duration"
                [time]="form.value.date"
                [min]="min_duration"
            ></a-duration-field>
        </main>
        <footer class="pb-4 flex items-center justify-center">
            <button btn class="w-32" [mat-dialog-close]="form?.value">OK</button>
        </footer>
    `,
    styles: [
        `
            label {
                font-size: 0.875rem;
                font-weight: 500;
            }
        `,
    ],
})
export class TimeSelectModalComponent {
    public form: FormGroup;

    public readonly min_duration = this._data.min_duration || 30;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private _data: { date: number; duration: number; min_duration?: number }
    ) {
        console.log('Data:', this._data);
        this.form = new FormGroup({
            date: new FormControl(this._data.date),
            duration: new FormControl(this._data.duration || 60),
        });
    }
}
