<div class="container" (window:resize)="checkSize()" (window:mouseup)="model.move = ''" (window:touchend)="model.move = ''">
    <div class="topbar">
        <div class="actions">
            <div class="back" (touchrelease)="back()">
                <i class="material-icons">keyboard_arrow_left</i>
                <div class="text">Back</div>
            </div>
            <div class="flex"></div>
            <div class="accept" (touchrelease)="accept()">
                <div class="text">Accept</div>
                <i class="material-icons">done</i>
            </div>
        </div>
        <div class="search" tooltip [(show)]="model.show" [template]="user_list" [triangle]="false" position="bottom"
            offset="middle">
            <searchbar [dictation]="false" [(filter)]="model.search" (filterChange)="search()" placeholder="Search for a person here..."
                (focus)="show()" (blur)="hide()"></searchbar>
            <ng-template #user_list>
                <div class="user-list">
                    <div class="item" *ngFor="let item of (model.search_results || [])" (touchrelease)="add(item)">
                        <user-tag [model]="item" [email]="true"></user-tag>
                    </div>
                    <div class="no-item" *ngIf="!model.search_results || model.search_results.length <= 0">
                        <div class="icon">
                            <i class="material-icons" *ngIf="!model.loading || !model.loading.searching">close</i>
                            <spinner *ngIf="model.loading && model.loading.searching" type="circle-ring"></spinner>
                        </div>
                        <div class="text">
                            {{ model.loading && model.loading.searching ? 'Searching...' : 'No matches found' }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="date-select">
        <div class="zone">
            <btn class="min-reduce" (tapped)="selectTimezone()"><span>Add zone</span></btn>
        </div>
        <div class="date-group" tooltip [template]="calendar" [(show)]="model.show_calendar" position="bottom">
            <div class="date" (touchrelease)="model.show_calendar = true">{{model.date}}</div>
            <div class="icon" (touchrelease)="model.show_calendar = true" >
                <i class="material-icons">event</i>
                <ng-template #calendar>
                    <a-date-picker klass="themed" [(ngModel)]="date" [options]="{ from: model.today }" (ngModelChange)="changeDate()"></a-date-picker>
                </ng-template>
            </div>
        </div>
        <div class="change-date">
            <btn klass="min-reduce group-item n1" [disabled]="model.today" (tapped)="changeDay(-1)">Prev</btn>
            <btn klass="min-reduce group-item nl" (tapped)="changeDay(1)">Next</btn>
        </div>
    </div>
    <div class="body" [class.vertical]="model.vertical" (mousemove)="move($event)" (touchmove)="move($event)">
        <div class="calendar">
            <div class="header-block">
                <div class="timeblocks">
                    <div class="text not-mobile">{{model.current_timezone}}</div>
                    <div class="text mobile">{{model.short_timezone}}</div>
                </div>
                <div class="event-block" #header (scroll)="updateContent()">
                    <div class="header host" *ngIf="host">
                        <div class="text" [title]="host.email">{{host.name}}</div>
                        <div class="info">Host</div>
                        <div class="remove">
                            <spinner *ngIf="model.loading && model.loading[host.id]" type="circle-ring"></spinner>
                        </div>
                    </div>
                    <div class="group" *ngFor="let grp of model.groups">
                        <div class="blk" *ngIf="grp !== 'None'">
                            <div class="text not-mobile">{{this.model.tz_data[grp].name}}</div>
                            <div class="text mobile">{{this.model.tz_data[grp].short_name}}</div>
                            <div class="remove" (touchrelease)="removeTimezone(grp)">
                                <i class="material-icons">close</i>
                            </div>
                        </div>
                        <div class="header" *ngFor="let usr of model.group_list[grp]">
                            <div class="text" [title]="usr.email">{{usr.name}}</div>
                            <div class="info">
                                <!-- <checkbox *ngIf="!host || usr.email !== host.email" [(model)]="usr.optional"
                                    (modelChange)="updateState(usr)" label="Optional"></checkbox> -->
                                <span *ngIf="host && usr.email === model.user.email">Requester</span>
                            </div>
                            <div class="remove" (touchrelease)="!model.loading || !model.loading[usr.id] ? remove(usr) : ''">
                                <i class="material-icons" *ngIf="!model.loading || !model.loading[usr.id]">close</i>
                                <spinner *ngIf="model.loading && model.loading[usr.id]" type="circle-ring"></spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calendar" #cal>
                <div class="content">
                    <div class="lines">
                        <div class="block" *ngFor="let item of model.blocks"
                            [style.width]="model.vertical ? (100 / model.blocks.length) + '%' : ''"
                            [style.height]="!model.vertical ? (100 / model.blocks.length) + '%' : ''"
                            [class.hour]="item.hour"></div>
                    </div>
                    <div class="event-group filler" *ngIf="model.user">
                        <div class="block" *ngFor="let item of (model.events[model.user.id] || [])" [style.z-index]="item['z-index']"
                            [class.in-use]="model.user_blocks[item.time]" (touchpress)="!model.user_blocks[item.time] ? setTime(item, true) : ''">
                        </div>
                    </div>
                    <div class="overlap">
                        <div class="current-time" *ngIf="model.today"
                            [style.left]="model.vertical ? (model.time * 100) + '%' : ''"
                            [style.top]="!model.vertical ? (model.time * 100) + '%': ''">
                            <div class="line"></div>
                            <div class="dot"></div>
                        </div>
                        <div class="selected-block" *ngIf="model.block"
                            [style.left]="model.vertical ? (model.block.start * 100) + '%' : ''"
                            [style.width]="model.vertical ? (model.block.length * 100) + '%' : ''"
                            [style.top]="!model.vertical ? (model.block.start * 100) + '%' : ''"
                            [style.height]="!model.vertical ? (model.block.length * 100) + '%' : ''">
                            <div class="inner" (touchpress)="model.move = 'top'"></div>
                            <div class="handle top" (touchpress)="model.move = 'top'">{{model.block.start_time}}</div>
                            <div class="handle bottom" (touchpress)="model.move = 'bottom'">{{model.block.end_time}}</div>
                        </div>
                    </div>
                    <div class="timeblocks">
                        <div class="block" *ngFor="let item of model.blocks"
                            [style.width]="model.vertical ? (1 / (model.blocks.length || 1)) * model.height + 'px' : ''"
                            [style.height]="!model.vertical ? (1 / (model.blocks.length || 1)) * model.height + 'px' : ''">

                            <div class="text not-mobile">{{item.hour ? (model.vertical ? item.short_display : item.display) : ''}}</div>
                            <div class="text mobile-only">{{item.hour ? item.short_display : ''}}</div>
                        </div>
                    </div>
                    <div class="event-block" #content (scroll)="updateHeader()">
                        <div class="event-group host" *ngIf="model.user">
                            <div class="block" *ngFor="let item of (model.events[model.user.id] || [])" [style.z-index]="item['z-index']">
                                <div class="event" *ngFor="let bkn of item.bookings"
                                    [style.width]="model.vertical ? (bkn.length * 100) + '%' : ''"
                                    [style.height]="!model.vertical ? (bkn.length * 100) + '%' : ''">
                                    Unavailable
                                </div>
                            </div>
                        </div>
                        <div class="group" *ngFor="let grp of model.groups">
                            <div class="blk time" *ngIf="grp !== 'None'">
                                <div class="block" *ngFor="let item of model.blocks"
                                    [style.width]="model.vertical ? (1 / (model.blocks.length || 1)) * model.height + 'px' : ''"
                                    [style.height]="!model.vertical ? (1 / (model.blocks.length || 1)) * model.height + 'px' : ''"
                                    [style.opacity]="item.timezones[grp] && item.timezones[grp].raw_hour % 6 === 0 ? 1 : 0">

                                    <div class="text not-mobile">{{item.hour ? (model.vertical ? item.timezones[grp]?.short_display : item.timezones[grp]?.display) : ''}}</div>
                                    <div class="text mobile-only">{{item.hour ? item.timezones[grp]?.short_display : ''}}</div>
                                </div>
                            </div>
                            <div class="event-group" *ngFor="let usr of model.group_list[grp]">
                                <div class="block" *ngFor="let item of (model.events[usr.id] || [])" [style.z-index]="item['z-index']">
                                    <div class="event" *ngFor="let bkn of item.bookings" [class.optional]="usr.optional"
                                    [style.width]="model.vertical ? (bkn.length * 100) + '%' : ''"
                                    [style.height]="!model.vertical ? (bkn.length * 100) + '%' : ''">
                                        Unavailable
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
