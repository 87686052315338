import { Component, ViewContainerRef } from "@angular/core";
import { OverlayService } from "@acaprojects/ngx-widgets";
import { ViewEncapsulation } from "@angular/core";

import { environment } from "../environments/environment";
import { AppService } from "./services/app.service";
import { ITDEAnalyticsService } from "./services/itde-analytics.service";

@Component({
    selector: "app-root",
    template: `
        <div class="app">
            <router-outlet></router-outlet>
        </div>
    `,
    styleUrls: [
        "./app-styles/app.styles.scss",
        "./app-styles/custom-widget.styles.scss",
        "./app-styles/native-element.styles.scss",
    ],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor(
        private view: ViewContainerRef,
        private overlay: OverlayService,
        private _service: AppService,
        private _analytics: ITDEAnalyticsService
    ) {
        this.overlay.view = view;
        (window as any).app_loaded = true;
        if (environment.production && "serviceWorker" in navigator) {
            navigator.serviceWorker
                .getRegistration()
                .then(
                    (active) =>
                        !active &&
                        navigator.serviceWorker.register(
                            "__base__demo/towers/client/ngsw-worker.js"
                        )
                )
                .catch(console.error);
        }
        setTimeout(() => {
            const itde_settings = this._service.Settings.get(
                "app.ITDE"
            )
            // this._analytics.token = itde_settings.token;
            this._analytics.apiDomain(`${itde_settings.domain}`, `${itde_settings.auth_domain}`);
        }, 1000);
    }
}
