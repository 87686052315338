
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { BaseComponent } from '../base.component';
import { AppService } from '../../../services/app.service';
import { IVisitorGroup } from '../../../services/data/visitors.service';

import * as moment from 'moment';
import { Utils } from '../../utility.class';
import { FormFormatters } from '../booking-form/form.formatters';
import { IUser } from '../../../services/data/users.service';

@Component({
    selector: 'visitor-form',
    templateUrl: './visitor-form.template.html',
    styleUrls: ['./visitor-form.styles.scss']
})
export class VisitorFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() visitor: IVisitorGroup;
    @Output() group = new EventEmitter();
    @Output() close = new EventEmitter();

    public model: any = { date: null, duration: 60 };

    @ViewChild('file_input') private file_input: ElementRef;

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.form = {};
        const date = moment().startOf('m');
        date.minutes(Math.ceil(date.minutes() / 5) * 5);
        this.model.date = date.valueOf();
        this.model.group = 0;
        if (this.model.visitor) {
            const visitor_group = this.model.visitor;
            this.model.date = visitor_group.date || visitor_group.time;
            if (visitor_group.visitors && visitor_group.visitors.length === 1) {
                const visitor = visitor_group.visitors[0];
                this.model.form.organisation = visitor.organisation;
                this.model.form.first_name = visitor.first_name;
                this.model.form.last_name = visitor.last_name;
                this.model.form.email = visitor.email;
                this.model.form.phone = visitor.phone;
            }
        }
        this.model.editable = this.service.Settings.get('app.schedule.edit_visitors') !== false &&
            moment(this.model.date).isAfter(moment(), 'm');
        this.model.contacts = this.service.Settings.get('app.schedule.contacts');
        this.model.csv = this.service.Settings.get('app.users.from_csv');
        this.updateDisplay();
    }

    public ngOnChanges(changes: any) {
        if (changes.visitor) {
            this.model.visitor = this.visitor;
        }
    }

    public addToGroup() {
        this.model.clear = null;
        if (!this.model.visitor_list) { this.model.visitor_list = []; }
        if (this.model.form.users && this.model.form.users.length > 0) {
            this.model.visitor_list = Utils.unique(this.model.visitor_list.concat(this.model.form.users), 'email');
        } else {
            if (!this.model.visitor_list.find(i => i.email === this.model.form.email)) {
                const user = {
                    name: (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || ''),
                    first_name: this.model.form.first_name,
                    last_name: this.model.form.last_name,
                    organisation_id: this.model.form.organisation_id,
                    organisation_name: this.model.form.organisation_name || this.model.form.organisation,
                    organisation: this.model.form.organisation || { name: this.model.form.organisation_name },
                    email: this.model.form.email,
                    phone: this.model.form.mobile || this.model.form.phone
                };
                if (user.organisation && !user.organisation.name) {
                    delete user.organisation;
                }
                this.model.visitor_list.push(user);
            }
        }
        const list = (this.model.visitor_list as IUser[]);
        this.model.orgs = Utils.unique(list.map(i => i.organisation || { name: i.organisation_name, id: i.organisation_id }), 'name');
        this.model.orgs = (this.model.orgs || []).reduce((a, i) => { if (i.name) { a.push(i); } return a; }, []);
        this.model.form = { ...this.model.form, users: [] };
        this.timeout('clear', () => {
            this.model.clear = !this.model.clear;
        }, 100);
    }

    public removeFromGroup(item: any) {
        if (this.model.visitor_list) {
            for (const i of this.model.visitor_list) {
                if (item === i || item.email === i.email) {
                    this.model.visitor_list.splice(this.model.visitor_list.indexOf(i), 1);
                    break;
                }
            }
        }
    }

    public add(finish: boolean = true) {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.finish = finish;
            if (!this.model.group) {
                this.addToGroup();
            }
            const data = {
                visitors: this.model.visitor_list,
                date: this.model.date,
                name: this.model.group_name,
                location: this.model.location,
                duration: this.model.duration || 60
            };
            this.service.Visitors.add(data).then(() => {
                this.model.processing = false;
                this.model.visitor_list = [];
                this.model.loading = false;
                this.model.show_details = true;
                if (localStorage) {
                    localStorage.setItem('STAFF.last_visitor', JSON.stringify({ ...this.model.form, ...data}));
                }
                this.model.form = {};
                this.toResult();
            }).catch(() => {
                this.service.error('An error occured while making the visitor booking.');
                this.model.loading = false;
            });
        }
    }

    public toResult() {
        const query: any = {};
        if (this.model.finish) {
            query.finish = this.model.finish;
        }
        this.service.navigate('/visitor/result', query);

    }

    public confirmModal(options): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.service.confirm({
                icon: options.icon,
                title: options.title,
                message: '',
                show_cancel: false
            }, (event) => {
                event.close();
                resolve();
            });
        });
    }

    public update() {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            if (!this.model.group) {
                this.addToGroup();
            }
            const vs = this.model.visitor;
            this.service.Visitors.update(vs.id, {
                visitors: this.model.visitor_list,
                date: this.model.date,
                duration: this.model.duration || 60,
                name: this.model.group_name,
                location: this.model.location
            }).then(() => {
                this.model.processing = false;
                this.model.visitor_list = [];
                this.service.Bookings.removeFromTimeline([this.model.visitor as any]);
                return this.confirmModal({
                    icon: 'cloud_upload',
                    title: 'Visitor Booking Successfully Updated'
                });
            }).then(() => {
                this.model.loading = false;
                this.home();
            }).catch(() => this.model.loading = false);
        }
    }

    public edit() {
        const visitor: IVisitorGroup = this.model.visitor;
        this.model.edit = true;
        this.model.group = visitor.group ? 1 : 0;
        if (this.model.group) {
            this.model.group_name = visitor.name;
            this.model.visitor_list = visitor.visitors;
        } else {
            this.model.form = {
                first_name: visitor.visitors[0].name.split(' ')[0],
                last_name: visitor.visitors[0].name.split(' ')[1],
                organisation: visitor.visitors[0].organisation.name || visitor.visitors[0].organisation,
                email: visitor.visitors[0].email,
                mobile: visitor.visitors[0].phone,
                phone: visitor.visitors[0].phone,
                date: visitor.date,
                duration: visitor.duration
            };
        }
        this.model.location = visitor.location;
    }

    public delete() {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.deleting = true;
            this.service.Visitors.delete(this.model.visitor.id).then(() => {
                this.model.processing = false;
                this.model.visitor_list = [];
                setTimeout(() => this.home(), 3000);
            }, () => {
                this.model.loading = false;
                this.model.deleting = false;
            });
        }
    }

    public upload(e) {
        if (this.file_input) {
            const file = this.file_input.nativeElement.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');
                reader.onload = (evt) => this.loadVisitorsFromCSV((evt.srcElement as any).result);
                reader.onerror = (evt) => this.service.error('Error reading file.');
            }
        }
    }

    private loadVisitorsFromCSV(data) {
        const list = Utils.loadCSV(data) || [];
        this.model.form.users = list;
        for (const u of list) {
            u.name = u.name || `${u.first_name} ${u.last_name}`;
        }
        if (!this.model.group) { this.model.group = true; }
        this.addToGroup();
    }

    public updateDisplay() {
        if (!this.model.form) { this.model.form = {}; }
        if (!this.model.date) { this.model.date = moment().startOf('m').valueOf(); }
        this.model.display = {};
        const date = moment(this.model.date);
        this.model.display.date = date.format('dddd, Do MMMM YYYY');
        this.model.display.time = FormFormatters.period(this.model.duration || 60)(date.format('HH:mm'));
    }

    public home() {
        this.service.navigate(this.service.Settings.get('app.booking.return_id') || '');
        this.close.emit();
    }

    public selectDate() {
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5).startOf('m');
        if (!this.model.date) {
            this.model.date = now.valueOf();
        }
        const date = moment(this.model.date);
        this.service.Overlay.openModal('calendar', { data: { date: this.model.date } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Accept') {
                    if (event.data.form && event.data.form.date) {
                        const new_date = moment(event.data.form.date).hours(date.hours()).minutes(date.minutes())
                            .seconds(0).milliseconds(0);
                        this.model.date = new_date.valueOf();
                    }
                    this.updateDisplay();
                }
                event.close();
            }));
    }

    public selectTime() {
        if (!this.model.date) {
            const now = moment();
            now.minutes(Math.ceil(now.minutes() / 5) * 5).startOf('m');
            this.model.date = now.valueOf();
        }
        if (!this.model.duration) {
            this.model.duration = 60;
        }
        const bkn_date = moment(this.model.date);
        const start_time = bkn_date.format('HH:mm');
        this.service.Overlay.openModal('old-time-period', { data: { start: start_time } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Accept') {
                    const form = event.data.form;
                    const new_start = form.start.split(':');
                    const start = moment(bkn_date);
                    start.hours(new_start[0]).minutes(new_start[1]);
                    this.model.date = start.valueOf();
                    this.updateDisplay();
                }
                event.close();
            }));
    }

    public selectPeriod() {
        const date = moment(this.model.date);
        const time = date.format('HH:mm');
        const end_time = date.add(this.model.duration || 60, 'm').format('HH:mm');
        this.service.Overlay.openModal('old-time-period', { data: {
            start: time,
            end: end_time
        } }, (event) => {
            if (event.type === 'Accept') {
                const d = moment(this.model.date);
                const parts = event.data.form.start.split(':');
                d.hours(parts[0]).minutes(+parts[1]);
                this.model.date = d.valueOf();
                this.model.duration = event.data.form.duration;
                this.updateDisplay();
            }
            event.close();
        });
    }

    public onUserSelect(e) {
        this.addToGroup();
    }

}
