<div class="container" *ngIf="model.route && model.routing && model.routing.main && !model.is_home" [@show]="model.show ? 'show' : 'hide'">
    <div class="routing-block">
        <div class="main" (touchrelease)="tap(model.routing.main)">
            <div class="icon" *ngIf="model.routing.sub">
                <i class="material-icons">keyboard_arrow_left</i>
            </div>
            <div class="name">{{model.routing.main}}</div>
        </div>
        <div class="sub" *ngIf="model.routing.sub">
            <div class="name">{{model.routing.sub}}</div>
        </div>
    </div>
</div>