<div override [class]="'user-tag ' + name" (touchstart)="tap()" (mousedown)="tap()">
    <div class="avatar">
        <avatar [user]="model"></avatar>
    </div>
    <div class="details" *ngIf="details">
        <ng-container *ngIf="model && (model.match_index === undefined || model.match_index < 0)">
            <div class="name">{{model.name || model.email || 'null'}}</div>
        </ng-container>
        <ng-container *ngIf="model && model.match_index >= 0">
            <div class="name"
            [innerHTML]="(model.match_name || model.name || model.match_email || model.email || 'null') | safe"></div>
        </ng-container>
        <div class="role" *ngIf="model && !email && model.type !== 'external'">
            {{model?.b_unit }}
            <span *ngIf="model.job && job">| {{model.job}}</span>
        </div>
        <div class="role" *ngIf="model && email && model">
            <ng-container *ngIf="(model.match_index === undefined || model.match_index < 0)">{{model.email}}</ng-container>
            <ng-container *ngIf="model.match_index >= 0">
                <div [innerHTML]="(model.match_email || model.email) | safe"></div>
            </ng-container>
        </div>
        <div *ngIf="model && model.state" [class]="'state ' + (model.state | lowercase)">
            {{ model.state }}
        </div>
    </div>
    <div class="action" *ngIf="model && model.action" (touchstart)="actionEvent()" (mousedown)="actionEvent()">
        <div center class="host" *ngIf="model.action === 'host'">Host</div>
        <i center class="pwc-close" *ngIf="model.action !== 'host'"></i>
    </div>
</div>