import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

import * as moment from 'moment';
import { AppService } from '../../services/app.service';
import { ITDEPaymentsService } from '../../services/itde-payments.service';
import { BookingFormComponent } from '../../shared/components/booking-form/booking-form.component';

@Component({
    selector: 'meeting-details-overlay',
    templateUrl: './meeting-details-overlay.template.html',
    styleUrls: ['./meeting-details-overlay.styles.scss'],
})
export class MeetingDetailsOverlayComponent
    extends OverlayContentComponent<AppService>
    implements OnDestroy {
    constructor(_cdr: ChangeDetectorRef, private _payments: ITDEPaymentsService) {
        super(_cdr);
    }

    @ViewChild('bookingForm') bookingForm: BookingFormComponent;
    private lastBooking = null;

    public async init() {
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.form = {};
        this.model.group = 0;
        this.model.user = this.service.Users.current();
        this.model.settings = this.service.Settings.get('app.booking') || {};
        this.model.user = this.service.Users.current();
        if (this.model.booking) {
            const price = await this._payments.getPrice(
                this.model.booking.room.email,
                this.model.booking.date,
                this.model.booking.duration
            );
            this.model.booking.room.rate = price.discounted_rate;
            this.checkSoon();
            if (this.model.booking.organiser) {
                this.model.organiser =
                    typeof this.model.booking.organiser === 'string'
                        ? this.model.booking.organiser
                        : this.model.booking.organiser.email;
                this.model.is_organiser = this.model.organiser === this.model.user.email;
            }
            this.model.check_soon = setInterval(() => this.checkSoon(), 12 * 1000);
        }
        this.updateDisplay();
        this.resize();
    }

    public ngOnDestroy() {
        if (this.model.check_soon) {
            clearInterval(this.model.check_soon);
            this.model.check_soon = null;
        }
    }

    /**
     * Update booking
     */
    public update() {
        if (this.service) {
            this.model.is_booking = true;
            this.model.form_checked = true;
            const booking = this.model.booking;
            const form = this.model.form;
            if (booking.date !== form.date || booking.duration !== form.duration) {
                // Start and/or end time is different check if room is available at new times
                this.service.Rooms.isAvailable(
                    form.room.id,
                    form.date,
                    form.duration,
                    booking.icaluid
                ).then(
                    () => this.updateMeeting(),
                    () => {
                        this.model.is_booking = false;
                        const start = moment(form.date);
                        const date = start.format('MMM Do, YYYY');
                        const end = moment(start).add(form.duration, 'm');
                        if (!this.model.error) {
                            this.model.error = {};
                        }
                        this.model.error.room = {
                            message: `The selected space is not free from ${start.format(
                                'h:mma'
                            )} to ${end.format('h:mma')} on ${date}`,
                        };
                        this.service.error(
                            `The selected space is not free from ${start.format(
                                'h:mma'
                            )} to ${end.format('h:mma')} on ${date}`
                        );
                    }
                );
            } else {
                this.updateMeeting();
            }
        }
    }

    public updateMeeting() {
        this.model.is_booking = false;
        this.model.loading = true;
        this.model.processing = true;
        if (this.model.settings.title_prefix) {
            this.model.form.title = this.model.settings.title_prefix + this.model.form.title;
        }
        let found = false;
        for (const u of this.model.form.attendees) {
            if (u.email === this.model.user.email) {
                found = true;
                break;
            }
        }
        if (!found) {
            this.model.form.attendees.unshift(this.model.user);
        }
        this.service.Bookings.update(this.model.booking.id, this.model.form).then(
            () => {
                this.model.processing = false;
                this.model.visitor_list = [];
                this.service.Bookings.removeFromTimeline([this.model.booking]);
                this.openConfirmModal().then(() => this.fn.close());
            },
            () => (this.model.loading = false)
        );
    }

    public openConfirmModal(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.service.confirm(
                {
                    icon: 'cloud_upload',
                    title: 'Booking Successfully Updated',
                    message: '',
                    show_cancel: false,
                },
                (event) => {
                    event.close();
                    resolve();
                }
            );
        });
    }

    public delete() {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.deleting = true;
            this.service.Bookings.delete(this.model.booking.id).then(
                () => {
                    this.model.processing = false;
                    this.fn.event('Success');
                    this.model.visitor_list = [];
                    this.service.Bookings.removeFromList([this.model.booking]);
                    this.service.Bookings.removeFromTimeline([this.model.booking]);
                    setTimeout(() => this.fn.close(), 3000);
                },
                (err) => {
                    this.model.loading = false;
                    this.model.deleting = false;
                    if (err !== 'User cancelled') {
                        this.service.error(err);
                    }
                }
            );
        }
    }

    public manageAttendees() {
        this.service.Overlay.openModal('attendees', {
            data: { attendees: this.model.form.attendees },
        }).then((inst: any) =>
            inst.subscribe((event: any) => {
                if (event.type === 'Change') {
                    this.model.form.attendees = event.data.form.attendees || [];
                    if (!this.model.error) {
                        this.model.error = {};
                    }
                    this.model.error.attendees = null;
                    this.model.form.room = this.model.form.room;
                    this.model.form = JSON.parse(JSON.stringify(this.model.form));
                }
                event.close();
            })
        );
    }

    public event(e) {
        if (e === 'Attendees') {
            if (this.model.form.attendees) {
                for (const item of this.model.form.attendees) {
                    item.select = true;
                }
            }
            this.manageAttendees();
        }
    }

    public edit() {
        this.model.edit = true;
        this.model.form = { ...(this.model.booking || {}) };
    }

    public control() {
        if (this.model.booking && this.model.booking.room && this.model.booking.room.support_url) {
            window.open(this.model.booking.room.support_url, 'blank_');
        }
    }

    public checkSoon() {
        const now = moment();
        const start = moment(this.model.booking.date);
        this.model.is_past =
            now.isAfter(start) || now.isBetween(moment(start).subtract(3, 'h'), start, 'm', '[]');
        if (this.model.is_past) {
            this.model.edit = false;
        }
        const end = moment(start).add(this.model.booking.duration, 'm');
        this.model.soon = now.isBetween(
            moment(start).add(-(this.model.settings.pre_control || 15), 'm'),
            end,
            'm',
            '[]'
        );
        const cancel = moment(start).add(
            -(this.service.Settings.get('app.booking.lock_cancel') || 0),
            'm'
        );
        this.model.can_cancel = now.isBefore(cancel, 'm');
    }

    public locate() {
        if (this.model.booking && this.model.booking.room && this.model.booking.room.map_id) {
            this.service.Overlay.openModal('view-room', {
                data: {
                    room: this.model.booking.room,
                },
            }).then((inst: any) => inst.subscribe((event) => event.close()));
        }
    }

    public updateDisplay() {
        if (!this.model.form) {
            this.model.form = {};
        }
        if (!this.model.form.date) {
            this.model.form.date = moment().startOf('m').valueOf();
        }
        this.model.display = {};
        const date = moment(this.model.form.date);
        this.model.display.date = date.format('dddd, Do MMMM YYYY');
        this.model.display.time = date.format('h:mm a');
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }

    /** Update rates on date changed */
    public async formChange(form){
        const booking = this.model.booking;
        const change = this.lastBooking || booking;
        if (booking && (form.date !== change.date || form.duration !== change.duration )) {
            const price = await this._payments.getPrice(
                this.model.booking.room.email,
                form.date,
                form.duration
            );
            this.model.booking.room.rate = price.discounted_rate;
            this.checkSoon();
            this.model.check_soon = setInterval(() => this.checkSoon(), 12 * 1000);
            this.updateDisplay();
            this.resize();
            this.bookingForm?.processFields();
        }
        this.lastBooking = {...form};
    }
}
