<div class="user-list" widget>
    <div class="search" tooltip [triangle]="false" [show]="model.show && model.search && model.filtered && model.filtered.length > 0" (showChange)="model.show = $event" [template]="list">
        <searchbar klass="form" [(filter)]="model.search" (filterChange)="search($event)" (focus)="model.show = true" (blur)="hideTooltip()"></searchbar>
        <ng-template #list>
            <div class="user-list">
                <div class="item" *ngFor="let item of model.filtered" (touchrelease)="add(item)">
                    <user-tag [model]="item" [email]="true"></user-tag>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="list" [class.error]="control?.errors">
        <div class="item" *ngFor="let item of model.users; let i = index" [style.background-color]="model.colours[i % model.colours.length]"
        [title]="item.name">
            <div class="text">{{item.name}}</div>
            <div class="icon" (touchrelease)="remove(item)">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="no-item" *ngIf="!model.users || model.users.length <= 0">
            <div class="text">No attendees</div>
        </div>
    </div>
    <div class="options">
        <div class="item" (touchrelease)="addExternal()" click-responder>
            <div class="icon">
                <i class="material-icons">add</i>
            </div>
            <div class="text">External Add</div>
        </div>
        <div class="item" touchrelease click-responder>
            <div class="icon">
                <i class="material-icons">insert_drive_file</i>
            </div>
            <div class="text">Bulk (CSV)</div>
            <input type="file" #file_input (change)="upload($event)">
        </div>
        <div class="item" (touchrelease)="downloadTemplate()" click-responder>
            <div class="icon">
                <i class="material-icons">arrow_downward</i>
            </div>
            <div class="text">CSV</div>
        </div>
    </div>
    <div class="info">
        <div class="icon" (touchrelease)="selectAvailability()">
            <i class="material-icons">search</i>
        </div>
        <div class="text" (touchrelease)="selectAvailability()">Find availability</div>
    </div>
</div>
